import React, { Component } from 'react';
import './AddBrand.scss';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import Select from "react-select";
import { userStore } from "../../stores/user";

class AddBrand extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedBusinessUnitsIds: [],
            selectedBusinessUnits: [],
        }

        this.clearBusinessUnits = this.clearBusinessUnits.bind(this);
    }

    businessUnitsChange(businessUnits){
        this.setState({
            selectedBusinessUnitsIds: businessUnits.map((item) => { return item.value }),
            selectedBusinessUnits: businessUnits
        });
        // this.props.selectedBusinessUnitsIds = businessUnits.map((item) => { return item.value });
    }

    clearBusinessUnits(){
        this.setState({
            selectedBusinessUnitsIds: [],
            selectedBusinessUnits: [],
        });
    }

    render() {
        const styleSelect = {
            control: (base, state) => ({
                ...base,
                minWidth: '150px',
                height: '50px',
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
                border: userStore.me.settings.theme === 'theme-dark' ? '1px solid #2a2d2e !important' : '1px solid #d3d3d3 !important',
                boxShadow: 'none'
            }),
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    color: userStore.me.settings.theme === 'theme-dark' ? '#778ca2' : '#000',
                    marginLeft: '5px'
                }
            },
            option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                color: userStore.me.settings.theme === 'theme-dark'  ? (state.isFocused ? '#FFF' : '#778ca2') : '#000',
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
            }),
            singleValue: (provided, state) => ({
                ...provided,
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
            }),
            menuList: (provided, state) => ({
                ...provided,
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF'
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '50px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            })
        };
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{ brandId: this.props.brandId, brandName: this.props.brandName, hogarthVisible: this.props.hogarthVisible }}
                        validate={values => {
                            let errors = {};
                            if (!values.brandName) {
                                errors.brandName = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveBrand({...values, ...{selectedBusinessUnitsIds: this.state.selectedBusinessUnitsIds}}, this.clearBusinessUnits);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>{(values.brandId ? 'Edit ' : 'Add new ') + 'brand'}</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="brandId" value={values.brandId}/>
                                    <FormGroup>
                                        <label htmlFor="brand--name" className="text-left--ts">Name</label>
                                        <input
                                            type="text"
                                            name="brandName"
                                            id="brand--name"
                                            value={values.brandName}
                                            onChange={handleChange}
                                            className={errors.brandName ? 'form-control error' : 'form-control'}
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="brandName" className="form-error" component="div" />
                                    </FormGroup>

                                    {/*<FormGroup>*/}
                                    {/*    <label className="chk-container">Hogarth visible*/}
                                    {/*        <input type="checkbox" name="hogarthVisible" checked={values.hogarthVisible} onChange={handleChange} id="brand--hogarth-visible" />*/}
                                    {/*        <span className="checkmark"></span>*/}
                                    {/*    </label>*/}
                                    {/*</FormGroup>    */}

                                    <FormGroup>
                                        <label htmlFor="brand--name" className="text-left--ts">Business Unit</label>
                                        <Select
                                            styles={styleSelect}
                                            value={this.state.selectedBusinessUnits.length ? this.state.selectedBusinessUnits : this.props.selectedBusinessUnits}
                                            options={this.props.businessUnitsList}
                                            onChange={(selectedOptions) => {
                                                this.businessUnitsChange(selectedOptions)
                                            }}
                                            isMulti
                                        />
                                        {/*<ErrorMessage name="brandName" className="form-error" component="div" />*/}
                                    </FormGroup>

                                
                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE BRAND'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddBrand);