import React, { Component } from 'react';
import './Login.scss';
import {Link} from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Preloader from "../../components/preloader/Preloader";
import ParticlesComponent from "../../components/particles/Particles";
import {Animated} from "react-animated-css";
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';
import AppContext from "../../context/index";
import { ls } from "../../services/ls";
import userStore from "../../stores/user";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirectToHomepage: false
        };
    }

    handleSubmit = (values, setSubmitting) => {
        let loginData = JSON.stringify({
            email: values.email,
            password: values.password
        });

        axios.post(process.env.REACT_APP_API_URL + 'login', loginData, {
            headers: { 'Content-Type': 'application/json' }
        })
        .then(res => {
            res = res.data.data;

            if (res.token) {
                const { cookies } = this.props;
                cookies.set('authToken', res.token, { path: '/' });
                cookies.set('userRole', res.me.role, { path: '/' });
                cookies.set('userSex', res.me.sex, { path: '/' });
                cookies.set('userAvatar', res.me.avatar, { path: '/' });
                cookies.set('isLimitedPm', res.me.is_limited_pm, { path: '/' });
                cookies.set('isIT', res.me.is_it, { path: '/' });

                /** Add Data to Localstorage at Login */
                ls.set('authToken', res.token);

                if (res.users) {
                    ls.set('usersList', res.users);
                }
                if (res.clients) {
                    ls.set('clientsList', res.clients);
                }
                if (res.brands) {
                    ls.set('brandsList', res.brands);
                }
                if (res.me) {
                    ls.set('me', res.me);
                }

                window.location = '/';
            }
            else{
                toast.error("There is no account matching the email and password you entered.");
            }

            setSubmitting(false);
        })
        .catch(err => {
            toast.error(err.message);
            setSubmitting(false);
        });

    };

    componentDidMount(){
        document.body.className = "login";

        const { cookies } = this.props;
        if(cookies.get('authToken')) {
            window.location = '/';
        }
    }

    render() {
        const LoginSchema = Yup.object().shape({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            password: Yup.string()
                .min(6, 'Must be longer than 6 characters')
                .required('Required')
        });

        return (
            this.state.loading ? <Preloader/> :
            <div className="LoginPage">
                <ParticlesComponent/>

                <Animated animationIn="fadeInLeft" animationOut="fadeOutLeft" isVisible={true}>
                    <div className={"login-wrapper"}>
                        <div className="full-center centerer">
                            <Formik
                                validationSchema = {LoginSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    this.handleSubmit(values, setSubmitting);
                                }}
                                initialValues={{ email: '', password: '' }}
                            >
                                { props => {
                                    const {
                                        touched,
                                        errors,
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email">
                                                    Email
                                                </label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    value={values.email}
                                                    type="text"
                                                    onChange={handleChange}
                                                    className={
                                                        errors.email && touched.email ? 'form-control error' : 'form-control'
                                                    }
                                                />

                                                {errors.email &&
                                                touched.email && <div className="input-feedback">{errors.email}</div>}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="password">
                                                    Password
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    type="password"
                                                    onChange={handleChange}
                                                    className={
                                                        errors.password && touched.password ? 'form-control error' : 'form-control'
                                                    }
                                                />

                                                {errors.password &&
                                                touched.password && <div className="input-feedback">{errors.password}</div>}
                                            </div>

                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                SIGN IN
                                            </button>

                                            <div className="remember-forgot">
                                                <Link to="/forgot" className="link-register">Forgot your password?</Link>
                                            </div>
                                        </form>
                                    );
                                } }
                            </Formik>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
}
Login.contextType = AppContext;

export default withCookies(Login);
