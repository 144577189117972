import React, { Component } from 'react';
import './Event.scss';
import { withCookies } from 'react-cookie';
import Moment from 'react-moment';
import profilePictureM from '../../assets/img/profile-pic.png';
import profilePictureF from '../../assets/img/profile-pic-f.png';

class Event extends Component {
    render() {
        return (
            <div className="event-container">
                <a href={this.props.tagUrl} className="event-title">
                    <span className="event-title-name">{this.props.tag}</span>
                </a>
                <div className="event-calendar"> <i className="la la-calendar"></i> <Moment format={this.props.dateFormat}>{this.props.date}</Moment>  </div>
                <div className="event-description">{this.props.message}</div>
                
                {this.props.showUserProfile &&
                    <div className="event-user">
                        <img src={this.props.userAvatar ? this.props.userAvatar : (this.props.userSex == 1 ? profilePictureM : profilePictureF) } alt="img" title="user's name" className="user-round-avatar" /><span className="event-user-name">{this.props.userName}</span>
                    </div>
                }
                
                {this.props.targetValue &&
                    <div className="event-location">
                        <i className="la la-bullseye"></i>
                        <span>
                            {this.props.targetLabel}: 
                            <a href={this.props.targetUrl} className="event-location-address">
                                {this.props.targetDate ?
                                <Moment format={this.props.targetDate}>{this.props.targetValue}</Moment>
                                :
                                this.props.targetValue}
                            </a>
                        </span>
                    </div>
                }
            </div>
        )
    }
}

export default withCookies(Event);