import React, { Component } from 'react';
import './Dashboard.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { withCookies, Cookies } from 'react-cookie';
import {instanceOf} from "prop-types";
import Event from "../../components/event/Event";
import ProjectsStats from "../../components/dashboard/ProjectsStats/ProjectsStats";
import Masonry from 'react-masonry-component';
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import Parser from 'html-react-parser';
import profilePictureM from '../../assets/img/profile-pic.png';
import profilePictureF from '../../assets/img/profile-pic-f.png';
import AppContext from "../../context/index";

class Dashboard extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            events: []
        }
    }

    componentDidMount = () => {
        const { cookies } = this.props;
        this.getActions();

        if(parseInt(cookies.get('isLimitedPm'))) {
            window.location = "/projects";
        }
    }

    getActions = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'actions', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            console.log(res);
            this.setState({
                loading: false,
                events: res.data.data
            })
        })
        .catch(err => {
            if(err && err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    render() {

        const events = this.state.events.map((event, index) => {
            return (

                <div key={'event-' + index} className="col-md-6">
                {event && event.project && event.project.project_title &&
                    <div className="event-outter">
                        {event.action_type_id == 2 ? //ADD_PROJECT
                            <Event
                                tag={event.project.project_title.substr(0, 50)}
                                tagUrl={'/project/' + event.project.project_id}
                                date={event.created_at}
                                dateFormat="DD MMM YYYY - HH:mm"
                                message={event.message ? Parser(event.message.replace('[PROJECT_ID]', event.project.project_id).replace('[PROJECT_TITLE]', event.project.project_title)) : ''}
                                showUserProfile="true"
                                userId={event.user_id}
                                userName={event.user_name}
                                userAvatar={event.user_avatar ? event.user_avatar : (event.user_sex == 1 ? profilePictureM : profilePictureF) }
                                userSex={event.user_sex || 1}
                                targetLabel="Deadline"
                                targetValue={event.project.project_deadline}
                                targetUrl={'/project/' + event.project.project_id}
                                targetDate="DD.MM.YYYY"
                            />
                        : event.action_type_id == 3 ?
                            <Event
                                tag={event.project.project_title.substr(0, 50)}
                                tagUrl={'/project/' + event.project.project_id}
                                date={event.created_at}
                                dateFormat="DD MMM YYYY - HH:mm"
                                message={Parser(event.message.replace('[PROJECT_ID]', event.project.project_id).replace('[PROJECT_TITLE]', event.project.project_title))}
                                targetLabel="Deadline"
                                targetValue={event.project.project_deadline}
                                targetUrl={'/project/' + event.project.project_id}
                                targetDate="DD.MM.YYYY"
                            />
                        : ''
                        }
                    </div>
                }
                </div>

             );
         });

        return (
            <div>
                { this.state.loading && <Preloader/> }
                <div className="page--with-header-sidebar">
                    <Sidebar/>
                    <div className="page--header-and-content">
                        <Header pageTitle="Dashboard"/>
                        <div className="DashboardPage">
                            <ProjectsStats/>

                            <div className="row">
                                <div className="dash-events">
                                    <Masonry>
                                        {events}
                                    </Masonry>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Dashboard.contextType = AppContext;
export default withCookies(Dashboard);
