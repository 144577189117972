import axios from 'axios';
import {toast} from "react-toastify";

export const createAction = (actionTypeId, entity, entityId, token, targetedUserId) => {
    axios({
        url: process.env.REACT_APP_API_URL + 'actions',
        method: "POST",
        data: {
            action_type_id: actionTypeId,
            entity: entity,
            entity_id: entityId,
            targeted_user_id: targetedUserId || ''
        },
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

export const validateRules = (value, rules) => {

    if(rules.includes('Required') && !value){
        return 'Required';
    }

    return null;
}

export const dataURLtoFile = (dataurl, filename) => {

    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

export const handleErrorResponse = (response) => {

    if(response.status === 422){
        Object.keys(response.data.errors).forEach((key) => {
            toast.error(response.data.errors[key][0]);
        });
    }else{
        toast.error("An error occurred, please try again later.");
    }
}