import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Col, Row } from 'reactstrap';
import './AddProject.scss';
import "@pathofdev/react-tag-input/build/index.css"
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import { createAction } from '../../utils/utils';
import AppContext from "../../context/index";
import {userStore} from "../../stores/user";
import ReactTagInput from "@pathofdev/react-tag-input";

class AddProject extends Component {

    constructor(props) {
        super(props);
        const { cookies } = this.props;
        let today = new Date();

        this.state = {
            fileName: 'UPLOAD FILE',
            file: new FormData(),
            redirectToLogin: false,
            loading: false,
            clientsList: [],
            brandsList: [],
            clientPMsList: [],
            typesList: [],
            categoriesList: [],
            idgPMsList: [],
            ownersList: [],
            allowedOwnersListForHogarth: [15, 198, 294],
            projectEditId: -1,
            projectEditJN: [],
            projectEditTitle: '',
            projectEditDescription: '',
            projectEditMjn: '',
            projectEditFile: '',
            projectEditClientId: -1,
            projectEditHogarthPMId: -1,
            projectEditBrandId: -1,
            projectEditOwnerId: -1,
            projectEditTypeId: parseInt(cookies.get('isLimitedPm')) ? 2 : -1,
            projectEditCategoryId: parseInt(cookies.get('isLimitedPm')) ? 17 : -1,
            projectEditStartDate: today,
            projectEditEndDate: new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000), //one week after today
            projectEditFixedPrice: '',
            renderForm: false,
            isLimitedPm: 1
        };
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleDeadline = this.handleDeadline.bind(this);
    }

    componentDidMount = () => {
        const { cookies } = this.props;

        this.getProjectTypesList();
        this.getCategoryList();

        this.setState({
            brandsList: parseInt(cookies.get('isLimitedPm')) ? userStore.brands.filter((brand) => ((brand.business_units.length > 0) && (brand.business_units.filter((businessUnit) => businessUnit.name.toLowerCase().includes('gdc')).length > 0))) : userStore.brands,
            clientsList: parseInt(cookies.get('isLimitedPm')) ? userStore.clients.filter((client) => client.name.toLowerCase().includes('hogarth')) : userStore.clients,
            clientPMsList: userStore.getClientPMs(),
            idgPMsList: userStore.getIdPMs(),
            ownersList: parseInt(cookies.get('isLimitedPm')) ? userStore.getOwners().filter((owner) => this.state.allowedOwnersListForHogarth.includes(owner.id)) : userStore.getOwners(),
            isLimitedPm: parseInt(cookies.get('isLimitedPm'))
        });

        if(this.props.projectId && this.props.projectId != -1) {
            //get details, it's an update
            this.getProjectDetails(this.props.projectId);
        }
        else {
            this.setState({
                renderForm: true
            })
        }
    }

    /* PROJECT DETAILS */
    getProjectDetails = (projectId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'project/' + projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectEditId: res.data.data.id,
                projectEditJN: res.data.data.job_numbers.map((item) => { return item.name }),
                projectEditTitle: res.data.data.title,
                projectEditDescription: res.data.data.description || '',
                projectEditMjn: res.data.data.mjn || '',
                projectEditFile: res.data.data.filepath || '',
                projectEditClientId: res.data.data.client_id,
                projectEditHogarthPMId: res.data.data.hogarth_pm_id || -1,
                projectEditBrandId: res.data.data.brand_id,
                projectEditCategoryId: res.data.data.category_id,
                projectEditOwnerId: res.data.data.owner_id,
                projectEditTypeId: res.data.data.type_id,
                projectEditStartDate: new Date(res.data.data.start_date),
                projectEditEndDate: new Date(res.data.data.deadline),
                projectEditFixedPrice: res.data.data.fixed_price || '',
                renderForm: true,
                loading: false
            });
        })
        .catch(err => {
            if (typeof err.response != "undefined" && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    handleStartDate = (date) => {
        let projectEditEndDate = this.state.projectEditEndDate;

        if(date > this.state.projectEditEndDate){
            projectEditEndDate = date;
        }

        this.setState({
            projectEditStartDate: date,
            projectEditEndDate: projectEditEndDate
        });
    }

    handleDeadline = (date) => {
        this.setState({
            projectEditEndDate: date
        });
    }

    handleFileChange = e => {
        this.setState({
            fileInput: e.target,
            fileName: e.target.files[0].name,
            file: e.target.files[0]
        })

    }

    clearInputFile = e => {
        this.setState({
            fileName: '',
            file: ''
        })
    }

    /* Save Project */
    saveProject = (projectData, startDate, endDate, file) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        let jobNumbers = this.state.projectEditJN.length ? this.state.projectEditJN : [document.getElementsByClassName('react-tag-input__input')[0].value]
        let formData = new FormData();
        if(file){
            formData.append("file_path", file);
        }

        formData.append('job_numbers', jobNumbers);
        formData.append('title', projectData.projectTitle);
        formData.append('mjn', projectData.projectMjn);

        if(projectData.projectDescription && projectData.projectDescription.length){
            formData.append('description', projectData.projectDescription);
        }

        formData.append('brand_id', projectData.projectBrandId);
        formData.append('client_id', projectData.projectClientId);
        formData.append('start_date', startDate.getFullYear() + '-' + ("0" + parseInt(parseInt(startDate.getMonth()) + 1)).slice(-2) + '-' + ("0" + startDate.getDate()).slice(-2));
        formData.append('deadline', endDate.getFullYear() + '-' + ("0" + parseInt(parseInt(endDate.getMonth()) + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2));
        formData.append('owner_id', projectData.projectOwnerId);
        formData.append('type_id', projectData.projectTypeId);
        formData.append('category_id', projectData.projectCategoryId);

        if(projectData.projectHogarthPMId && projectData.projectHogarthPMId != -1){
            formData.append('hogarth_pm_id', projectData.projectHogarthPMId);
        }
        if(projectData.projectFixedPrice && projectData.projectFixedPrice.length){
            formData.append('fixed_price', projectData.projectJN);
        }

        axios.post(process.env.REACT_APP_API_URL + 'save-project' + (projectData.projectId != -1 ? ('/'+projectData.projectId) : ''), formData, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken'),
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            this.setState({
                loading: false,
            })
            this.props.closeEditModal(true);
            toast.success("Project has been saved.");

            createAction(2, 'projects', res.data.data.id, cookies.get('authToken'));
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
                this.props.closeEditModal(true);
            }

            if(err.response.status === 422){
                if(err.response.data.errors && err.response.data.errors){
                    for(let key in err.response.data.errors){
                        toast.error(err.response.data.errors[key][0]);
                    }
                }
            }else{
                toast.error("An error occurred, please try again later.");
            }
        })
    }

    getProjectTypesList = () => {
        /* Project Types - Dropdown Options */
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'project-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                typesList: parseInt(cookies.get('isLimitedPm')) ? res.data.data.filter((type) => type.id == 2) : res.data.data,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }
    getCategoryList = () => {
        /* Category - Dropdown Options */
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'project-categories', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
          .then(res => {
              this.setState({
                  categoriesList: parseInt(cookies.get('isLimitedPm')) ? res.data.data.paginatedProjectCategories.filter((category) => category.id == 17) : res.data.data.paginatedProjectCategories,
                  loading: false
              });
          })
          .catch(err => {
              if (typeof err.response != "undefined" && err.response.status === 401) {
                  //redirect to login
                  this.context.redirectToLogin();
              } else {
                  this.setState({
                      loading: false
                  });
              }
          })
    }

    setTags(newTags, tagsKey, validateForm){
        let state = {};
        state[tagsKey] = newTags;

        this.setState(state, () => {
            validateForm();
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} wrapClassName="component--modal-new-project">
                {this.state.renderForm &&
                    <Formik
                            initialValues = {{
                                projectId: this.state.projectEditId,
                                projectJN: this.state.projectEditJN,
                                projectTitle: this.state.projectEditTitle,
                                projectDescription: this.state.projectEditDescription || '',
                                projectMjn: this.state.projectEditMjn || '',
                                projectClientId: this.state.projectEditClientId || -1,
                                projectHogarthPMId: this.state.projectEditHogarthPMId || -1,
                                projectBrandId: this.state.projectEditBrandId || -1,
                                projectCategoryId: this.state.projectEditCategoryId || -1,
                                projectTypeId: this.state.projectEditTypeId || -1,
                                projectOwnerId: this.state.projectEditOwnerId || -1,
                                projectFile: '',
                                projectFixedPrice: this.state.projectEditFixedPrice
                            }}
                            validate={values => {
                                let errors = {};

                                if(!this.state.projectEditJN.length && !document.getElementsByClassName('react-tag-input__input')[0].value){
                                    errors.projectJN = 'Required';
                                }

                                if (!values.projectTitle) {
                                    errors.projectTitle = 'Required';
                                }
                                if (!values.projectClientId || values.projectClientId === -1) {
                                    errors.projectClientId = 'Required';
                                }
                                if (!values.projectBrandId || values.projectBrandId === -1) {
                                    errors.projectBrandId = 'Required';
                                }
                                if (!this.state.projectEditStartDate) {
                                    errors.projectStartDate = 'Required';
                                }
                                if (!this.state.projectEditEndDate) {
                                    errors.projectEndDate = 'Required';
                                }
                                if (!values.projectTypeId || values.projectTypeId === -1) {
                                    errors.projectTypeId = 'Required';
                                }
                                if (!values.projectOwnerId || values.projectOwnerId === -1) {
                                    errors.projectOwnerId = 'Required';
                                }
                                if (!values.projectCategoryId || values.projectCategoryId === -1) {
                                    errors.projectCategoryId = 'Required';
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.saveProject(values, new Date(this.state.projectEditStartDate), new Date(this.state.projectEditEndDate), this.state.file);
                                setSubmitting(false);
                            }}
                             >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                validateForm
                                /* and other goodies */
                            }) => (
                                <Form>
                                    <input type="hidden" name="projectId" value={values.projectId}/>
                                    <ModalHeader toggle={this.toggle}>{ values.projectId ? 'Edit project' : 'Add new project' }</ModalHeader>
                                    <ModalBody className="new--project">
                                        <FormGroup>
                                            <label htmlFor="project--jn" className="text-left--ts">Job Numbers</label>
                                            <div className={errors.projectJN ? 'error' : ''}>
                                                <ReactTagInput
                                                    placeholder="Job Numbers"
                                                    tags={this.state.projectEditJN}
                                                    onChange={(newTags) => {this.setTags(newTags, 'projectEditJN', validateForm)}}
                                                />
                                            </div>
                                            <ErrorMessage name="projectJN" className="form-error" component="div" />
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="project--title" className="text-left--ts">Title</label>
                                            <input
                                                type="text"
                                                name="projectTitle"
                                                id="project--title"
                                                value={values.projectTitle}
                                                onChange={handleChange}
                                                className={errors.projectTitle ? 'form-control error' : 'form-control'}
                                                placeholder="Title"
                                            />
                                            <ErrorMessage name="projectTitle" className="form-error" component="div" />
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="project--description" className="text-left--ts">Description</label>
                                            <textarea
                                                name="projectDescription"
                                                id="project--description"
                                                value={values.projectDescription}
                                                onChange={handleChange}
                                                className={errors.projectDescription ? 'form-control error' : 'form-control'}
                                                placeholder="Description"
                                            />
                                            <ErrorMessage name="projectDescription" className="form-error" component="div" />
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="project--mjn" className="text-left--ts">Mjn</label>
                                            <input
                                                type="text"
                                                name="projectMjn"
                                                id="project--mjn"
                                                value={values.projectMjn}
                                                onChange={handleChange}
                                                className={errors.projectMjn ? 'form-control error' : 'form-control'}
                                                placeholder="Mjn"
                                            />
                                            <ErrorMessage name="projectMjn" className="form-error" component="div" />
                                        </FormGroup>

                                        {!this.state.isLimitedPm &&
                                            <FormGroup className="file-input--container">
                                                <label htmlFor="project--file" className="text-left--ts">File</label>
                                                <div className="file-input-placeholder">
                                                    <i className="la la-cloud-upload"></i> {this.state.fileName}
                                                    <input
                                                        type="file"
                                                        name="projectFile"
                                                        id="project--file"
                                                        value={values.projectFile}
                                                        onChange={this.handleFileChange}
                                                        className={errors.projectFile ? 'form-control-file error' : 'form-control-file'}
                                                    />
                                                    <i className="btn btn--ts-grey btn-del"
                                                       onClick={this.clearInputFile}>X</i>
                                                </div>
                                                <ErrorMessage name="projectFile" className="form-error"
                                                              component="div"/>
                                            </FormGroup>
                                        }
                                        <FormGroup>
                                            <label htmlFor="project--client" className="text-left--ts">Client</label>
                                            <select
                                                name="projectClientId"
                                                id="project--client"
                                                value={values.projectClientId}
                                                onChange={handleChange}
                                                className={errors.projectClientId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Select</option>
                                                {
                                                    this.state.clientsList.map(client => {
                                                        return (
                                                            <option key={'client-' + client.id} value={client.id}>{client.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="projectClientId" className="form-error" component="div" />
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="project--brand" className="text-left--ts">Brand</label>
                                            <select
                                                name="projectBrandId"
                                                id="project--brand"
                                                value={values.projectBrandId}
                                                onChange={handleChange}
                                                className={errors.projectBrandId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Select</option>
                                                {
                                                    this.state.brandsList.map(brand => {
                                                        return (
                                                            <option key={'brand-' + brand.id} value={brand.id}>{brand.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="projectBrandId" className="form-error" component="div" />
                                        </FormGroup>

                                        {!this.state.isLimitedPm &&
                                            <FormGroup>
                                                <label htmlFor="project--hogarth-pm" className="text-left--ts">Hogarth
                                                    PM</label>
                                                <select
                                                    name="projectHogarthPMId"
                                                    id="project--hogarth-pm"
                                                    value={values.projectHogarthPMId}
                                                    onChange={handleChange}
                                                    className={errors.projectHogarthPMId ? 'form-control error' : 'form-control'}
                                                >
                                                    <option>Select</option>
                                                    {
                                                        this.state.clientPMsList.map(hgPM => {
                                                            return (
                                                                !hgPM.disabled ?
                                                                    <option key={'hg-pm-' + hgPM.id}
                                                                            value={hgPM.id}>{hgPM.name}</option>
                                                                    :
                                                                    ''
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <ErrorMessage name="projectHogarthPMId" className="form-error"
                                                              component="div"/>
                                            </FormGroup>
                                        }
                                        <FormGroup>
                                            <Row className="start-deadline-row" form>
                                                <Col md={6}>
                                                    <Label className="text-left--ts"><span>Start date </span></Label>
                                                    <DatePicker
                                                        selected={this.state.projectEditStartDate}
                                                        onChange={this.handleStartDate}
                                                        className="start-date-col"
                                                    />
                                                    <ErrorMessage name="projectStartDate" className="form-error" component="div" />
                                                </Col>
                                                <Col md={6}>
                                                    <Label className="text-left--ts"><span>Deadline </span></Label>
                                                    <DatePicker
                                                        selected={this.state.projectEditEndDate}
                                                        onChange={this.handleDeadline}
                                                        minDate={this.state.projectEditStartDate}
                                                        className="start-date-col"
                                                    />
                                                    <ErrorMessage name="projectEndDate" className="form-error" component="div" />
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="project--type" className="text-left--ts">Type </label>
                                            <select
                                                name="projectTypeId"
                                                id="project--type"
                                                value={values.projectTypeId}
                                                onChange={handleChange}
                                                className={errors.projectTypeId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Select</option>
                                                {
                                                    this.state.typesList.map(type => {
                                                        return (
                                                            <option key={'type-' + type.id} value={type.id}>{type.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="projectTypeId" className="form-error" component="div" />
                                        </FormGroup>

                                        <FormGroup>
                                            <label htmlFor="project--category" className="text-left--ts">Category</label>
                                            <select
                                              name="projectCategoryId"
                                              id="project--category"
                                              value={values.projectCategoryId}
                                              onChange={handleChange}
                                              className={errors.projectTypeId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Select</option>
                                                {
                                                    this.state.categoriesList.map(category => {
                                                        return (
                                                          <option key={'category-' + category.id} value={category.id}>{category.title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="projectCategoryId" className="form-error" component="div" />
                                        </FormGroup>

                                        {values.projectTypeId == 1 &&
                                            <FormGroup>
                                                <label htmlFor="project--fixed-price" className="text-left--ts">Fixed Price </label>
                                                <input
                                                    type="text"
                                                    name="projectFixedPrice"
                                                    id="project--fixed-price"
                                                    value={values.projectFixedPrice}
                                                    onChange={handleChange}
                                                    className={errors.projectFixedPrice ? 'form-control error' : 'form-control'}
                                                    placeholder="Fixed price"
                                                />
                                                <ErrorMessage name="projectFixedPrice" className="form-error" component="div" />
                                            </FormGroup>
                                        }

                                        <FormGroup>
                                            <label htmlFor="project--owner" className="text-left--ts">Owner </label>
                                            <select
                                                name="projectOwnerId"
                                                id="project--owner"
                                                value={values.projectOwnerId}
                                                onChange={handleChange}
                                                className={errors.projectOwnerId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Select</option>
                                                {
                                                    this.state.ownersList.map(owner => {
                                                        return (
                                                            !owner.disabled ?
                                                            <option key={'owner-' + owner.id} value={owner.id}>{owner.name}</option>
                                                            :''
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="projectOwnerId" className="form-error" component="div" />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE PROJECT'}</button>
                                        <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeEditModal}>CANCEL</button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
AddProject.contextType = AppContext;
export default withCookies(AddProject);
