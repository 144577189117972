import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import './TableRow.scss';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

class TableRow extends Component {

    actionClicked = (functionName, functionParams) => {
      this.props[functionName](functionParams);
    }

    render() {
        return (
            <div className={"TableRow row justify-content-between " + (this.props.customRowClass || '')} key={this.props.key}>
                <div className="col">
                    <div className="row align-items-center">
                        {
                            this.props.tableCols && this.props.tableCols.map((col, index) => {

                                let colClassName = 'col ';
                                colClassName += col.hiddenMobile ? 'd-none d-sm-block ' : '';
                                colClassName += col.mobileFullWidth ? 'col-12 col-sm ' : '';
                                colClassName += col.customCssClass ? (col.customCssClass + ' ') : '';

                                return (
                                  col.ddOptions ?
                                    <div className="col dd-col" key={'col-' + index}>
                                        {col.ddOptions && Array.isArray(col.ddOptions) ?
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <i className='la la-ellipsis-h'></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    {

                                                            col.ddOptions.map((ddOption, index) => {
                                                                return (
                                                                    <DropdownItem key={'dd-' + index} onClick={ () => this.actionClicked(ddOption.action.name, ddOption.action.params) }>{ddOption.value}</DropdownItem>
                                                                )
                                                            })


                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        :
                                            <button type="button" className={col.ddOptions.iconClass} onClick={ () => this.actionClicked(col.ddOptions.iconAction, col.ddOptions.iconActionParams) }></button>
                                        }
                                    </div>
                                  : col.actions ?
                                    <div key={'col-' + index} className={colClassName}>
                                        { col.actions.map((action, index) => {
                                            return (
                                                <button key={'dd-' + index} className="btn--ts btn--ts-secondary" onClick={ () => this.actionClicked(action.name, action.params) }>{action.label}</button>
                                            )
                                        })
                                        }
                                        <p className="col--label">{col.label}</p>
                                    </div>
                                  : col.label ?
                                    <div key={'col-' + index} className={colClassName}>
                                        <div className="col--value">
                                          {
                                            col.link ?
                                                (
                                                    col.targetBlank ?
                                                        <a href={col.link} target="_blank">{col.value}</a>
                                                    :
                                                      (col.download ?
                                                        <a href={col.link} download target="_blank">{col.value}</a>
                                                        :
                                                        <Link to={col.link}>{isNaN(col.value) ? col.value.toString().replace(/_|\./g, " ") : col.value.toString().replace(/_/g, " ")}</Link>
                                                      )
                                                )
                                            :
                                            col.dateFormat ? <Moment format={col.dateFormat}>{col.value}</Moment>
                                            :
                                            col.showAsCheckbox ? <label className={(col.value === 2) ? "chk-container rejected" : "chk-container"}><input type="checkbox" disabled checked={col.value > 0} /><span className="checkmark"></span></label>
                                            :
                                            (col.value || col.value === 0) ? (isNaN(col.value) ? col.value.toString().replace(/_|\./g, " ") : col.value.toString().replace(/_/g, " "))
                                            :
                                            ''
                                          }
                                            {col.tooltip ?
                                              <div className="tooltip-container">
                                                  <span className="icon la la-caret-up"></span>
                                                  <div className="tooltip-element"><span className="tooltip-content">{col.tooltip}</span></div>
                                              </div>
                                              :
                                              ''
                                            }
                                        </div>
                                        <p className="col--label">{col.label}</p>
                                    </div>
                                  :
                                    col.checkBox ? <label key={col.id} className="chk-container">
                                        <input type="checkbox" checked={col.selected.includes(col.id)} onChange={(event) => { col.handleChange(event.target.checked, col.id) }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    : ''
                                  )
                            })
                        }
                    </div>
                </div>


            </div>
        );
    }
}

export default withCookies(TableRow);
