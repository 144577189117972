import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Preloader from "../../components/preloader/Preloader";
import ParticlesComponent from "../../components/particles/Particles";
import { Animated } from "react-animated-css";
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router';
import { compose } from 'recompose';
import AppContext from "../../context/index";

class ChangePass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            token: '',
            password1: '',
            password2: '',
            loading: false,
            redirectToLogin: false
        };
    }

    componentDidMount() {
        document.body.className = "login";
        this.checkToken();
    }

    checkToken = () => {
        const { match : {params}} = this.props;
        
        axios.get(process.env.REACT_APP_API_URL + 'password/find/' + params.token, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if(res.data.email) {
                this.setState({
                    email: res.data.email,
                    token: params.token
                })
            }
            else {
                this.setRedirect();
            }
        })
        .catch(err => {
            toast.error(err.message);
            this.setRedirect();
        });
    }

    setRedirect = () => {
        this.setState({
            redirectToLogin: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirectToLogin) {
            return <Redirect to='/login' />
        }
    }

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            loading: true
        });

        let passData = JSON.stringify({
            email: this.state.email,
            password: this.state.password1,
            password_confirmation: this.state.password2,
            token: this.state.token
        })

        axios.post(process.env.REACT_APP_API_URL + 'password/reset', passData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            
            if(res.data.success){
                toast.success(res.data.message);
                this.setState({
                    loading: false
                })

                this.setRedirect();
            }
            else{
                this.setState({
                    loading: false
                })
                toast.error(res.data.message);
            }
            
        })
        .catch(err => {
            toast.error(err.message);
            this.setState({
                loading: false
            });
        });
            
    };

    render() {
        const LoginSchema = Yup.object().shape({
            password1: Yup.string()
                .min(6, 'Must be longer than 6 characters')
                .required('Required'),
            password2: Yup.string()
                .min(6, 'Must be longer than 6 characters')
                .required('Required')
        });

        return (
            this.state.loading ? <Preloader/> :
            <div className="LoginPage">
                {this.renderRedirect()}
                <ParticlesComponent/>
                
                <Animated animationIn="fadeInLeft" animationOut="fadeOutLeft" isVisible={true}>
                    <div className="login-wrapper">
                        <div className="full-center centerer">
                            <Formik
                                validationSchema = {LoginSchema}
                            >
                                { props => {
                                    const {
                                        touched,
                                        errors,
                                        isSubmitting
                                    } = props;
                                    return (
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="password1">
                                                    Password
                                                </label>
                                                <input
                                                    id="password1"
                                                    name="password1"
                                                    placeholder="Choose a new password"
                                                    value={this.state.password1}
                                                    type="password"
                                                    onChange={this.handleChange}
                                                    className={
                                                        errors.password1 && touched.password1 ? 'form-control error' : 'form-control'
                                                    }
                                                />

                                                {errors.password1 &&
                                                touched.password1 && <div className="input-feedback">{errors.password1}</div>}
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="password2">
                                                    Confirm password
                                                </label>
                                                <input
                                                    id="password2"
                                                    name="password2"
                                                    placeholder="Re-type your new password"
                                                    value={this.state.password2}
                                                    type="password"
                                                    onChange={this.handleChange}
                                                    className={
                                                        errors.password2 && touched.password2 ? 'form-control error' : 'form-control'
                                                    }
                                                />

                                                {errors.password2 &&
                                                touched.password2 && <div className="input-feedback">{errors.password2}</div>}
                                            </div>

                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                SAVE PASSWORD
                                            </button>

                                            <div className="remember-forgot">
                                                <Link to="/login" className="link-register">Back to login</Link>
                                            </div>
                                        </form>
                                    );
                                } }
                            </Formik>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
}

ChangePass.contextType = AppContext;
export default compose(withRouter, withCookies)(ChangePass);