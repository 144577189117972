import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddUser extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{ userId: this.props.userId, roleId: this.props.roleId, userName: this.props.userName, userEmail: this.props.userEmail, userPhone: this.props.userPhone, userLocationId: this.props.userLocationId, isLimitedPm: this.props.isLimitedPm }}
                        validate={values => {
                            let errors = {};
                            if (!values.userName) {
                                errors.userName = 'Required';
                            }
                            if (!values.userEmail) {
                                errors.userEmail = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveUser(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>{values.userId ? 'Edit' : 'Add new'} user</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="userId" value={values.userId}/>
                                    <input type="hidden" name="roleId" value={values.roleId}/>
                                    <FormGroup>
                                        <label htmlFor="user--name" className="text-left--ts">Name</label>
                                        <input
                                            type="text"
                                            name="userName"
                                            id="user--name"
                                            value={values.userName}
                                            onChange={handleChange}
                                            className={errors.userName ? 'form-control error' : 'form-control'}
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="userName" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="user--email" className="text-left--ts">Email</label>
                                        <input
                                            type="text"
                                            name="userEmail"
                                            id="user--email"
                                            value={values.userEmail}
                                            onChange={handleChange}
                                            className={errors.userEmail ? 'form-control error' : 'form-control'}
                                            placeholder="Email"
                                        />
                                        <ErrorMessage name="userEmail" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="user--phone" className="text-left--ts">Phone</label>
                                        <input
                                            type="text"
                                            name="userPhone"
                                            id="user--phone"
                                            value={values.userPhone}
                                            onChange={handleChange}
                                            className={errors.userPhone ? 'form-control error' : 'form-control'}
                                            placeholder="Phone"
                                        />
                                        <ErrorMessage name="userPhone" className="form-error" component="div" />
                                    </FormGroup>

                                    {this.props.isHogarthPm &&
                                        <FormGroup>
                                            <label className="chk-container">Is limited pm
                                                <input type="checkbox" name="isLimitedPm" onChange={handleChange}
                                                       checked={!!values.isLimitedPm} id="business-show-on-project"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </FormGroup>
                                    }

                                    {this.props.roleId == 4 &&
                                        <FormGroup>
                                            <label htmlFor="user--location" className="text-left--ts">Location</label>
                                            <select
                                                name="userLocationId"
                                                id="user--location"
                                                value={values.userLocationId}
                                                onChange={handleChange}
                                                className={errors.userLocationId ? 'form-control error' : 'form-control'}
                                            >
                                                {
                                                    this.props.locations.map(location => {
                                                        return (
                                                            <option key={'location-' + location.id} value={location.id}>{location.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="userLocationId" className="form-error" component="div" />
                                        </FormGroup>
                                    }

                                    {this.props.roleId == 4 &&
                                        <FormGroup>
                                            <label htmlFor="user--activity-types" className="text-left--ts">Activity Types</label>
                                            <select
                                                name="userActivityTypeId"
                                                id="user--activity-types"
                                                value={values.userActivityTypeId}
                                                onChange={(e) => {this.props.addAT(e.target.value)}}
                                                className={errors.userActivityTypeId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Please select</option>
                                                {
                                                    this.props.activityTypes.map(at => {
                                                        return (
                                                            !at.disabled?
                                                            <option key={'activity-type-' + at.id} value={at.id}>{at.name}</option>
                                                            : ''
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="userActivityTypeId" className="form-error" component="div" />
                                        </FormGroup>
                                    }

                                    {this.props.roleId == 4 &&
                                        <ul className="list-of-activities">
                                            {this.props.editUserActivityTypes.map(at => {
                                                return (
                                                    <li key={'at-' + at.id}><button type="button" onClick={() => {this.props.removeAT(at.id)}}><i className="la la-trash-o"></i></button> {at.name}</li>
                                                )
                                            })}
                                        </ul>
                                    }


                                  {this.props.roleId == 4 &&
                                  <FormGroup>
                                    <label htmlFor="user--project-categories" className="text-left--ts">Project Categories</label>
                                    <select
                                      name="userProjectCategoryId"
                                      id="user--project-categories"
                                      value={values.userProjectCategoryId}
                                      onChange={(e) => {this.props.addPCat(e.target.value)}}
                                      className={errors.userProjectCategoryId ? 'form-control error' : 'form-control'}
                                    >
                                      <option>Please select</option>
                                      {
                                        this.props.projectCategories.map(cat => {
                                          return (
                                            !cat.disabled?
                                              <option key={'project-category-' + cat.id} value={cat.id}>{cat.title}</option>
                                              : ''
                                          )
                                        })
                                      }
                                    </select>
                                    <ErrorMessage name="userProjectCategoryId" className="form-error" component="div" />
                                  </FormGroup>
                                  }

                                  {this.props.roleId == 4 &&
                                  <ul className="list-of-project-categories">
                                    {this.props.editUserProjectCategories.map(cat => {
                                      return (
                                        <li key={'cat-' + cat.id}><button type="button" onClick={() => {this.props.removePCat(cat.id)}}><i className="la la-trash-o"></i></button> {cat.title}</li>
                                      )
                                    })}
                                  </ul>
                                  }

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE USER'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddUser);
