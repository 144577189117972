import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddIdgUnits extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{ idgUnitsId: this.props.idgUnitsId, idgUnitsName: this.props.idgUnitsName, idgUnitsEntity: this.props.idgUnitsEntity }}
                        validate={values => {
                            let errors = {};
                            if (!values.idgUnitsName) {
                                errors.idgUnitsName = 'Required';
                            }
                            if (!values.idgUnitsEntity) {
                                errors.idgUnitsEntity = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveIdgUnits(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>Add new infodesign unit</ModalHeader>
                                <ModalBody className="new--idg-unit">
                                    <input type="hidden" name="idgUnitsId" value={values.idgUnitsId}/>
                                    <FormGroup>
                                        <label htmlFor="idg-unit--name" className="text-left--ts">Name</label>
                                        <input
                                            type="text"
                                            name="idgUnitsName"
                                            id="idg-unit--name"
                                            value={values.idgUnitsName}
                                            onChange={handleChange}
                                            className={errors.idgUnitsName ? 'form-control error' : 'form-control'}
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="idgUnitName" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="idg-unit--entity" className="text-left--ts">Legal Entity</label>
                                        <input
                                            type="text"
                                            name="idgUnitsEntity"
                                            id="idg-unit--entity"
                                            value={values.idgUnitsEntity}
                                            onChange={handleChange}
                                            className={errors.idgUnitsEntity ? 'form-control error' : 'form-control'}
                                            placeholder="Legal Entity"
                                        />
                                        <ErrorMessage name="idgUnitsEntity" className="form-error" component="div" />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE UNIT'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddIdgUnits);