import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddClientUnits extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{ clientUnitsId: this.props.clientUnitsId, clientUnitsName: this.props.clientUnitsName, clientUnitsEntity: this.props.clientUnitsEntity }}
                        validate={values => {
                            let errors = {};
                            if (!values.clientUnitsName) {
                                errors.clientUnitsName = 'Required';
                            }
                            if (!values.clientUnitsEntity) {
                                errors.clientUnitsEntity = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveClientUnits(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>{(values.clientUnitsId ? 'Edit ' : 'Add new ') + 'client unit'}</ModalHeader>
                                <ModalBody className="new--client-unit">
                                    <input type="hidden" name="clientUnitsId" value={values.clientUnitsId}/>
                                    <FormGroup>
                                        <label htmlFor="client-unit--name" className="text-left--ts">Name</label>
                                        <input
                                            type="text"
                                            name="clientUnitsName"
                                            id="client-unit--name"
                                            value={values.clientUnitsName}
                                            onChange={handleChange}
                                            className={errors.clientUnitsName ? 'form-control error' : 'form-control'}
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="clientUnitName" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="client-unit--entity" className="text-left--ts">Legal Entity</label>
                                        <input
                                            type="text"
                                            name="clientUnitsEntity"
                                            id="client-unit--entity"
                                            value={values.clientUnitsEntity}
                                            onChange={handleChange}
                                            className={errors.clientUnitsEntity ? 'form-control error' : 'form-control'}
                                            placeholder="Legal Entity"
                                        />
                                        <ErrorMessage name="clientUnitsEntity" className="form-error" component="div" />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE UNIT'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddClientUnits);