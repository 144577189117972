import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddActivityType extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{ atId: this.props.atId, atName: this.props.atName, atRate: this.props.atRate, atUSDCurrency: this.props.atUSDCurrency }}
                        validate={values => {
                            let errors = {};
                            if (!values.atName) {
                                errors.atName = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveAT(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>Add new activity type</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="atId" value={values.atId}/>
                                    <FormGroup>
                                        <label htmlFor="at--name" className="text-left--ts">Name</label>
                                        <input
                                            type="text"
                                            name="atName"
                                            id="at--name"
                                            value={values.atName}
                                            onChange={handleChange}
                                            className={errors.atName ? 'form-control error' : 'form-control'}
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="atName" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="at--rate" className="text-left--ts">Rate</label>
                                        <input
                                            type="text"
                                            name="atRate"
                                            id="at--rate"
                                            value={values.atRate}
                                            onChange={handleChange}
                                            className={errors.atRate ? 'form-control error' : 'form-control'}
                                            placeholder="Rate"
                                        />
                                        <ErrorMessage name="atRate" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label className="chk-container">USD Currency
                                            <input type="checkbox" name="atUSDCurrency" checked={values.atUSDCurrency} onChange={handleChange} id="at--usd-currency" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </FormGroup>    
                                
                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE ACTIVITY TYPE'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddActivityType);