import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import AddWildcard from "../../components/addWildcard/AddWildcard";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class Wildcards extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            wildcards: [],
            loading: false,
            showAddIdgUnits: false,
            editIdgUnitsId: '',
            editIdgUnitsName: '',
            editEntityName: '',
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if(cookies.get('userRole') != 2) {
            this.context.redirectToLogin();
        }

        this.getWildcards();
    }

    openModal = (clearData) => {
        if (clearData) {
            this.setState({
                editIdgUnitsId: '',
                editIdgUnitsName: '',
                editEntityName: '',
            })
        }
        this.setState({
            showAddIdgUnits: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddIdgUnits: false
        });
    }

    getWildcards = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'wildcards', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                wildcards: res.data.user_wildcards,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    closeWildcardModal = refreshList => {
        this.setState({
            showAddIdgUnits: false
        });
        if(refreshList) {
            this.getWildcards();
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Wildcards"
                        headerOptions={[{
                            name: 'New IDG unit',
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="IdgUnitsPage">

                        <div className="TableHead">
                            <div className="TableHead--options"></div>

                            <button onClick={(e) => {this.openModal(true)}} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Wildcard
                                <AddWildcard
                                    saveWildcard={this.saveWildcard}
                                    closeModal={this.closeModal}
                                    closeWildcardModal = {this.closeWildcardModal}
                                    showPopup={this.state.showAddIdgUnits}
                                />
                            </button>    

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.wildcards.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Name',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.Wildcards,
                                                        label: 'Date',
                                                        customCssClass: 'row-head-mobile'
                                                    }
                                                ]
                                            }
                                            deleteWildcards={this.deleteWildcards}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Wildcards.contextType = AppContext;
export default withCookies(Wildcards);
