import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddBusinessUnits extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{
                            businessUnitsId: this.props.businessUnitsId,
                            businessUnitsName: this.props.businessUnitsName,
                            businessUnitsType: this.props.businessUnitsType,
                            businessUnitShowOnProject: !!this.props.businessUnitShowOnProject
                    }}
                        validate={values => {
                            let errors = {};
                            if (!values.businessUnitsName) {
                                errors.businessUnitsName = 'Required';
                            }
                            if (!values.businessUnitsType) {
                                errors.businessUnitsType = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveBusinessUnits(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>{(values.businessUnitsId ? 'Edit ' : 'Add new ')  + 'business unit'}</ModalHeader>
                                <ModalBody className="new--business-unit">
                                    <input type="hidden" name="businessUnitsId" value={values.businessUnitsId}/>
                                    <FormGroup>
                                        <label htmlFor="business-unit--name" className="text-left--ts">Name</label>
                                        <input
                                            type="text"
                                            name="businessUnitsName"
                                            id="business-unit--name"
                                            value={values.businessUnitsName}
                                            onChange={handleChange}
                                            className={errors.businessUnitsName ? 'form-control error' : 'form-control'}
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="businessUnitsName" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="business-unit--type" className="text-left--ts">Type</label>
                                        <input
                                            type="text"
                                            name="businessUnitsType"
                                            id="business-unit--type"
                                            value={values.businessUnitsType}
                                            onChange={handleChange}
                                            className={errors.businessUnitsType ? 'form-control error' : 'form-control'}
                                            placeholder="Type"
                                        />
                                        <ErrorMessage name="businessUnitsType" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label className="chk-container">Show on project
                                            <input type="checkbox" name="businessUnitShowOnProject" onChange={handleChange} checked={!!values.businessUnitShowOnProject}  id="business-show-on-project" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE UNIT'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddBusinessUnits);