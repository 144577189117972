import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import DatePickerMultiple from "react-multi-date-picker"
import "react-datepicker/dist/react-datepicker.css";
import "./AddUserWorkingPlaces.scss";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import AppContext from "../../context/index";
import {handleErrorResponse} from "../../utils/utils";
import { userStore } from "../../stores/user";
import moment from "moment";

class AddUserWorkingPlace extends Component {

    constructor(props) {
        super(props);
        let today = new Date();

        this.state = {
            UWPId: this.props.UWPId || '',
            selectedUwpDates: [],
            selectedUwpWorkingPlace: 1,
            minDate: today,
            maxDate: new Date().setMonth(new Date().getMonth() + 1),
            editUWPManualDate: null,
            selectedManualDates: [],
            renderForm: false
        };
    }

    componentDidMount() {
        if(this.props.UWPId) {
            this.getUWPEntry(this.props.UWPId);
        }else{
            this.setState({
                renderForm: true
            });
        }

        this.loadSelectedUWPDates();
    }

    /* GET UWP ENTRY DETAILS */
    getUWPEntry = (UWPId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'uwp/' + UWPId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                renderForm: true,
                loading: false,
                selectedUwpWorkingPlace: res.data.working_place,
                editUWPManualDate: res.data.manual_date
            });
        })
    }

    loadSelectedUWPDates(){
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'uwp-selected-days', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                selectedUwpDates: res.data,
            });
        })
    }

    saveUWP = (uwpData) => {
        const { cookies } = this.props;
        let data = {
            working_place: uwpData.uwpWorkingPlace || 1,
        };

        this.setState({
            loading: true
        })

        let requestMethod = "PUT";
        if (!uwpData.UWPId) {
            requestMethod = "POST";
            data['manual_dates'] = this.state.selectedManualDates.map((date) => { return date.format('YYYY-MM-DD')});
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'uwp' + (uwpData.UWPId ? ('/' + uwpData.UWPId) : ''),
            data: data,
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false
            })
            this.props.closeModal(true);
            toast.success("Working place has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddTimesheet: false
                })
            }
            handleErrorResponse(err.response);
        })
    }

    handleManualDates = (dates) => {
        this.setState({
            selectedManualDates: dates
        });
    }

    render() {
        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    {this.state.renderForm &&
                    <Formik
                        initialValues = {{
                            UWPId: this.props.UWPId,
                            uwpWorkingPlace: this.state.selectedUwpWorkingPlace,
                        }}
                        validate={values => {
                            let errors = {};
                            if (!values.uwpWorkingPlace) {
                                errors.uwpWorkingPlace = 'Required';
                            }

                            if (!this.state.UWPId && !this.state.selectedManualDates.length) {
                                errors.uwpManualDates = 'Required';
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.saveUWP(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>{this.state.UWPId ? 'Edit' : 'Add new'} working place</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="UWPId" value={values.UWPId}/>
                                    <FormGroup>
                                        <label htmlFor="uwp-working-place" className="text-left--ts">Working place</label>
                                        <select
                                            name="uwpWorkingPlace"
                                            id="uwp-working-place"
                                            value={values.uwpWorkingPlace}
                                            onChange={handleChange}
                                            className={errors.uwpWorkingPlace ? 'form-control error' : 'form-control'}
                                        >
                                            <option key='uwp-working-place-1' value='1'>Work from office</option>
                                            <option key='uwp-working-place-2' value='2'>Work from home</option>
                                        </select>
                                        <ErrorMessage name="uwpManualDates" className="form-error" component="div" />
                                    </FormGroup>

                                    <FormGroup>
                                        <label><span>Dates </span></label>
                                        {!this.state.UWPId &&
                                            <DatePickerMultiple
                                                value={this.state.selectedManualDates}
                                                className={'manual-date-input-multiple' + ((userStore.me.settings.theme === 'theme-dark') ? ' bg-dark' : ' ')}
                                                onChange={this.handleManualDates}
                                                minDate={this.state.minDate}
                                                maxDate={this.state.maxDate}
                                                multiple
                                                placeholder="Select dates"
                                                mapDays={({ date }) => {
                                                    let holidayDays = [1,7];

                                                    if (this.state.selectedUwpDates.includes(date.format('YYYY-MM-DD')) || (holidayDays.includes(date.weekDay.number))) return {
                                                        disabled: true,
                                                        style: { color: "#ccc" }
                                                    }
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                                containerStyle={{
                                                    width: "100%"
                                                }}
                                            />
                                        }
                                        {this.state.UWPId &&
                                            <DatePicker
                                                selected={new Date(this.state.editUWPManualDate)}
                                                className="manual-date-input"
                                                minDate={new Date(this.state.minDate)}
                                                maxDate={this.state.maxDate}
                                                showMonthYearDropdown
                                                disabled={this.state.UWPId ? true : false}
                                            />
                                        }

                                        <ErrorMessage name="uwpManualDate" className="form-error" component="div" />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE ENTRY'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
AddUserWorkingPlace.contextType = AppContext;
export default withCookies(AddUserWorkingPlace);
