import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddClient from "../../components/addClient/AddClient";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class Clients extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            clientsList: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddClient: false,
            editClientId: '',
            editClientName: '',
            editClientHogarthVisible: false
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getClients();
    }

    openModal = (clearData) => {
        if(clearData){
            this.setState({
                editClientId: '',
                editClientName: '',
                editClientHogarthVisible: false
            })
        }
        this.setState({
            showAddClient: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddClient: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getClients();
            }
        );
    }

    searchClientsByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getClients();
        });
        
    }

    getClients = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'clients', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                clientsList: res.data.data.paginatedClients,
                totalResults: res.data.data.totalResults,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editClient = (clientId) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'client/' + clientId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editClientId: res.data.data.id,
                editClientName: res.data.data.name,
                editClientHogarthVisible: res.data.data.hogarth_visible,
                showAddClient: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveClient = (clientData) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        //if we have a client id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if(clientData.clientId){
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'clients' + (clientData.clientId ? ('/' + clientData.clientId) : ''),
            data: {
                name: clientData.clientName,
                hogarth_visible: clientData.hogarthVisible
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getClients();
            this.setState({
                loading: false,
                showAddClient: false
            })
            toast.success("Client has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddClient: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    deleteClient = async (clientId) => {
        const { cookies } = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if( result ) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'clients/' + clientId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getClients();
                toast.success("Client has been deleted.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
                toast.error("An error occured, please try again later.");
            })
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Clients" 
                        headerOptions={[{
                            name: 'New Client', 
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="ClientsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchClientsByName} placeholder='Search clients by name'/>
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => {this.openModal(true)}} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Client
                                <AddClient
                                    clientId={this.state.editClientId}
                                    clientName={this.state.editClientName}
                                    hogarthVisible={this.state.editClientHogarthVisible}
                                    saveClient={this.saveClient}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddClient}
                                />
                            </button>    

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.clientsList.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Client',
                                                        mobileFullWidth: true,
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.hogarth_visible,
                                                        label: 'Hogarth Visible',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit this client',
                                                                action: {
                                                                    name: 'editClient',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove this client',
                                                                action: {
                                                                    name: 'deleteClient',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editClient={this.editClient}
                                            deleteClient={this.deleteClient}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)}/>
                    </div>
                </div>
            </div>
        );
    }
}
Clients.contextType = AppContext;
export default withCookies(Clients);