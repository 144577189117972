import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, Legend } from 'recharts';
import DatePicker from "react-datepicker";
import Autosuggest from 'react-autosuggest';
import Moment from 'react-moment';
import { FormGroup } from 'reactstrap';
import AppContext from "../../context/index";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { CSVLink, CSVDownload } from "react-csv";
import userStore from "../../stores/user";

class TimesheetReport extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        let today = new Date();
        let yesterday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

        this.state = {
            reportData: [],
            startDate: yesterday,
            endDate: today,
            selectedUser: '',
            selectedUserId: -1,
            devsList: [],
            usersSuggestions: [],
            reportDataGraph: [],
            projectTypesList: [], //filters dd
            filtersProjectTypeId: -1
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();
        this.getProjectTypesList();
        this.setState({
            devsList: userStore.getDEVs()
        })
    }
    getProjectTypesList = () => {
        /* Project Types - Dropdown Options */
        const { cookies } = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'project-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectTypesList: res.data.data
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
        })
    }
    onChangeDeveloper = (event, { newValue }) => {
        this.setState({
            selectedUser: newValue
        });
    };
    getUsersSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.devsList.filter(user =>
            user.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !user.disabled
        );
    };
    onUsersSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            usersSuggestions: this.getUsersSuggestions(value)
        });
    };
    onUsersSuggestionsClearRequested = () => {
        this.setState({
            usersSuggestions: []
        });
    };
    renderUsersSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onUsersSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedUserId: suggestion.id
        }, () => {
            this.getReportData();
        })
    }
    getUsersSuggestionValue = suggestion => suggestion.name;

    handleStartDate = (date) => {
        let endDate = this.state.endDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getReportData());
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getReportData());
    }

    getReportData = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'reports-timesheet', {
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                userId: this.state.selectedUserId,
                type: this.state.filtersProjectTypeId != -1 ? this.state.projectTypesList[this.state.filtersProjectTypeId].id : -1,
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            console.log(res);

            let activityReport = [];
            for(let i in res.data.data.ts) {
                for(let j in res.data.data.ts[i]) {
                    activityReport[res.data.data.ts[i][j].activity_name] = (activityReport[res.data.data.ts[i][j].activity_name] || 0) + parseInt(res.data.data.ts[i][j].hours)
                }
            }

            let activityReportGraph = [];
            for(let i in activityReport) {
                activityReportGraph.push({
                    name: i,
                    value: activityReport[i]
                })
            }
            this.setState({
                reportData: res.data.data.ts,
                reportDataGraph: activityReportGraph,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })

    }

    filtersChanged = (filterId, filterValue) => {
        this.setState(
            {
                [filterId]: filterValue,
                page: 1, // reset pagination when status filter is changed
                paginationKey: new Date().getTime() //unique key to reset pagination component
            },
            () => {
                this.getReportData();
            });
    }

    render() {

        const userInputProps = {
            placeholder: 'Developer\'s name',
            value: this.state.selectedUser,
            onChange: this.onChangeDeveloper
        };

        const COLORS = ['#0043fe', '#4173ff', '#608afd', '#81a3fe', '#a4bcff', '#c4d4ff', '#002da9', '#002489', '#3955a2', '#061a52', '#5971b6', '#6886d9'];

        let csvData = [];
        csvData.push(["Project", "Date", "User", "Hours", "Activity", "Extra request", "QA", "Overtime", "Details", "MJN"])

        for(let i in this.state.reportData) {
            for (let j in this.state.reportData[i]) {
                csvData.push([
                    this.state.reportData[i][j].project_title || '',
                    this.state.reportData[i][j].manual_date || '',
                    this.state.reportData[i][j].user_name || '',
                    this.state.reportData[i][j].hours || '',
                    this.state.reportData[i][j].activity_name || '',
                    this.state.reportData[i][j].extra_request || '',
                    this.state.reportData[i][j].qa_issue || '',
                    this.state.reportData[i][j].overtime || '',
                    this.state.reportData[i][j].details || '',
                    this.state.reportData[i][j].mjn || '',
                ]);
            }
        }

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="77"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Reports::Timesheet"
                    />
                    <div className="ReportsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Developer:
                                    </div>
                                    <div className="filter--dd xxl">
                                        <FormGroup>
                                            <Autosuggest
                                                suggestions={this.state.usersSuggestions}
                                                onSuggestionsFetchRequested={this.onUsersSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onUsersSuggestionsClearRequested}
                                                getSuggestionValue={this.getUsersSuggestionValue}
                                                renderSuggestion={this.renderUsersSuggestion}
                                                onSuggestionSelected={this.onUsersSuggestionSelected}
                                                inputProps={userInputProps}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-magic"></i>
                                        Type:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                    { this.state.filtersProjectTypeId != -1 ? this.state.projectTypesList[this.state.filtersProjectTypeId].name : 'Select' }
                                                    <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                    this.state.projectTypesList.map((type, index) => {
                                                        return (
                                                            <div key={'type-' + index} onClick={() => this.filtersChanged("filtersProjectTypeId", index)}>
                                                                <DropdownItem>{type.name}</DropdownItem>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDate}
                                                minDate={this.state.startDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.reportData ?
                            <div>

                                <div className="responsive-chart">
                                    <ResponsiveContainer>
                                        <PieChart width={300} height={300}>
                                            <Pie
                                                data={this.state.reportDataGraph}
                                                fill="#4D7CFE"
                                                dataKey="value"
                                                >
                                                {
                                                    this.state.reportDataGraph.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                }
                                            </Pie>
                                            <Legend />
                                            <Tooltip/>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className="export-link">
                                    <CSVLink data={csvData} filename={"timesheet_report.csv"}>
                                        Export to CSV
                                    </CSVLink>
                                </div>

                                <div className="report-table">
                                {
                                    Object.keys(this.state.reportData).map((dailyEntries, key) => {
                                        return (
                                            <div key={'day-' + key} className="TableMainContainer">
                                                <div className="daily-summary"><Moment format={'dddd DD.MM.YYYY'}>{dailyEntries}</Moment></div>
                                                {
                                                    this.state.reportData[dailyEntries].map((item, index) => {
                                                        return (
                                                            <TableRow
                                                                key = {'row-' + index}
                                                                tableCols = {
                                                                    [
                                                                        {
                                                                            value: item.project_title,
                                                                            label: 'Project',
                                                                            link: '/project/' + item.project_id,
                                                                            customCssClass: 'row-details'
                                                                        },
                                                                        {
                                                                            value: item.manual_date,
                                                                            dateFormat: 'DD.MM.YYYY', //momentjs formats
                                                                            label: 'Date'
                                                                        },
                                                                        {
                                                                            value: item.user_name,
                                                                            label: 'User'
                                                                        },
                                                                        {
                                                                            value: item.hours,
                                                                            label: 'Hours'
                                                                        },
                                                                        {
                                                                            value: item.activity_name,
                                                                            label: 'Activity',
                                                                            customCssClass: 'row-details'
                                                                        },
                                                                        {
                                                                            value: item.extra_request,
                                                                            label: 'Extra',
                                                                            showAsCheckbox: true
                                                                        },
                                                                        {
                                                                            value: item.qa_issue,
                                                                            label: 'QA',
                                                                            showAsCheckbox: true
                                                                        },
                                                                        {
                                                                            value: item.overtime,
                                                                            label: 'OT',
                                                                            showAsCheckbox: true
                                                                        },
                                                                        {
                                                                            value: item.details,
                                                                            label: 'Details',
                                                                            customCssClass: 'row-details'
                                                                        },
                                                                        {
                                                                            value: item.mjn,
                                                                            label: 'MJN',
                                                                            customCssClass: 'row-details'
                                                                        }
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                )}
                                </div>
                            </div>
                        : ''}

                    </div>
                </div>
            </div>
        );
    }
}
TimesheetReport.contextType = AppContext;
export default withCookies(TimesheetReport);
