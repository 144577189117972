import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddClientUnits from "../../components/addClientUnits/AddClientUnits";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class ClientUnits extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            clientUnitsList: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddClientUnits: false,
            editClientUnitsId: '',
            editClientUnitsName: '',
            editEntityName: '',
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getClientUnits();
    }

    openModal = (clearData) => {
        if (clearData) {
            this.setState({
                editClientUnitsId: '',
                editClientUnitsName: '',
                editEntityName: '',
            })
        }
        this.setState({
            showAddClientUnits: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddClientUnits: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getClientUnits();
            }
        );
    }

    searchClientUnitsByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getClientUnits();
        });
        
    }

    getClientUnits = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'client-units', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                clientUnitsList: res.data.data.paginatedClientUnits,
                totalResults: res.data.data.totalResults,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editClientUnits = (clientUnitsId) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'client-unit/' + clientUnitsId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editClientUnitsId: res.data.data.id,
                editClientUnitsName: res.data.data.name,
                editEntityName: res.data.data.legal_entity,
                showAddClientUnits: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveClientUnits = (clientUnitsData) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        //if we have a client id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (clientUnitsData.clientUnitsId) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'client-units' + (clientUnitsData.clientUnitsId ? ('/' + clientUnitsData.clientUnitsId) : ''),
            data: {
                name: clientUnitsData.clientUnitsName,
                legal_entity: clientUnitsData.clientUnitsEntity
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getClientUnits();
            this.setState({
                loading: false,
                showAddClientUnits: false
            })
            toast.success("Client unit has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddClientUnits: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    deleteClientUnits = async (clientUnitsId) => {
        const {cookies} = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'client-units/' + clientUnitsId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getClientUnits();
                toast.success("Client unit has been deleted.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
                toast.error("An error occured, please try again later.");
            })
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Client units" 
                        headerOptions={[{
                            name: 'New Client unit', 
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="ClientUnitsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchClientUnitsByName} placeholder='Search client unit by name'/>
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => {this.openModal(true)}} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Unit
                                <AddClientUnits
                                    clientUnitsId={this.state.editClientUnitsId}
                                    clientUnitsName={this.state.editClientUnitsName}
                                    clientUnitsEntity={this.state.editEntityName}
                                    saveClientUnits={this.saveClientUnits}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddClientUnits}
                                />
                            </button>    

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.clientUnitsList.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Name',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.legal_entity,
                                                        label: 'Legal entity',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit unit',
                                                                action: {
                                                                    name: 'editClientUnits',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove unit',
                                                                action: {
                                                                    name: 'deleteClientUnits',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editClientUnits={this.editClientUnits}
                                            deleteClientUnits={this.deleteClientUnits}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)}/>
                    </div>
                </div>
            </div>
        );
    }
}
ClientUnits.contextType = AppContext;
export default withCookies(ClientUnits);
