import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import {Link} from "react-router-dom";
import { Collapse } from 'reactstrap';
import './Sidebar.scss';
import axios from 'axios';
import logoLightTheme from '../../assets/img/logo.png';
import logoDarkTheme from '../../assets/img/logo-dark-theme.png';
import AppContext from "../../context/index";
import { userStore } from "../../stores/user";

class Sidebar extends Component {
    constructor(props){
      super(props);
      const { cookies } = this.props;
      this.toggleSubmenu = this.toggleSubmenu.bind(this);
      this.hasApprovalMenu = (typeof userStore.me === "undefined" || userStore.me == null) ? 999 : userStore.me.has_approval;

      this.state = {
        collapseSubmenu5: false,
        collapseSubmenu7: false,
        role: cookies.get('userRole'),
        isIT: cookies.get('isIT'),
        isLimitedPm: parseInt(cookies.get('isLimitedPm')),
        menuItems:
         [
         !parseInt(cookies.get('isLimitedPm')) ? {
              title: "Dashboard",
              cssIconClass: "la la-dashboard",
              href: "/",
              id: 1,
              minRole: 1,
          } : {},
         !parseInt(cookies.get('isIT')) ? {
              title: "Projects",
              cssIconClass: "la la-suitcase",
              href: "/projects",
              id: 2,
              minRole: 1
          } : {},
           !parseInt(cookies.get('isLimitedPm')) && !parseInt(cookies.get('isIT')) ? {
            title: "Approval",
            cssIconClass: "la la-check-circle",
            href: "/approval",
            id: 3,
            minRole: this.hasApprovalMenu
          } : {},
            !parseInt(cookies.get('isLimitedPm')) && !parseInt(cookies.get('isIT')) ? {
             title: "Working places",
             cssIconClass: "la la-tasks",
             href: "/user-working-places",
             id: 6,
             minRole: 1
            } : {},
           !parseInt(cookies.get('isLimitedPm')) && !parseInt(cookies.get('isIT')) ? {
            title: "Timesheet",
            cssIconClass: "la la-clock-o",
            href: "/timesheet",
            id: 4,
            minRole: 1
          } : {},
           !parseInt(cookies.get('isLimitedPm')) ? {
            title: "Configuration",
            cssIconClass: "la la-cog",
            href: "",
            id: 5,
            minRole: 2,
            submenu: [
              {
                title: "Clients",
                cssIconClass: "la la-user-secret",
                href: "/config/clients",
                id: 51
              },
              {
                title: "Client units",
                cssIconClass: "la la-briefcase",
                href: "/config/client-units",
                id: 52
              },
              {
                title: "Business units",
                cssIconClass: "la la-money",
                href: "/config/business-units",
                id: 62
              },
              {
                title: "Project Categories",
                cssIconClass: "la la-flask",
                href: "/config/project-categories",
                id: 61
              },
              {
                title: "IDG units",
                cssIconClass: "la la-building",
                href: "/config/idg-units",
                id: 53
              },
              {
                title: "Brands",
                cssIconClass: "la la-registered",
                href: "/config/brands",
                id: 54
              },
              {
                title: "Activity Types",
                cssIconClass: "la la-code-fork",
                href: "/config/activity-types",
                id: 55
              },
              {
                title: "Hogarth PMs",
                cssIconClass: "la la-male",
                href: "/config/hogarth-pms",
                id: 56
              },
              {
                title: "IDG PMs",
                cssIconClass: "la la-users",
                href: "/config/idg-pms",
                id: 57
              },
              {
                title: "ITs",
                cssIconClass: "la la-users",
                href: "/config/its",
                id: 63
              },
              {
                title: "Developers",
                cssIconClass: "la la-code",
                href: "/config/developers",
                id: 58
              },
              {
                title: 'Wildcards',
                cssIconClass: 'la la-calendar-plus-o',
                href: '/config/wildcards',
                id: 59
              },
              {
                title: "Expenses Categories",
                cssIconClass: "la la-pagelines",
                href: "/config/expenses-categories",
                id: 60
              },
            ]
          } : {},
           !parseInt(cookies.get('isLimitedPm')) && !parseInt(cookies.get('isIT'))? {
            title: "Reports",
            cssIconClass: "la la-pie-chart",
            href: "",
            id: "7",
            minRole: 2,
            submenu: [
              {
                title: "Income",
                cssIconClass: "la la-money",
                href: "/reports/income",
                id: "71"
              },
              {
                title: "Projects",
                cssIconClass: "la la-suitcase",
                href: "/reports/projects",
                id: 85
              },
              {
                title: "Missing POs",
                cssIconClass: "la la-exclamation-circle",
                href: "/reports/missing-pos",
                id: 72
              },
              {
                title: "Missing CEs",
                cssIconClass: "la la-exclamation-circle",
                href: "/reports/missing-ces",
                id: 80
              },
              {
                title: "Missing Resource H",
                cssIconClass: "la la-exclamation-circle",
                href: "/reports/missing-resources",
                id: 81
              },
              {
                title: "Missing Timesheet",
                cssIconClass: "la la-frown-o",
                href: "/reports/missing-timesheet",
                id: 73
              },
              {
                title: "Occupancy",
                cssIconClass: "la la-binoculars",
                href: "/reports/occupancy",
                id: 74
              },
              // {
              //   title: "Loading by Proj. Type",
              //   cssIconClass: "la la-puzzle-piece",
              //   href: "/reports/loading-proj-type",
              //   id: 75
              // },
              {
                title: "Loading by Act. Type",
                cssIconClass: "la la-server",
                href: "/reports/loading-activity-type",
                id: 76
              },
              {
                title: "Working places",
                cssIconClass: "la la-tasks",
                href: "/reports/working-places",
                id: 77
              },
              {
                title: "Timesheet",
                cssIconClass: "la la-bar-chart-o",
                href: "/reports/timesheet",
                id: 78
              },
              {
                title: "Overtime",
                cssIconClass: "la la-hourglass-end",
                href: "/reports/overtime",
                id: 79
              },
              {
                title: "Category Profitability ",
                cssIconClass: "la la-server",
                href: "/reports/by-project-category",
                id: 82
              },
              {
                title: "Category Loading",
                cssIconClass: "la la-server",
                href: "/reports/cat-occupacy-per-day",
                id: 83
              }
            ]
          } : {}
        ],
        menuProjects: []
      }
    }

    componentDidMount = () => {
        this.getMVPs();
        userStore.triggerCheckApproval = () => this.checkApproval();
    };

    checkApproval = () => {
        if(!this.state.isLimitedPm){
            this.state.menuItems.filter(item => item.id === 3)[0].minRole = userStore.me.has_approval;
        }
    };

    getMVPs = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'projects-mvp', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                menuProjects: res.data.data,
                loading: false
            });
        })
    }

    toggleSubmenu = (menuParentId) => {
      this.setState({
        [menuParentId]: !this.state[menuParentId]
      })
    }

    render() {
        return (
            <div className="Sidebar">
              <p className="SidebarTitle d-none d-lg-block">
                <Link to="/">
                  <AppContext.Consumer>{({theme}) =>
                    (
                      <img src={logoLightTheme} width={140} alt="timecast.io"/>
                    )}
                  </AppContext.Consumer>
                </Link>
              </p>
              <ul className="menu-items">
                {
                  this.state.menuItems.map((item) => {
                    if(this.state.role >= item.minRole)
                    return(
                      <li key={item.id}>
                        <Link onClick={(e) => { if(item.submenu) e.preventDefault(); this.toggleSubmenu('collapseSubmenu' + item.id) }} to={item.href}>
                          <i className={item.cssIconClass}></i>
                          <span className="d-none d-lg-inline-flex">{item.title}</span>
                        </Link>
                        <div className="submenu-container">
                          <Collapse isOpen={(item.id == 5 && this.state.collapseSubmenu5) || (item.id == 7 && this.state.collapseSubmenu7)}>
                              <ul>
                                {item.submenu &&
                                  (
                                    item.submenu.map(subItem => {
                                      return ((parseInt(this.state.isIT) ? (subItem.title == 'Developers') : true) &&
                                        <li key={subItem.id}>
                                          <Link to={subItem.href} className={this.props.selectedSubmenu == subItem.id ? 'active': ''}>
                                            <i className={subItem.cssIconClass}></i>
                                            <span>{subItem.title}</span>
                                          </Link>
                                        </li>
                                      )
                                    })
                                  )
                                }
                              </ul>
                            </Collapse>
                          </div>
                      </li>
                    )
                  })
                }
              </ul>
              {this.state.menuProjects.length > 0 && !this.state.isIT &&
                <div className="projects-shortcuts">
                  <h3 className="projects-submenu d-none d-lg-flex">
                    <span>Projects</span>
                  </h3>
                  <ul className="menu-items d-none d-lg-block">
                    {
                      this.state.menuProjects.map((item, key) => {
                        return <li className="project-menu-item" key={'project-' + key}><a href={'/project/' + item.entity_id}><span>{item.project_title.toString().replace(/_/g, " ")}</span></a></li>
                      })
                    }
                  </ul>
                </div>
              }
            </div>
        );
    }
}

export default withCookies(Sidebar);
