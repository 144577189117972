import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddFeedbackApprove extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={ this.props.showPopup } centered wrapClassName="component--modal-feedback">
                    <Formik
                        initialValues = {{ teId: this.props.teId, feedbackApprove: '', decision: 2 }}
                        validate={values => {
                            let errors = {};
                            if (!values.feedbackApprove) {
                                errors.feedbackApprove = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            if(this.props.isMultiple){
                                this.props.approveTSEntries(false, values.feedbackApprove);
                            }else{
                                this.props.approveTSEntry(values);
                            }
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            handleChange,
                            isSubmitting
                        }) => (
                            <Form>
                                <ModalHeader>{'Add argument for the rejected timesheet ' + (this.props.isMultiple? 'entries' : 'entry')}</ModalHeader>
                                <ModalBody className="feedback">
                                    <input type="hidden" name="teId" value={values.teId}/>
                                    <input type="hidden" name="decision" value={values.decision}/>
                                    <FormGroup>
                                        <label htmlFor="client--name" className="text-left--ts">Argument</label>
                                        <input type="text" name="feedbackApprove" id="feedback--approve" placeholder="Argument"
                                            value={values.feedbackApprove} onChange={handleChange}
                                            className={errors.feedbackApprove ? 'form-control error' : 'form-control'}
                                        />
                                        <ErrorMessage name="feedbackApprove" className="form-error" component="div" />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SUBMITING ...' : 'ADD ARGUMENT'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddFeedbackApprove);
