import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './Paginate.scss';
import { withCookies } from 'react-cookie';

class Paginate extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentPage: 1
        }
    }
    
    pageChange = pageNo => {
        this.setState({
            currentPage: pageNo
        })
        this.props.triggerPageChange(pageNo);
    }

    render() {
        const pages = [];
        for(let i = 0; i < this.props.pagesCounter; i++){
            pages.push(i);
        }
        return (
            <Pagination className="paginate--component" >
                <PaginationItem disabled={this.state.currentPage === 1}>
                    <PaginationLink previous onClick={() => this.pageChange(this.state.currentPage-1)}/>
                </PaginationItem>

                { 
                    pages.map((page, index) => {
                        return(
                            <PaginationItem hidden={((index+3) < this.state.currentPage) || (index > (this.state.currentPage+1))} key={index} active={index + 1 === this.state.currentPage}>
                                <PaginationLink onClick={() => this.pageChange(index+1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        )
                    })
                }
                
                <PaginationItem disabled={this.state.currentPage >= this.props.pagesCounter}>
                    <PaginationLink next onClick={() => this.pageChange(this.state.currentPage+1)}/>
                </PaginationItem>
            </Pagination>
        );
    }
}

export default withCookies(Paginate);