import React, { Component } from 'react'
import Pusher from 'pusher-js';
import Dashboard from './modules/dashboard/Dashboard.js'
import Projects from './modules/projects/Projects.js'
import ProjectsReport from './modules/reports/Projects.js'
import ProjectDetails from './modules/projects/ProjectDetails.js'
import Clients from './modules/clients/Clients.js'
import Brands from './modules/brands/Brands.js'
import ActivityTypes from './modules/activityTypes/ActivityTypes.js'
import Users from './modules/users/Users.js'
import Login from './modules/login/Login.js'
import Forgot from './modules/forgot/Forgot.js'
import ChangePass from './modules/changepass/ChangePass.js'
import Logout from './modules/logout/Logout.js'
import './App.scss'
import { Route } from 'react-router-dom'
import KanbanDesk from './modules/kanbanDesk/KanbanDesk.js'
import ClientUnits from './modules/clientUnits/ClientUnits';
import IdgUnits from './modules/idgUnits/IdgUnits';
import Timesheet from './modules/timesheet/Timesheet';
import Approval from './modules/approval/Approval';
import Income from './modules/reports/Income';
import MissingPOs from './modules/reports/MissingPOs';
import MissingCEs from './modules/reports/MissingCEs';
import MissingResourceHours from './modules/reports/MissingResourceHours';
import MissingTimesheet from './modules/reports/MissingTimesheet';
import Occupancy from './modules/reports/Occupancy';
import LoadingByActivityType from './modules/reports/LoadingByActivityType';
import TimesheetReport from './modules/reports/Timesheet';
import Overtime from './modules/reports/Overtime';
import Wildcards from './modules/wildcards/Wildcards';
import { ToastContainer } from 'react-toastify';
import ExpensesCategories from "./modules/expensesCategories/ExpensesCategories";
import ProjectCategories from "./modules/projectCategories/ProjectCategories";
import ByProjectCategory from "./modules/reports/ByProjectCategory";
import OccupacyPerDay from "./modules/reports/OccupacyPerDay";
import AppContext from "./context/index";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { userStore } from "./stores/user";
import { ls } from "services/ls";
import BusinessUnits from "./modules/businessUnits/BusinessUnits";
import UserWorkingPlaces from "./modules/userWorkingPlaces/UserWorkingPlaces";
import WorkingPlaces from "./modules/reports/WorkingPlaces";


class App extends Component {

    constructor(props) {
        super(props);
        const { cookies } = this.props;

        /** Pusher Config Socket **/
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            //Pusher.logToConsole = true;
        }

        const socket = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            forceTLS: true
        });

        this.state = {
            redirectToLogin: this.redirectToLogin,
            hideLoading: this.hideLoading,
            theme: 'theme-light',
            toggleTheme: this.toggleTheme,
            getSettings: this.getSettings,
            channel: socket.subscribe('codezilla-timecast')
        };

        document.documentElement.classList.add(this.state.theme);
        userStore.init(); /* needs to stay in constructor */
    }

    setSettings = () => {
        const { cookies } = this.props;

        if(cookies.get('authToken') && typeof userStore.me.settings !== "undefined") {
            let theme_class = userStore.me.settings.theme ? userStore.me.settings.theme : 'theme-light';
            document.documentElement.classList.remove('theme-light', 'theme-dark');
            document.documentElement.classList.add(theme_class);
        }
    }

    redirectToLogin = () => {
        const {cookies} = this.props;
        cookies.remove('authToken');
        window.location = "/login";
    }

    toggleTheme = () => {
        const { cookies } = this.props;

        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + 'settings',
            data: {
                theme: this.state.theme === 'theme-light' ? 'theme-dark' : 'theme-light'
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                theme: res.data.user_settings.theme
            }, () => {
                document.documentElement.classList.remove('theme-light', 'theme-dark');
                document.documentElement.classList.add(res.data.user_settings.theme);
            });

            /* update localstorage settings theme */
            userStore.me.settings.theme = res.data.user_settings.theme;
            ls.set('me', userStore.me);
        })
    }

    checkForAccess(){
        const { cookies } = this.props;
        if(parseInt(cookies.get('isIT'))){
            const allowedPaths = [
                '/',
                '/login',
                '/config/developers'
            ];

            if(!allowedPaths.includes(window.location.pathname)){
                this.redirectToLogin();
            }
        }
    }

    componentDidMount(){
        document.title = "Codezilla Timecast";
        this.checkForAccess();

        this.setSettings();

        this.state.channel.bind('update-data', function(data) {
            console.log('%c '+ data.message, 'font-weight:bold;color:orange');
            if (data.message === "users-update") {
                userStore.init('users');
            }
            if (data.message === "clients-update") {
                userStore.init('clients');
            }
            if (data.message === "brands-update") {
                userStore.init('brands');
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkForAccess();
    }

    render() {

        return (
            <AppContext.Provider value={this.state}>
                <div className={"App"}>
                    <ToastContainer autoClose={3000} />
                    <Route exact path="/" render={() => (<Dashboard/>)}/>
                    <Route exact path="/projects" render={() => (<Projects/>)}/>
                    <Route path="/project/:projectId" render={() => (<ProjectDetails/>)}/>
                    <Route exact path="/login" render={() => (<Login/>)}/>
                    <Route exact path="/forgot" render={() => (<Forgot/>)}/>
                    <Route exact path="/change-password/:token" render={() => (<ChangePass/>)}/>
                    <Route exact path="/timesheet" render={() => (<Timesheet/>)}/>
                    <Route exact path="/kanban" render={() => (<KanbanDesk/>)}/>
                    <Route exact path="/approval" render={() => (<Approval/>)}/>
                    <Route exact path="/user-working-places" render={() => (<UserWorkingPlaces/>)}/>

                    {/* Configuration routes */}
                    <Route exact path="/config/clients" render={() => (<Clients/>)}/>
                    <Route exact path="/config/client-units" render={() => (<ClientUnits/>)}/>
                    <Route exact path="/config/business-units" render={() => (<BusinessUnits/>)}/>
                    <Route exact path="/config/idg-units" render={() => (<IdgUnits/>)}/>
                    <Route exact path="/config/project-categories" render={() => (<ProjectCategories/>)}/>
                    <Route exact path="/config/brands" render={() => (<Brands/>)}/>
                    <Route exact path="/config/activity-types" render={() => (<ActivityTypes/>)}/>
                    <Route exact path="/config/hogarth-pms" render={() => (<Users roleId="3"/>)}/>
                    <Route exact path="/config/idg-pms" render={() => (<Users roleId="2"/>)}/>
                    <Route exact path="/config/its" render={() => (<Users roleId="6"/>)}/>
                    <Route name="test" exact path="/config/developers" render={() => (<Users roleId="4"/>)}/>
                    <Route exact path="/config/wildcards" render={() => (<Wildcards/>)}/>
                    <Route exact path="/config/expenses-categories" render={() => (<ExpensesCategories/>)}/>

                    {/* Reports routes */}
                    <Route exact path="/reports/income" render={() => (<Income/>)}/>
                    <Route exact path="/reports/projects" render={() => (<ProjectsReport/>)}/>
                    <Route exact path="/reports/missing-pos" render={() => (<MissingPOs/>)}/>
                    <Route exact path="/reports/missing-ces" render={() => (<MissingCEs/>)}/>
                    <Route exact path="/reports/missing-resources" render={() => (<MissingResourceHours/>)}/>
                    <Route exact path="/reports/missing-timesheet" render={() => (<MissingTimesheet/>)}/>
                    <Route exact path="/reports/occupancy" render={() => (<Occupancy/>)}/>
                    <Route exact path="/reports/loading-activity-type" render={() => (<LoadingByActivityType/>)}/>
                    <Route exact path="/reports/timesheet" render={() => (<TimesheetReport/>)}/>
                    <Route exact path="/reports/overtime" render={() => (<Overtime/>)}/>
                    <Route exact path="/reports/by-project-category" render={() => (<ByProjectCategory/>)}/>
                    <Route exact path="/reports/cat-occupacy-per-day" render={() => (<OccupacyPerDay/>)}/>
                    <Route exact path="/reports/working-places" render={() => (<WorkingPlaces/>)}/>

                    <Route exact path="/logout" render={() => (<Logout/>)}/>
                </div>

            </AppContext.Provider>

        );
    }
}

export default withCookies(App);
