import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import NoResults from "../../components/noResults/NoResults";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import NumberFormat from 'react-number-format';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import DatePicker from "react-datepicker";
import AppContext from "../../context/index";
import userStore from "../../stores/user";
import {CSVLink} from "react-csv";

class Income extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            reportData: [],
            reportDetails: [],
            clientsList: [],
            clientPMsList: [],
            idgPMsList: [],
            hogarthPMsList: [],
            filtersTitle: '',
            filtersStartDate: new Date(new Date().getFullYear(), 0, 1),
            filtersEndDate: new Date(),
            filtersClientId: null,
            filtersClientPMId: null,
            filtersIdgPMid: null,
            filtersHogarthPMid: null,
            totalIncome: 0
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();

        this.setState({
            clientPMsList: userStore.getClientPMs(),
            idgPMsList: userStore.getIdPMs(),
            hogarthPMsList: userStore.getHogarthPMs(),
            clientsList: userStore.clients
        });
    }

    handleStartDate = (date) => {
        let filtersEndDate = this.state.filtersEndDate;

        if(date > this.state.filtersEndDate){
            filtersEndDate = date;
        }

        this.setState({
            filtersStartDate: date,
            filtersEndDate: filtersEndDate
        }, () => this.getReportData());
    }

    handleEndDate = (date) => {
        this.setState({
            filtersEndDate: date
        }, () => this.getReportData());
    }

    getReportData = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        this.getDetailedReport();
        axios.get(process.env.REACT_APP_API_URL + 'reports-income', {
            params: {
                projectTitle: this.state.filtersTitle,
                startDate: this.state.filtersStartDate,
                endDate: this.state.filtersEndDate,
                clientId: this.state.filtersClientId != null ? this.state.clientsList[this.state.filtersClientId].id : null,
                clientPM: this.state.filtersClientPMId != null ? this.state.clientPMsList[this.state.filtersClientPMId].id : null,
                idgPM: this.state.filtersIdgPMid != null ? this.state.idgPMsList[this.state.filtersIdgPMid].id : null,
                hogarthPM: this.state.filtersHogarthPMid != null ? this.state.hogarthPMsList[this.state.filtersHogarthPMid].id : null
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            let repData = [];
            let sum = 0;
            for(let i in res.data.data) {
                repData.push({
                    name: i,
                    income: res.data.data[i]
                });
                sum += res.data.data[i];
            }

            this.setState({
                loading: false,
                reportData: repData,
                totalIncome: sum
            });

        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    getDetailedReport = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'reports-income-by-month', {
            params: {
                projectTitle: this.state.filtersTitle,
                startDate: this.state.filtersStartDate,
                endDate: this.state.filtersEndDate,
                clientId: this.state.filtersClientId != null ? this.state.clientsList[this.state.filtersClientId].id : null,
                clientPM: this.state.filtersClientPMId != null ? this.state.clientPMsList[this.state.filtersClientPMId].id : null,
                idgPM: this.state.filtersIdgPMid != null ? this.state.idgPMsList[this.state.filtersIdgPMid].id : null,
                hogarthPM: this.state.filtersHogarthPMid != null ? this.state.hogarthPMsList[this.state.filtersHogarthPMid].id : null
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            console.log(res);
            this.setState({
                loading: false,
                reportDetails: res.data.data
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    filtersChanged = (filterId, filterValue) => {
        this.setState(
            {
                [filterId]: filterValue
            },
            () => {
                this.getReportData();
            });
    }

    clearFilters = () => {
        document.getElementById('filterByTitle').value = '';
        this.setState({
            filtersTitle: '',
            filtersStartDate: new Date(new Date().getFullYear(), 0, 1),
            filtersEndDate: new Date(),
            filtersClientId: null,
            filtersClientPMId: null,
            filtersIdgPMid: null,
            filtersHogarthPMid: null
        }, () => this.getReportData());
    }

    render() {
        let csvData = [];
        csvData.push(["Project", "Date", "PO number", "Client unit", "Client","Client's PM", "Value", "Invoice", "Invoice date"])

        this.state.reportDetails.map((po, key) => {
                csvData.push([
                    po.project_title|| '',
                    po.date || '',
                    po.po_number || '',
                    po.client_unit_name || '',
                    po.client_name || '',
                    po.client_pm_name || '',
                    po.value ? '€'+po.value : '',
                    po.invoice_id ? '#'+po.invoice_id : '',
                    po.invoice_date ? '#'+po.invoice_date : ''
                ]);
        });

        const NumberMask = (value) => (
            <div>
                <NumberFormat
                    value={parseInt(value)}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="€"
                />
                {/* <p className="footnote-click">click for more details</p> */}
            </div>
          )

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="71"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle={ "Reports::Income • total: " + this.state.totalIncome }
                    />
                    <div className="ReportsPage">
                        {this.state.reportData && this.state.reportData.length > 1 &&
                            <div className="responsive-chart">
                                <ResponsiveContainer>
                                    <AreaChart data={this.state.reportData}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#4d7cfe" stopOpacity={0.9}/>
                                            <stop offset="100%" stopColor="#4d7cfe" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="name" />
                                        <YAxis type="number" tickLine={false} tickFormatter={(tick) => ((tick / 1000) + 'k')} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip formatter={NumberMask} />
                                        <Area type="monotone" dataKey="income" stroke="#4d7cfe" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        }

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-keyboard-o"></i>
                                        Title:
                                    </div>
                                    <div className="filter--dd xxl">
                                        <input type="text" id="filterByTitle" onChange={(event) => (event.target.value.length >= 3 || event.target.value.length == 0) ? this.filtersChanged("filtersTitle", event.target.value) : {}}></input>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.filtersStartDate}
                                            onChange={this.handleStartDate}
                                            className="start-date-col"
                                        />
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.filtersEndDate}
                                            onChange={this.handleEndDate}
                                            minDate={this.state.filtersStartDate}
                                            className="start-date-col"
                                        />
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-money"></i>
                                        Client:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersClientId != null ? this.state.clientsList[this.state.filtersClientId].name.substr(0, 10) + '...' : 'Select' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                this.state.clientsList.map((client, index) => {
                                                    return (
                                                    <div key={'client-' + index} onClick={() => this.filtersChanged("filtersClientId", index)}>
                                                        <DropdownItem>{client.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-money"></i>
                                        Client PM:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersClientPMId != null ? this.state.clientPMsList[this.state.filtersClientPMId].name.substr(0, 10) + '...' : 'Select' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                this.state.clientPMsList.map((clientPM, index) => {
                                                    return (
                                                    <div key={'client-pm-' + index} onClick={() => this.filtersChanged("filtersClientPMId", index)}>
                                                        <DropdownItem>{clientPM.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-money"></i>
                                        IDG PM:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                    { this.state.filtersIdgPMid != null ? this.state.idgPMsList[this.state.filtersIdgPMid].name.substr(0, 10) + '...' : 'Select' }
                                                    <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    this.state.idgPMsList.map((idgPM, index) => {
                                                        return (
                                                            <div key={'idg-pm-' + index} onClick={() => this.filtersChanged("filtersIdgPMid", index)}>
                                                                <DropdownItem>{idgPM.name}</DropdownItem>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-money"></i>
                                        Hogarth PM:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                    { this.state.filtersHogarthPMid != null ? this.state.hogarthPMsList[this.state.filtersHogarthPMid].name.substr(0, 10) + '...' : 'Select' }
                                                    <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    this.state.hogarthPMsList.map((hogarthPM, index) => {
                                                        return (
                                                            <div key={'hogarth-pm-' + index} onClick={() => this.filtersChanged("filtersHogarthPMid", index)}>
                                                                <DropdownItem>{hogarthPM.name}</DropdownItem>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className="filter filter--reset">
                                    <button className="btn--ts btn--ts-grey" onClick={() => this.clearFilters()}>Clear filters</button>
                                </div>
                            </div>
                            {(this.state.reportDetails && this.state.reportDetails.length > 0) &&
                                <div className="export-link">
                                    <CSVLink data={csvData} filename={"income.csv"}>
                                        Export to CSV
                                    </CSVLink>
                                </div>
                            }
                        </div>

                        {this.state.reportDetails && this.state.reportDetails.length > 0 ?
                            <div className="detailed-chart">
                                {this.state.reportDetails.map((po, index) => {
                                    return (
                                        <TableRow
                                            key={'po-details-' + index}
                                            tableCols={
                                                [
                                                    {
                                                        value: po.project_title,
                                                        label: 'Project',
                                                        link: '/project/' + po.project_id,
                                                        mobileFullWidth: true,
                                                        customCssClass: 'row-details row-head-mobile'
                                                    },
                                                    {
                                                        value: po.date,
                                                        dateFormat: 'DD.MM.YYYY',
                                                        label: 'Date'
                                                    },
                                                    {
                                                        value: po.po_number,
                                                        label: 'PO number',
                                                        hiddenMobile: true
                                                    },
                                                    {
                                                        value: po.client_unit_name,
                                                        label: 'Client unit',
                                                        customCssClass: 'row-details',
                                                        hiddenMobile: true
                                                    },
                                                    {
                                                        value: po.client_name,
                                                        label: 'Client',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: po.client_pm_name,
                                                        label: 'Client\'s PM',
                                                        customCssClass: 'row-details',
                                                        hiddenMobile: true
                                                    },
                                                    {
                                                        value: '€'+po.value,
                                                        label: 'Value'
                                                    },
                                                    {
                                                        value: '#'+po.invoice_id,
                                                        label: 'Invoice',
                                                        link: 'http://facturi.infodesign.ro/index.php?page=factura_pdf&id=' + po.invoice_id + '&tip=0&nr_chitanta=&plaja_chitanta=&hash=' + po.hash_factura,
                                                        targetBlank: true
                                                    },
                                                    {
                                                        value: '#'+po.invoice_date,
                                                        label: 'Invoice date',
                                                        dateFormat: 'DD.MM.YYYY',
                                                        hiddenMobile: true
                                                    }
                                                ]
                                            }
                                        />
                                    )
                                })}
                            </div>
                        :
                            <NoResults message="Sorry, we couldn't find any results matching your search."></NoResults>
                        }


                    </div>
                </div>
            </div>
        );
    }
}
Income.contextType = AppContext;
export default withCookies(Income);
