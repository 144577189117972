import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddProjectCategory from "../../components/addProjectCategory/AddProjectCategory";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class ProjectCategories extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            projectCategoriesList: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddProjectCategory: false,
            editCategoryId: '',
            editCategoryTitle: ''
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getCategories();
    }

    openModal = (clearData) => {
        if (clearData) {
            this.setState({
                editCategoryId: '',
                editCategoryTitle: ''
            })
        }
        this.setState({
            showAddProjectCategory: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddProjectCategory: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getCategories();
            }
        );
    }

    searchCategoriesByTitle = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getCategories();
        });

    }

    getCategories = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'project-categories/', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            res = res.data.data;
            this.setState({
                projectCategoriesList: res.paginatedProjectCategories,
                totalResults: res.totalResults,
                loading: false
            });
        })
        .catch(err => {
            if (typeof err.response !== 'undefined' && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editCategory = (catId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'project-category/' + catId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editCategoryId: res.data.data.id,
                editCategoryTitle: res.data.data.title,
                showAddProjectCategory: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveCategory = (catData) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        //if we have a cat id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (catData.catId) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'project-categories' + (catData.catId ? ('/' + catData.catId) : ''),
            data: {
                title: catData.catTitle
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getCategories();
            this.setState({
                loading: false,
                showAddProjectCategory: false
            })
            toast.success("Category has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddProjectCategory: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    deleteCategory = async (catId) => {
        const { cookies } = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'project-categories/' + catId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                let msg = "Project Category has been deleted.";
                this.getCategories();

                if (res.data.status == 401 && res.data.message) {
                    msg = res.data.message;
                    toast.error(msg);
                }else {
                    toast.success(msg);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                console.error (err);
                toast.error("An error occured, please try again later.");
            })
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                {this.state.loading && <Preloader />}
                <Sidebar />
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Categories"
                        headerOptions={[{
                            name: 'New Category',
                            action: 'openModal',
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="CategoriesPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchCategoriesByTitle} placeholder='Search project Categories by title' />
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => { this.openModal(true) }} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Category
                                <AddProjectCategory
                                    catId={this.state.editCategoryId}
                                    catTitle={this.state.editCategoryTitle}
                                    saveCategory={this.saveCategory}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddProjectCategory}
                                />
                            </button>

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.projectCategoriesList.map((item, index) => {
                                    return (
                                        <TableRow
                                            key={'row-' + index}
                                            tableCols={
                                                [
                                                    {
                                                        value: item.title,
                                                        label: 'Category',
                                                        mobileFullWidth: true,
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit this category',
                                                                action: {
                                                                    name: 'editCategory',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove this category',
                                                                action: {
                                                                    name: 'deleteCategory',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editCategory={this.editCategory}
                                            deleteCategory={this.deleteCategory}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)} />
                    </div>
                </div>
            </div>
        );
    }
}
ProjectCategories.contextType = AppContext;
export default withCookies(ProjectCategories);
