import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import userStore from "../../stores/user";

class AddWildcard extends Component {

    constructor (props){
        super(props);

        this.state = {
            selectedUser: '',
            selectedUserId: -1,
            devsList: [],
            usersSuggestions: [],
        }
    }

    componentDidMount() {
        this.setState({
            devsList: userStore.getDEVs()
        })
    }

    onChangeDeveloper = (event, { newValue }) => {
        this.setState({
            selectedUser: newValue
        });
    };

    getUsersSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.devsList.filter(user =>
            user.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !user.disabled
        );
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onUsersSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            usersSuggestions: this.getUsersSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onUsersSuggestionsClearRequested = () => {
        this.setState({
            usersSuggestions: []
        });
    };

    renderUsersSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    onUsersSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedUserId: suggestion.id
        })
    }

    getUsersSuggestionValue = suggestion => suggestion.name;

    saveWildcard(values, selectedUserId) {
        const { cookies } = this.props;
        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + 'wildcards',
            data: {
                user_id: selectedUserId
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.props.closeWildcardModal(true);
            toast.success("Wildcard has been saved.");
        })
    }

    render() {
        const userInputProps = {
            placeholder: 'Developer\'s name',
            value: this.state.selectedUser,
            onChange: this.onChangeDeveloper
        };

        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        validate={values => {
                            let errors = {};
                            if (this.state.selectedUserId === -1) {
                                errors.userName = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.saveWildcard(values, this.state.selectedUserId);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>Add new wildcard</ModalHeader>
                                <ModalBody className="new--idg-unit">
                                    <FormGroup className={errors.userName ? 'error-container': ''}>
                                        <label htmlFor="te--userId" className="text-left--ts">Developer </label>
                                        <Autosuggest
                                            suggestions={this.state.usersSuggestions}
                                            onSuggestionsFetchRequested={this.onUsersSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onUsersSuggestionsClearRequested}
                                            getSuggestionValue={this.getUsersSuggestionValue}
                                            renderSuggestion={this.renderUsersSuggestion}
                                            onSuggestionSelected={this.onUsersSuggestionSelected}
                                            inputProps={userInputProps}
                                        />
                                        {errors.userName &&
                                            <div className="form-error">{errors.userName}</div>
                                        }
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE WILDCARD'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddWildcard);
