import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import './NoResults.scss';
import noResultsImage from '../../assets/img/no-data.png';

class NoResults extends Component {

    render() {

        return (
            <div className="no-results">
                <img src={noResultsImage} alt="no results" title="" />
                <p className="no-results--message">
                    {this.props.message}
                </p>
            </div>
        );
    }
}

export default withCookies(NoResults);