import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label } from 'reactstrap';
import './AddExpenses.scss';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import AppContext from "../../context/index";
import userStore from "../../stores/user";
import confirm from "reactstrap-confirm";

class AddExpenses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documentName: (this.props.editExpItem.document) ? this.props.editExpItem.document.replace(/^.*[\\\/]/, '') : '',
            document: new FormData(),
            renderForm: false,
            loading: false,
            expensesCategories: [],
            authorsList: [],
            authorsSuggestions: [],
            selectedAuthor: (this.props.editExpItem.author_name) ? this.props.editExpItem.author_name : '',
            selectedAuthorId: (this.props.editExpItem.author_id) ? this.props.editExpItem.author_id : -1,
            expEditNumber: '',
            expEditDate: (this.props.editExpItem.init_date) ? new Date(this.props.editExpItem.init_date) : new Date(),
            expEditValue: '',
            expErrorClientUnitId: '',
            expErrorAuthorId: ''

        };

        this.handleDate = this.handleDate.bind(this);
    }

    componentDidMount = () => {
        this.getExpensesCategories();

        this.setState({
            authorsList: userStore.getIdPMs()
        });

        if(this.props.expId && this.props.expId !== -1) {
            //get details, it's an update
            this.getPODetails(this.props.expId);
        }
        else {
            this.setState({
                renderForm: true
            })
        }
    }

    /* Client PMs */
    onChangeAuthor = (event, { newValue }) => {
        this.setState({
            selectedAuthor: newValue
        });
    };

    getAuthorsSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.authorsList.filter(cu =>
            cu.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !cu.disabled
        );
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onAuthorsSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            authorsSuggestions: this.getAuthorsSuggestions(value)
        });
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onAuthorsSuggestionsClearRequested = () => {
        this.setState({
            authorsSuggestions: []
        })
    };
    renderAuthorsSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onAuthorsSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedAuthorId: suggestion.id,
            expErrorAuthorId: false
        });
    }

    getAuthorsSuggestionValue = suggestion => suggestion.name;

    handleDate = (date) => {
        this.setState({
            expEditDate: date
        });
    }

    handleDocumentChange = e => {
        this.setState({
            documentName: e.target.files[0].name,
            document: e.target.files[0]
        })
    }

    clearInputFile = async (e) => {
        const { cookies } = this.props;

        this.setState({
            documentName: '',
            document: '',
        });

        if (this.props.editExpItem.document) {

            let result = await confirm({
                title: 'Are you sure?',
                message: "This action is irreversible",
                confirmText: "DELETE",
                confirmColor: "primary",
                cancelColor: "text-danger"
            });

            if (result) {

                this.setState({
                    loading: true
                });

                axios.delete(process.env.REACT_APP_API_URL + 'external-expense-file/' + this.props.editExpItem.id, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.get('authToken')
                    }
                })
                  .then(res => {
                      this.setState({
                          loading: false
                      });
                      toast.success("File has been removed.");
                  })
                  .catch(err => {
                      console.error(err);
                      this.setState({
                          loading: false
                      });
                  })
            }
        }
    }

    saveExpense = (values, initDate, document) => {

        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        let formData = new FormData();
        document = typeof document.name !== "undefined" ? document: "";
        formData.append('project_id', this.props.projectId);
        formData.append('value', values.expValue);
        formData.append('category_id', values.expCatId);
        formData.append('init_date', initDate.getFullYear() + '-' + ("0" + parseInt(parseInt(initDate.getMonth()) + 1)).slice(-2) + '-' + ("0" + initDate.getDate()).slice(-2));
        formData.append('author_id', this.state.selectedAuthorId);
        formData.append('code', values.expCode);
        formData.append('description', values.expDesc);
        formData.append('currency', values.expCurrency);
        formData.append("document", document);

        axios.post(process.env.REACT_APP_API_URL + 'external-expenses' + ((values.expId && values.expId !== -1) ? ('/' + values.expId) : ''),
             formData,
             {
             headers: {
                 'Authorization': 'Bearer ' + cookies.get('authToken'),
                 'Content-Type': 'multipart/form-data'
             }
        })
        .then(res => {
            this.setState({
                loading: false
            });
            this.props.closeEditModal(true);

            if (res.data.status === 'error') {
                toast.error('An error occured, please try again later.');
            }
            else {
            toast.success("External Expense has been saved.");
            }
        })
        .catch(err => {
            if(err && err.response && err.response.status && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
                console.log(err);
            }
            this.setState({
                loading: false
            })
            toast.error("An error occured, please try again later.");
        })
    }

    getExpensesCategories = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'list-all-categories/', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
          .then(res => {
              this.setState({
                  expensesCategories: res.data.data,
                  loading: false
              });
          })
          .catch(err => {
              if(err.response.status === 401){
                  //redirect to login
                  this.context.redirectToLogin();
              } else {
                  this.setState({
                      loading: false
                  });
              }
          })
    }

    render() {

        const authorInputProps = {
            placeholder: 'Author',
            value: this.state.selectedAuthor,
            onChange: this.onChangeAuthor
        };

        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} wrapClassName="component--modal-new-exp">
                {this.state.renderForm &&

                    <Formik
                            initialValues = {{
                                expId: (this.props.editExpItem.id) ? this.props.editExpItem.id :  -1,
                                prodId: (this.props.editExpItem.product_id) ? this.props.editExpItem.product_id : '',
                                expCatId: (this.props.editExpItem.category_id) ? this.props.editExpItem.category_id : '',
                                expValue: (this.props.editExpItem.value) ? this.props.editExpItem.value : '',
                                expCode: (this.props.editExpItem.code) ? this.props.editExpItem.code : '',
                                expDesc: (this.props.editExpItem.description) ? this.props.editExpItem.description : '',
                                expCurrency: (this.props.editExpItem.currency) ? this.props.editExpItem.currency : '',
                                document: ''
                            }}
                            validate={values => {
                                let errors = {};
                                if(!values.expCatId) {
                                    errors.expCatId = 'Required';
                                }
                                if (!this.state.expEditDate) {
                                    errors.initDate = 'Required';
                                }
                                if (!values.expValue) {
                                    errors.expValue = 'Required';
                                }
                                if (!values.expCurrency) {
                                    errors.expCurrency = 'Required';
                                }
                                if(!this.state.selectedAuthorId || this.state.selectedAuthorId === -1) {
                                    this.setState({
                                        expErrorAuthorId: true
                                    })
                                    errors.expAuthorId = 'Required';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.saveExpense(values, new Date(this.state.expEditDate), this.state.document);
                                setSubmitting(false);
                            }}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <Form>
                                    <input type="hidden" name="prodId" value={values.prodId}/>
                                    <input type="hidden" name="expId" value={values.expId}/>
                                    <ModalHeader toggle={this.toggle}>Add new external expense</ModalHeader>
                                    <ModalBody className="new--exp">
                                        <div className="row">
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="exp--catId" className="text-left--ts">Category</label>
                                                    <select
                                                        name="expCatId"
                                                        id="exp--catId"
                                                        value={values.expCatId}
                                                        onChange={handleChange}
                                                        className={errors.expCatId ? 'form-control error' : 'form-control'}
                                                    >
                                                        <option>Select</option>
                                                        {
                                                            this.state.expensesCategories.map(cats => {
                                                                return (
                                                                    <option key={'cats-' + cats.id} value={cats.id}>{cats.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <ErrorMessage name="expCatId" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="exp--code" className="text-left--ts">Code</label>
                                                    <input
                                                        type="text"
                                                        name="expCode"
                                                        id="exp--code"
                                                        value={values.expCode}
                                                        onChange={handleChange}
                                                        className={errors.expCode ? 'form-control error' : 'form-control'}
                                                    />
                                                    <ErrorMessage name="expCode" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 date-container">
                                                <FormGroup>
                                                    <Label className="text-left--ts"><span>Date</span></Label>
                                                    <DatePicker
                                                        selected={this.state.expEditDate}
                                                        onChange={this.handleDate}
                                                        className="init-date-col"
                                                    />
                                                    <ErrorMessage name="initDate" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="po--value" className="text-left--ts">Value </label>
                                                    <input
                                                        type="text"
                                                        name="expValue"
                                                        id="exp--value"
                                                        value={values.expValue}
                                                        onChange={handleChange}
                                                        className={errors.expValue ? 'form-control error' : 'form-control'}
                                                    />
                                                </FormGroup>
                                                <ErrorMessage name="expValue" className="form-error" component="div" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <FormGroup className={this.state.expErrorAuthorId ? 'error-container': ''}>
                                                    <label htmlFor="exp--authorId" className="text-left--ts">Author</label>
                                                    <Autosuggest
                                                      suggestions={this.state.authorsSuggestions}
                                                      onSuggestionsFetchRequested={this.onAuthorsSuggestionsFetchRequested}
                                                      onSuggestionsClearRequested={this.onAuthorsSuggestionsClearRequested}
                                                      getSuggestionValue={this.getAuthorsSuggestionValue}
                                                      renderSuggestion={this.renderAuthorsSuggestion}
                                                      onSuggestionSelected={this.onAuthorsSuggestionSelected}
                                                      inputProps={authorInputProps}
                                                    />
                                                    {this.state.expErrorAuthorId &&
                                                    <div className="form-error">Required</div>
                                                    }
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="exp--currency" className="text-left--ts">Currency</label>
                                                    <select
                                                      name="expCurrency"
                                                      id="exp--currency"
                                                      value={values.expCurrency}
                                                      onChange={handleChange}
                                                      className={errors.expCurrency ? 'form-control error' : 'form-control'}
                                                    >
                                                        <option>Select</option>
                                                        <option value="USD">USD</option>
                                                        <option value="EURO">EURO</option>
                                                    </select>
                                                    <ErrorMessage name="expCurrency" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <FormGroup>
                                                    <label htmlFor="exp--desc" className="text-left--ts">Description</label>
                                                    <input
                                                      type="text"
                                                      name="expDesc"
                                                      id="exp--desc"
                                                      value={values.expDesc}
                                                      onChange={handleChange}
                                                      className='form-control'
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <FormGroup className="document-input--container">
                                                    <label htmlFor="expense--document" className="text-left--ts">Document</label>
                                                    <div className="file-input-placeholder">
                                                        <i className="la la-cloud-upload"></i> {this.state.documentName ? this.state.documentName : 'UPLOAD DOCUMENT' }
                                                        <input
                                                          type="file"
                                                          name="document"
                                                          id="expense--document"
                                                          value={values.document}
                                                          onChange={this.handleDocumentChange}
                                                          className={errors.document ? 'form-control-file error' : 'form-control-file'}
                                                        />
                                                        {this.state.documentName &&
                                                        <i className="btn btn--ts-grey btn-del" onClick={this.clearInputFile}>X</i>
                                                        }
                                                    </div>
                                                    <ErrorMessage name="document" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE EXPENSE'}</button>
                                        <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeEditModal}>CANCEL</button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
AddExpenses.contextType = AppContext;
export default withCookies(AddExpenses);
