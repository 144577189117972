import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import MissingDays from "../../components/missingDays/MissingDays";
import DatePicker from "react-datepicker";
import AppContext from "../../context/index";

class MissingTimesheet extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        let today = new Date();
        let yesterday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

        this.state = {
            reportData: [],
            reportDetails: [],
            totalMissingDays: 0,
            startDate: yesterday,
            endDate: today,
            showDetailsPopup: false
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();
    }

    handleStartDate = (date) => {
        let endDate = this.state.endDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getReportData());
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getReportData());
    }

    getReportData = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'reports-missing-timesheet', {
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                reportData: res.data.data
            });

            let totalDays = 0;
            for(let i in res.data.data) {
                totalDays += parseInt(res.data.data[i].count_days);
            }
            this.setState({totalMissingDays: totalDays});
        })
        .catch(err => {
            if(err && err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    viewMissingDays = (userId) => {
        this.state.reportData.forEach((ts) => {
            if(ts.user_id == userId) {
                console.log(ts);
                this.setState({
                    reportDetails: ts,
                    showDetailsPopup: true
                });
            }
        })
    }

    closeModal = () => {
        this.setState({
            showDetailsPopup: false
        });
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="73"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Reports::Missing Timesheet" 
                    />
                    <div className="ReportsPage">
                        <div className="total-missing">
                            <span className="label">Total missing days: </span>{this.state.totalMissingDays}
                        </div>

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDate}
                                            className="start-date-col"
                                        />
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={this.handleEndDate}
                                            minDate={this.state.startDate}
                                            className="start-date-col"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {this.state.showDetailsPopup &&
                            <MissingDays closeModal={this.closeModal} showDetailsPopup={this.state.showDetailsPopup} days={this.state.reportDetails}></MissingDays>
                        }

                        {this.state.reportData &&
                            <div className="detailed-chart no-padding-top">
                                {this.state.reportData.map((te, index) => {
                                    // console.log(po);
                                    return (
                                        <TableRow
                                            key={'ts-missing-' + index}
                                            tableCols={
                                                [
                                                    {
                                                        value: te.user_name,
                                                        label: 'Name',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: te.count_days,
                                                        label: 'Missing days'
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Details',
                                                                action: {
                                                                    name: 'viewMissingDays',
                                                                    params: te.user_id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            viewMissingDays={this.viewMissingDays}
                                        /> 
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
MissingTimesheet.contextType = AppContext;
export default withCookies(MissingTimesheet);