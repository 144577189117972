import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddBusinessUnits from "../../components/addBusinessUnits/AddBusinessUnits";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";
import {handleErrorResponse} from "../../utils/utils";

class BusinessUnits extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            clientBusinessList: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddBusinessUnits: false,
            editBusinessUnitsId: '',
            editBusinessUnitsName: '',
            editBusinessUnitsType: '',
            editBusinessUnitShowOnProject: false
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getBusinessUnits();
    }

    openModal = (clearData) => {
        if (clearData) {
            this.setState({
                editBusinessUnitsId: '',
                editBusinessUnitsName: '',
                editBusinessUnitsType: '',
                editBusinessUnitShowOnProject: '',
            })
        }
        this.setState({
            showAddBusinessUnits: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddBusinessUnits: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getBusinessUnits();
            }
        );
    }

    searchBusinessUnitsByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getBusinessUnits();
        });
        
    }

    getBusinessUnits = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'business-units', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                clientBusinessList: res.data.data,
                totalResults: res.data.total,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }


    editBusinessUnits = (businessUnitsId) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'business-unit/' + businessUnitsId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editBusinessUnitsId: res.data.data.id,
                editBusinessUnitsName: res.data.data.name,
                editBusinessUnitsType: res.data.data.type,
                editBusinessUnitShowOnProject: res.data.data.show_on_project,
                showAddBusinessUnits: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveBusinessUnits = (businessUnitsData) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        //if we have a client id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (businessUnitsData.businessUnitsId) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'business-units' + (businessUnitsData.businessUnitsId ? ('/' + businessUnitsData.businessUnitsId) : ''),
            data: {
                name: businessUnitsData.businessUnitsName,
                type: businessUnitsData.businessUnitsType,
                show_on_project: businessUnitsData.businessUnitShowOnProject
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getBusinessUnits();
            this.setState({
                loading: false,
                showAddBusinessUnits: false
            })
            toast.success("Business unit has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddBusinessUnits: false
                })
            }

            handleErrorResponse(err.response);
        })
    }

    deleteBusinessUnits = async (businessUnitsId) => {
        const {cookies} = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'business-units/' + businessUnitsId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getBusinessUnits();
                toast.success("Business unit has been deleted.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }

                handleErrorResponse(err.response);
            })
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Business units"
                        headerOptions={[{
                            name: 'New Business unit',
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="BusinessUnitsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchBusinessUnitsByName} placeholder='Search business unit by name'/>
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => {this.openModal(true)}} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Unit
                                <AddBusinessUnits
                                    businessUnitsId={this.state.editBusinessUnitsId}
                                    businessUnitsName={this.state.editBusinessUnitsName}
                                    businessUnitsType={this.state.editBusinessUnitsType}
                                    businessUnitShowOnProject={this.state.editBusinessUnitShowOnProject}
                                    saveBusinessUnits={this.saveBusinessUnits}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddBusinessUnits}
                                />
                            </button>    

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.clientBusinessList.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Name',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.type,
                                                        label: 'Unit type',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.show_on_project ? "yes" : "no",
                                                        label: 'Show on project',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit unit',
                                                                action: {
                                                                    name: 'editBusinessUnits',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove unit',
                                                                action: {
                                                                    name: 'deleteBusinessUnits',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editBusinessUnits={this.editBusinessUnits}
                                            deleteBusinessUnits={this.deleteBusinessUnits}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)}/>
                    </div>
                </div>
            </div>
        );
    }
}
BusinessUnits.contextType = AppContext;
export default withCookies(BusinessUnits);
