import axios from "axios";
import { ls } from "../services/ls";

/*** STORE ALL CURRENT USER DATA ***/
export let userStore = {
  notifications : [],
  users: [],
  clients: [],
  brands: [],
  me: {
    id: -1,
    has_approval: 999,
    role: ''
  },
};

userStore.init = (section) => {

  /** Check memory or if it is an update */
  if (userStore.users.length < 1 || typeof section !== "undefined") {

    /** Check for localstorage first **/
    if (typeof ls.get('me') === "undefined" || ls.get('me') == null || typeof section !== "undefined") {

      axios.get(process.env.REACT_APP_API_URL + 'update-user-store', {
        params: { section: section },
        headers: {
          'Authorization': 'Bearer ' + ls.get('authToken'),
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          res = res.data.data;

          if (typeof res.brands !== "undefined") {
            ls.set('brandList', res.brands);
            userStore.brands = res.brands;
          }
          if (typeof res.clients !== "undefined") {
            ls.set('clientsList', res.clients);
            userStore.clients = res.clients;
          }
          if (typeof res.users !== "undefined") {
            ls.set('usersList', res.users);
            userStore.users = res.users;
          }
          if (typeof res.me !== "undefined") {
            ls.set('me', res.me);
            userStore.me = res.me;
          }

        })
        .catch(err => {
          console.error(err);
        });

    } else {
      /** Load data from Localstorage */
      if (ls.get('brandsList') != null) {
        userStore.brands = ls.get('brandsList');
      }
      if (ls.get('clientsList') != null) {
        userStore.clients = ls.get('clientsList');
      }
      if (ls.get('usersList') != null) {
        userStore.users = ls.get('usersList');
      }
      if (ls.get('me') != null) {
        userStore.me = ls.get('me');
      }
    }
  }
};

userStore.getNotifications = () => {
  axios.get(process.env.REACT_APP_API_URL + 'check-user-data', {
    headers: {
      'Authorization': 'Bearer ' + ls.get('authToken')
    }
  })
    .then(res => {
      userStore.me.has_approval = res.data.has_approval ? res.data.has_approval : 999;
      userStore.triggerCheckApproval(userStore.me.has_approval);
      userStore.notifications = res.data.user_notifications;
    })
    .catch(err => {
      console.error(err);
    });
};
userStore.getUserById = (idx) => {
  return userStore.users.filter(({id}) => id == idx)[0];
};

userStore.getOwners = () => {
  return userStore.users.filter(item =>
    ([2, 4].includes(item.role) && item.disabled !== 1)
  );
};

userStore.getIdPMs = () => {
  return userStore.users.filter(item =>
    ([1, 2].includes(item.role) && item.disabled !== 1)
  );
};

userStore.getDEVs= () => {
  return userStore.users.filter(item =>
    (item.role === 4 && item.disabled !== 1)
  );
};

userStore.getHogarthPMs= () => {
  return userStore.users.filter(item =>
      (item.role === 3 && item.disabled !== 1)
  );
};

userStore.getClientPMs= () => {
  return userStore.users.filter(item =>
    (item.role === 3 && item.disabled !== 1)
  );
};

export default userStore;
