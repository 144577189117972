import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import './PurchaseOrders.scss';
import TableRow from "../../components/tableRow/TableRow";
import {
    Modal, ModalBody, ModalFooter, ModalHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Preloader from "../../components/preloader/Preloader";
import AddPO from "../../components/addPO/AddPO";
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";
import { userStore } from "../../stores/user";

class PurchaseOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ceItemTypes: [],
            purchaseOrders: this.props.purchaseOrders,
            activityTypes: [],
            editPOId: -1,
            showAddPO: false,
            showInvoiceGenerator: false,
            showInvoiceCEId: -1,
            showInvoicePOId: -1,
            ceLines: [],
            totalInvoice: 0
        };
    }

    closeGenerateModal = () => {
        this.setState({
            showInvoiceGenerator: false,
            showInvoiceCEId: -1,
            showInvoicePOId: -1,
            ceLines: [],
            totalInvoice: 0
        })
    }

    getCEItemTypes = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimate-item-types', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                ceItemTypes: res.data.data,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getCEActivityTypes = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'activity-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                activityTypes: res.data.data.paginatedActivityTypes,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    componentDidMount() {
        this.getCEActivityTypes();
        this.getCEItemTypes();
    }

    openModal = () => {
        this.setState({
            showAddPO: true
        });
    }

    closeModal = refreshList => {
        this.setState({
            showAddPO: false,
            editPOId: -1
        });
        if(refreshList) {
            this.getPurchaseOrders();
        }
    }

    editCE = (poId) => {
        this.setState({
            editPOId: poId,
            showAddPO: true
        });
    }

    getPurchaseOrders = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'purchase-orders/' + this.props.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                purchaseOrders: res.data.data,
                loading: false
            });
            this.syncPO();
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    prepareInvoice = (params) => {
        const { cookies } = this.props;

        //params[0] - poId
        //params[1] - ceId

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimate/' + params[1], {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                ceLines: res.data.data.CostEstimateLines,
                renderForm: true,
                loading: false,
                showInvoiceGenerator: true,
                showInvoiceCEId: params[1],
                showInvoicePOId: params[0]
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    generateInvoice = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + 'generate-invoice/' + this.state.showInvoicePOId,
            data: {
                ceLines: this.state.ceLines
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            if(res.data.status == 'error') {
                toast.error(res.data.data);
            }
            else {
                toast.success("The invoice has been generated.");
                this.getPurchaseOrders();
            }
            this.setState({
                loading: false
            });
            this.closeGenerateModal();
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
            this.closeGenerateModal();
        })


    }

    downloadInvoice = (invoiceParams) => {
        let invoiceURL = 'http://facturi.infodesign.ro/index.php?page=factura_pdf&id=' + invoiceParams[0] + '&tip=0&nr_chitanta=&plaja_chitanta=&hash=' + invoiceParams[1];
        window.open(invoiceURL, '_blank');
    }

    downloadStorno = (stornoParams) => {
        let stornoURL = 'http://facturi.infodesign.ro/index.php?page=factura_pdf&id=' + stornoParams[0] + '&tip=0&nr_chitanta=&plaja_chitanta=&hash=' + stornoParams[1];
        window.open(stornoURL, '_blank');
    }

    stornoInvoice = async (poId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "Are you sure you want to generate storno invoice?",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.get(process.env.REACT_APP_API_URL + 'storno-invoice/' + poId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                if(res.data.status == 'error') {
                    toast.error(res.data.data);
                }
                else {
                    toast.success("The storno invoice has been generated.");
                    this.getPurchaseOrders();
                }
                this.setState({
                    loading: false
                });
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
        }
    }

    resetInvoice = async (poId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.get(process.env.REACT_APP_API_URL + 'reset-invoice-data/' + poId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getPurchaseOrders();
                toast.success("Invoice data has been removed.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
        }
    }

    deletePO = async (poId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'purchase-orders/' + poId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getPurchaseOrders();
                toast.success("Purchase order has been removed.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
        }
    }

    syncPO = () => {
        this.props.syncPO(this.state.purchaseOrders)
    }

    changeLineQuantity = (event, lineIndex) => {

        let auxLines = this.state.ceLines;
        auxLines[lineIndex].quantity = event.target.value;
        this.setState({
            ceLines: auxLines
        })
    }

    render() {

        const units = [
            '',
            'hour',
            'piece',
            'percent'
        ];

        const getTypeName = (typeId) => {
            let itemName = '';
            this.state.ceItemTypes.forEach(item => {
                if (item.id == typeId) {
                    itemName = item.name
                }
            })
            return itemName
        }

        const getActivityTypeName = (typeId) => {
            let itemName = '';
            this.state.activityTypes.forEach(item => {
                if (item.id == typeId) {
                    itemName = item.name
                }
            })
            return itemName
        }

        const calculateDiscount = (discount) => {
            let total = 0;
            this.state.ceLines.forEach(line => {
                total += (line.item_type_id == 1 || line.item_type_id == 2) ? line.quantity * line.unit_cost : 0
            })
            return (-1 * (discount / 100) * total).toFixed(2);
        }

        const calculateTotal = () => {
            let total = 0;
            let discount = 0;

            this.state.ceLines.forEach(line => {
                total += (line.item_type_id == 1 || line.item_type_id == 2) ? line.quantity * line.unit_cost : 0

                if(line.item_type_id == 3) {
                    discount = line.discount
                }
            })

            if(discount != 0) {
                total = total - ((discount / 100) * total).toFixed(2);
            }
            return total;
        }

        return (
            <div className="project-subsection">
                { this.state.loading && <Preloader/> }
                <div className="row project-subsection--head">
                    <div className="col-10"><h1>Purchase Orders</h1></div>
                    <div className="col-2 ta-right">
                    {this.props.costEstimates.length != 0 ?
                            <UncontrolledDropdown>
                                <DropdownToggle nav>
                                    <i className="la la-plus-circle"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem key={'po-1'} onClick={(e) => {this.openModal()}}>New purchase order</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        : ''}
                    </div>
                </div>

                {this.state.showAddPO &&
                    <AddPO
                        poId={this.state.editPOId}
                        projectId={this.props.projectId}
                        closeEditModal={this.closeModal}
                        showPopup={this.state.showAddPO}
                    />
                }

                {this.state.showInvoiceGenerator &&
                    <Modal isOpen={this.state.showInvoiceGenerator} wrapClassName="component--modal-generate-invoice">
                        <ModalHeader>Generate invoice</ModalHeader>
                        <ModalBody>
                            <div className="ce-lines">
                                {this.state.ceLines.map((ceLine, index) => {
                                    return(
                                        ceLine.item_type_id == 1 || ceLine.item_type_id == 2 ? //we don't show discount
                                        <div className={"TableRow row justify-content-between"} key={'ce-line-' + index}>
                                            <div className="col">
                                                <p className="col--value">{ceLine.details}</p>
                                                <p className="col--label">Details</p>
                                            </div>
                                            <div className="col">
                                                <p className="col--value">{getTypeName(ceLine.item_type_id)}</p>
                                                <p className="col--label">Type</p>
                                            </div>
                                            <div className="col">
                                                <p className="col--value">{getActivityTypeName(ceLine.activity_type_id)}</p>
                                                <p className="col--label">Activity type</p>
                                            </div>
                                            <div className="col">
                                                <p className="col--value">{units[ceLine.item_type_id]}</p>
                                                <p className="col--label">Unit</p>
                                            </div>
                                            <div className="col">
                                                <p className="col--value"><input value={ceLine.quantity} onChange={(e) => this.changeLineQuantity(e, index)}/></p>
                                                <p className="col--label">Quantity</p>
                                            </div>
                                            <div className="col">
                                                <p className="col--value">{ceLine.unit_cost}</p>
                                                <p className="col--label">Unit cost</p>
                                            </div>
                                            <div className="col">
                                                <p className="col--value">{ceLine.item_type_id == 3 ? calculateDiscount(ceLine.discount) : ceLine.quantity * ceLine.unit_cost}</p>
                                                <p className="col--label">Value</p>
                                            </div>
                                        </div>
                                        :''
                                    )
                                })}
                            </div>

                            <div className="total--invoice">TOTAL: {calculateTotal()}</div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="btn--ts-grey btn--ts" onClick={this.closeGenerateModal}>CANCEL</button>
                            <button type="submit" className="btn--ts-primary btn--ts" onClick={this.generateInvoice}>GENERATE INVOICE</button>
                        </ModalFooter>
                    </Modal>
                }

                <div className="project-subsection--body">
                    {this.state.purchaseOrders.map((po, index) => {
                        return(
                            <TableRow
                                key={'porow-' + index}
                                tableCols={
                                    [
                                        {
                                            value: po.id,
                                            label: 'ID'
                                        },
                                        {
                                            value: po.cost_estimate_id,
                                            label: 'Cost Estimate'
                                        },
                                        {
                                            value: po.po_number,
                                            label: 'PO number'
                                        },
                                        {
                                            value: po.date,
                                            label: 'Date',
                                            dateFormat: 'DD.MM.YYYY'
                                        },
                                        {
                                            value: userStore.getUserById(po.client_pm_id).name,
                                            label: 'Client PM',
                                            customCssClass: 'row-details'
                                        },
                                        {
                                            value: typeof userStore.getUserById(po.infodesign_pm_id) != "undefined" ? userStore.getUserById(po.infodesign_pm_id).name: ('userid:'+po.infodesign_pm_id),
                                            label: 'PM',
                                            customCssClass: 'row-details'
                                        },
                                        {
                                            value: po.value,
                                            label: 'Value'
                                        },
                                        {
                                            value: po.client_unit_name,
                                            label: 'Client unit',
                                            customCssClass: 'row-details'
                                        },
                                        {
                                            value: po.po_status_name,
                                            label: 'Status',
                                            customCssClass: (po.status_id == 2 ? 'danger' : 'success')
                                        },
                                        {
                                            value: po.invoice_id,
                                            label: 'Invoice ID'
                                        },
                                        {
                                            ddOptions:
                                                po.storno_id ?
                                                [
                                                    {
                                                        value: 'Download storno invoice',
                                                        action: {
                                                            name: 'downloadStorno',
                                                            params: [po.storno_id, po.hash_storno]
                                                        }
                                                    },
                                                    {
                                                        value: 'Remove purchase order',
                                                        action: {
                                                            name: 'deletePO',
                                                            params: po.id
                                                        }
                                                    },
                                                    {
                                                        value: 'Generate invoice',
                                                        action: {
                                                            name: 'prepareInvoice',
                                                            params: [po.id, po.cost_estimate_id]
                                                        }
                                                    },
                                                    {
                                                        value: 'Reset invoice data',
                                                        action: {
                                                            name: 'resetInvoice',
                                                            params: [po.id]
                                                        }
                                                    }
                                                ]
                                                : po.invoice_id ?
                                                [
                                                    {
                                                        value: 'Download invoice',
                                                        action: {
                                                            name: 'downloadInvoice',
                                                            params: [po.invoice_id, po.hash_factura]
                                                        }
                                                    },
                                                    {
                                                        value: 'Storno invoice',
                                                        action: {
                                                            name: 'stornoInvoice',
                                                            params: po.id
                                                        }
                                                    },
                                                    {
                                                        value: 'Reset invoice data',
                                                        action: {
                                                            name: 'resetInvoice',
                                                            params: [po.id]
                                                        }
                                                    }
                                                ]
                                                :
                                                [
                                                    {
                                                        value: 'Remove purchase order',
                                                        action: {
                                                            name: 'deletePO',
                                                            params: po.id
                                                        }
                                                    },
                                                    {
                                                        value: 'Generate invoice',
                                                        action: {
                                                            name: 'prepareInvoice',
                                                            params: [po.id, po.cost_estimate_id]
                                                        }
                                                    }
                                                ]
                                        }
                                    ]
                                }
                                deletePO={this.deletePO}
                                downloadInvoice={this.downloadInvoice}
                                stornoInvoice={this.stornoInvoice}
                                prepareInvoice={this.prepareInvoice}
                                resetInvoice={this.resetInvoice}
                            />
                        )
                    })}

                    {this.props.costEstimates && this.props.costEstimates.length == 0 ?
                        <div className="no-data">
                            <p><i className="la la-exclamation-triangle"></i> No cost estimates</p>
                        </div>
                        : ''
                    }

                    {(this.props.costEstimates.length != 0 && this.state.purchaseOrders.length == 0) ?
                        <div className="no-data">
                            <p><i className="la la-exclamation-triangle"></i> No purchase orders</p>
                            <button type="button" className="btn--ts btn--ts-primary" onClick={(e) => {this.openModal()}}>Add your first purchase order for this project</button>
                        </div>
                    : ''}

                </div>
            </div>
        );
    }
}
PurchaseOrders.contextType = AppContext;
export default withCookies(PurchaseOrders);
