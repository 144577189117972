import React, { Component } from 'react';
import './KanbanDesk.scss';
import { withCookies } from 'react-cookie';
import profilePicture from '../../assets/img/profile-pic.png';
import cosminProfile from '../../assets/img/cosmin-doroftei.png';
import KanbanDeskTask from "../../components/kanbanDeskTask/KanbanDeskTask";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";

import {
    Row, Col,
    Dropdown, DropdownMenu, DropdownToggle, DropdownItem
} from 'reactstrap';


class KanbanDesk extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            dropDownToDoOpen: false,
            dropDownProgressOpen: false,
            dropDownDoneOpen: false,
            tasks: {
                "toDo": [
                    {
                        "taskTitle": "task todo 1",
                        "progress": "5/8",
                        "progressBar": "70",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "1"
                    },
                    {
                        "taskTitle": "task todo 2",
                        "progress": "5/8",
                        "progressBar": "100",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "2"
                    },
                    {
                        "taskTitle": "task todo 3",
                        "progress": "5/8",
                        "progressBar": "100",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "3"
                    },
                    {
                        "taskTitle": "task todo 4",
                        "progress": "5/8",
                        "progressBar": "100",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "4"
                    }
                ],
                "inProgress": [
                    {
                        "taskTitle": "task in progress 1",
                        "progress": "5/8",
                        "progressBar": "100",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "1"
                    },
                    {
                        "taskTitle": "task in progress 2",
                        "progress": "5/8",
                        "progressBar": "100",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "2"
                    }
                ],
                "done": [
                    {
                        "taskTitle": "task done 1",
                        "progress": "5/8",
                        "progressBar": "100",
                        "taskComments": "4",
                        "dueDate": "27 Apr",
                        "id": "1"
                    }
                ]
            }
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }




    render() {
        return (
            <div className="page--with-header-sidebar">
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header pageTitle="Tasks"/>
                    <div className="kb-container">
                        <Row>
                            <Col>
                                <Dropdown className="kanbanDesk-dropdown" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                    <DropdownToggle
                                        className="kanbanDesk-project-title"
                                        tag="span"
                                        onClick={this.toggle}
                                        data-toggle="dropdown"
                                        aria-expanded={this.state.dropdownOpen}>

                                        <i className="la la-inbox"></i>PROJECT:
                                        <span className="kanbanDesk-project-name">YOUniverse reshufle 2019</span>
                                        <i className="la la-angle-down"></i>
                                    </DropdownToggle>

                                    <DropdownMenu right className="kanbanDesk-dropdown-items">
                                        <div onClick={this.toggle}>WEBSITE SPOTIFY item</div>
                                        <div onClick={this.toggle}>WEBSITE SPOTIFY item</div>
                                        <div onClick={this.toggle}>WEBSITE SPOTIFY item</div>
                                        <div onClick={this.toggle}>WEBSITE SPOTIFY item</div>
                                    </DropdownMenu>

                                    <div className="kanbanDesk-profilePic">
                                        <img src={profilePicture} className="user-round-avatar" alt="img" title="user's name" />
                                        <img src={cosminProfile} className="user-round-avatar" alt="img" title="user's name" />
                                        <button className="kanbanDesk-btn-add"><i className="la la-plus"></i></button>
                                    </div>
                                </Dropdown>

                            </Col>
                        </Row>

                        <Row>

                            <div className="col-md-4">
                                <div className="mx-auto kb-task-title">

                                    <div className="kb-head">
                                        <span className="task-order">To do</span>
                                        <Dropdown direction="left" className="dropdown-dots" isOpen={this.state.dropDownToDoOpen} toggle={() => { this.setState({ dropDownToDoOpen: !this.state.dropDownToDoOpen }); }}>
                                            <DropdownToggle caret>
                                                <i className="la la-ellipsis-h"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Another Action</DropdownItem>
                                                <DropdownItem>Another Action</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>

                                    {/* Task-uri, proiecte */}

                                    {
                                        this.state.tasks.toDo.map(task => {
                                            return <KanbanDeskTask key={task.taskTitle + '-' + task.id} title={task.taskTitle} progress={task.progress} progressBar={task.progressBar} taskComments={task.taskComments} dueDate={task.dueDate} />
                                        })
                                    }

                                    <div className="kanbanDesk-footer">

                                        <button className="btn-new-task">
                                            <i className="la la-plus"></i>
                                            <span className="kanbanDesk-footer-add">NEW TASK</span>
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mx-auto kb-task-title">
                                    
                                    <div className="kb-head">
                                        <span className="task-order">In progress</span>
                                        <Dropdown direction="left" className="dropdown-dots" isOpen={this.state.dropDownProgressOpen} toggle={() => { this.setState({ dropDownProgressOpen: !this.state.dropDownProgressOpen }); }}>
                                            <DropdownToggle caret>
                                                <i className="la la-ellipsis-h"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Another Action</DropdownItem>
                                                <DropdownItem>Another Action</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    
                                    {
                                        this.state.tasks.inProgress.map(task => {
                                            return <KanbanDeskTask key={task.taskTitle + '-' + task.id} title={task.taskTitle} progress={task.progress} progressBar={task.progressBar} taskComments={task.taskComments} dueDate={task.dueDate} />
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mx-auto kb-task-title">

                                    <div className="kb-head">
                                        <span className="task-order">Done</span>
                                        <Dropdown direction="left" className="dropdown-dots" isOpen={this.state.dropDownDoneOpen} toggle={() => { this.setState({ dropDownDoneOpen: !this.state.dropDownDoneOpen }); }}>
                                            <DropdownToggle className="test-test-test" caret>
                                                <i className="la la-ellipsis-h"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Another Action</DropdownItem>
                                                <DropdownItem>Another Action</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>

                                    {
                                        this.state.tasks.done.map(task => {
                                            return <KanbanDeskTask key={task.taskTitle + '-' + task.id} title={task.taskTitle} progress={task.progress} progressBar={task.progressBar} taskComments={task.taskComments} dueDate={task.dueDate} />
                                        })
                                    }
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(KanbanDesk);