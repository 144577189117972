import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import './ExternalExpenses.scss';
import TableRow from "../../components/tableRow/TableRow";
import {
    Modal, ModalBody, ModalFooter, ModalHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Preloader from "../../components/preloader/Preloader";
import AddExpenses from "../../components/addExpenses/AddExpenses";
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class ExternalExpenses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            editExpId: -1,
            editExpItem: {},
            showAddExp: false,
        };
    }

    componentDidMount() {
        //
    }

    openModal = () => {
        this.setState({
            showAddExp: true
        });
    }

    closeModal = refreshList => {
        this.setState({
            showAddExp: false,
            editExpItem: {}
        });
        if(refreshList) {
            this.getList();
        }
    }

    editExp = (expenseItem) => {
        this.setState({
            editExpItem: expenseItem,
            showAddExp: true
        });
    }

    getList = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'external-expenses/' + this.props.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            res = res.data.data;

            this.syncExternalExpenses(res);

            this.setState({
                loading: false
            });
        })
        .catch(err => {
            if(typeof err.response !== "undefined" && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    syncExternalExpenses = (expenses) => {
        this.props.syncExternalExpenses(expenses)
    }

    deleteExp = async (expId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            });

            axios.delete(process.env.REACT_APP_API_URL + 'external-expenses/' + expId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getList();
                toast.success("External expense has been removed.");
            })
            .catch(err => {
                if(typeof err.response !== 'undefined' && err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
        }
    }

    render() {
        return (
            <div className="project-subsection">
                { this.state.loading && <Preloader/> }
                <div className="row project-subsection--head">
                    <div className="col-10">
                        <h1>External Expenses <span className="small"> { this.props.externalTotalCost ? '• Total: '+ this.props.externalTotalCost : '' }</span></h1>
                    </div>
                    <div className="col-2 ta-right">
                        <UncontrolledDropdown>
                            <DropdownToggle nav>
                                <i className="la la-plus-circle"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem key={'po-1'} onClick={(e) => {this.openModal()}}>New external expense</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>

                {this.state.showAddExp &&
                    <AddExpenses
                        editExpItem={this.state.editExpItem}
                        projectId={this.props.projectId}
                        closeEditModal={this.closeModal}
                        showPopup={this.state.showAddExp}
                    />
                }

                <div className="project-subsection--body">
                    {typeof this.props.externalExpenses !== "undefined" && this.props.externalExpenses.length > 0 &&
                    this.props.externalExpenses.map((item, index) => {
                        return (
                          <TableRow
                            key={'exprow-' + index}
                            tableCols={
                                [
                                    {
                                        value: item.id,
                                        label: 'ID'
                                    },
                                    {
                                        value: item.code,
                                        label: 'Code'
                                    },
                                    {
                                        value: item.init_date,
                                        label: 'Date',
                                        dateFormat: 'DD.MM.YYYY'
                                    },
                                    {
                                        value: item.author_name,
                                        label: 'Author',
                                        customCssClass: 'row-details'
                                    },
                                    {
                                        value: item.value,
                                        label: 'Value'
                                    },
                                    {
                                        value: item.currency,
                                        label: 'Currency'
                                    },
                                    {
                                        value: item.category_title,
                                        label: 'Category',
                                        customCssClass: 'row-details'
                                    },
                                    {
                                        value: item.description,
                                        label: 'Description'
                                    },
                                    {
                                        value: (item.document) ? 'download' : null,
                                        link: process.env.REACT_APP_PUBLIC_PATH + 'storage/' + item.document,
                                        download: true,
                                        label: 'Document'
                                    },
                                    {
                                        ddOptions:
                                          [

                                              {
                                                  value: 'Edit',
                                                  action: {
                                                      name: 'editExp',
                                                      params: item
                                                  }
                                              },
                                              {
                                                  value: 'Remove',
                                                  action: {
                                                      name: 'deleteExp',
                                                      params: item.id
                                                  }
                                              },
                                          ]
                                    }
                                ]
                            }
                            deleteExp={this.deleteExp}
                            editExp={this.editExp}
                          />
                        )
                    })}

                    {typeof this.props.externalExpenses !== "undefined" && this.props.externalExpenses.length == 0 ?
                      <div className="no-data">
                          <p><i className="la la-exclamation-triangle"></i> No external expenses</p>
                      </div>
                      : ''
                    }
                </div>
            </div>
        );
    }
}
ExternalExpenses.contextType = AppContext;
export default withCookies(ExternalExpenses);
