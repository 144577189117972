import React, { Component } from 'react';
import './Header.scss';
import { withCookies } from 'react-cookie';
import profilePictureM from '../../assets/img/profile-pic.png';
import profilePictureF from '../../assets/img/profile-pic-f.png';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, ModalHeader, ModalBody, FormGroup, ModalFooter, Modal
} from 'reactstrap';
import AppContext from "../../context/index";
import axios from "axios";
import { toast } from "react-toastify";
import { userStore } from "../../stores/user";
import UpdateProfile from "../updateProfile/UpdateProfile";
import moment from "moment";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userRole: -1,
            showSetupWorkingPlaces: false,
            userAvatar: '',
            showUpdateProfile: false,
            menuUser:
                [
                    // {
                    //     titleMenuUser: "PROFILE",
                    //     cssIconClass: "la la-user",
                    //     href: '/profile'
                    // },
                    {
                        titleMenuUser: "SIGN OUT",
                        cssIconClass: "la la-sign-out",
                        href: '/logout'
                    },
                ]
        }
    }

    componentDidMount() {
        const { cookies } = this.props;

        //role = 2 for superadmin or idg pm and 1 for devs
        this.setState({
            userRole: cookies.get('userRole'),
            userSex: cookies.get('userSex'),
            userAvatar: cookies.get('userAvatar')
        });

        userStore.getNotifications();
        if((cookies.get('userRole') == 1) && !cookies.get('showedSetupWorkingPlaces')){
           this.isUWPThreeDaysForward();
        }
    }

    readNotification = (notificationId, e ) => {
        e.stopPropagation();
        const {cookies} = this.props;

        let data = JSON.stringify({ notificationId : notificationId });

        this.setState({
            loading: true
        });

        axios.post(process.env.REACT_APP_API_URL + 'read-notification' , data, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken'),
                'Content-Type': 'application/json'
            }
        })
          .then(res => {
              userStore.notifications = userStore.notifications.filter(i => i.id !== notificationId);
          })
          .catch(err => {
              if(err.response.status === 401){
                  //redirect to login
                  this.context.redirectToLogin();
              } else {
                  this.setState({
                      loading: false
                  })
              }
              toast.error("An error occured, please try again later.");
          });

    };

    isUWPThreeDaysForward = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'uwp-is-three-days-forward', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
            .then(res => {
                this.setState({
                    showSetupWorkingPlaces: !res.data.is_setup_forward_three_days
                });
            })
    }

    actionClicked = (functionName, functionParams) => {
        this.props[functionName](functionParams);
    };

    openUpdateProfileModal = () => {
        this.setState({
            showUpdateProfile: true
        });
    }

    closeUpdateProfile = () => {
        this.setState({
            showUpdateProfile: false
        });
    }

    closeShowSetupWorkingPlaces(){
        const { cookies } = this.props;

        this.setState({
            showSetupWorkingPlaces: false
        });

        cookies.set('showedSetupWorkingPlaces', true, {
            expires: moment().add(24, 'hours').toDate()
        });
    }

    render() {
        return (
            <div className="Header">
                <div className="header-container">

                    <div className="left-menu">
                        {this.props.roleNeeded && this.props.headerOptions && this.state.userRole == this.props.roleNeeded &&
                            <span className="dropdown-icon">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        <i className="la la-plus-circle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {this.props.headerOptions.map((action, index) => {
                                            return (
                                                <DropdownItem key={'dd-' + index} onClick={ () => this.actionClicked(action.action, action.params) }>
                                                    <i className={action.iconClass ? action.iconClass : 'la la-suitcase'}></i> {action.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </span>
                        }
                        <span className="page--title d-none d-md-inline-block">{this.props.pageTitle}</span>
                    </div>

                    <div className="right-side">
                        {/* <div className="search-bar d-none d-md-inline-block">
                            <form className="search-form">
                                <div className="dropdown-icon dropdown-search">
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            <span>
                                                <input type="text" placeholder="Search for task and etc"/>
                                                <i className="la la-search"></i>
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem href="/xxx">
                                                <i className="la la-suitcase"></i> Search for projects
                                            </DropdownItem>
                                            <DropdownItem href="/xxx">
                                                <i className="la la-list-alt"></i> Search for asigned tasks
                                            </DropdownItem>
                                            <DropdownItem href="/xxx">
                                                <i className="la la-calendar"></i> Search for created events
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </form>
                        </div> */}
                        <ul className="menu-right">
                            { <div className="notifications-dropdown dropdown-menu-custom ">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        <i className="la la-bell"></i>
                                        <i className={ userStore.notifications.length ? 'count-notifs active' : 'count-notifs' }>{ userStore.notifications.length ? userStore.notifications.length : '' }</i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {
                                            userStore.notifications.map((item, index) => {
                                                return (
                                                    <DropdownItem key={'notification-' + index} className="message-box">
                                                        <div className="message-box-items">
                                                            <div className="text-message">
                                                                <a className="title" href={item.link}> {item.title} </a>
                                                                <p className="description" dangerouslySetInnerHTML={{__html: item.message}}></p>
                                                                <a className="close-btn"  onClick={(e) => this.readNotification(item.id, e)}>x</a>
                                                            </div>
                                                        </div>
                                                    </DropdownItem>
                                                )
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            }
                            <div className="profile-dropdown dropdown-menu-custom ">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        <img src={this.state.userAvatar ? this.state.userAvatar : (this.state.userSex == 1 ? profilePictureM : profilePictureF)} alt="profile" title="" className="user-round-avatar" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <AppContext.Consumer>{({toggleTheme, theme}) =>
                                            (
                                                <DropdownItem onClick={toggleTheme} key={'profile-link-theme'} className="message-box user-box">
                                                    <div className="message-box-items user-box-items">
                                                        <div className="text-message">
                                                            <span className="title"> <i className={theme === 'theme-dark' ? 'la la-sun-o' : 'la la-moon-o'}></i>
                                                                {theme === 'theme-dark' ? 'LIGHT MODE' : 'DARK MODE'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </DropdownItem>
                                            )}
                                        </AppContext.Consumer>
                                        <AppContext.Consumer>{({toggleTheme, theme}) =>
                                            (
                                                <DropdownItem onClick={() => { this.openUpdateProfileModal() }} key={'profile-link-theme'} className="message-box user-box">
                                                    <div className="message-box-items user-box-items">
                                                        <div className="text-message">
                                                            <span className="title"> <i className="la la-user"></i>
                                                                UPDATE PROFILE
                                                            </span>
                                                        </div>
                                                    </div>
                                                </DropdownItem>
                                            )}
                                        </AppContext.Consumer>
                                        {
                                            this.state.menuUser.map((item, index) => {
                                                return (
                                                    <DropdownItem href={item.href} key={'profile-link-' + index} className="message-box user-box">
                                                        <div className="message-box-items user-box-items">
                                                            <div className="text-message">
                                                                <span className="title"> <i className={item.cssIconClass}></i>{item.titleMenuUser}</span>
                                                            </div>
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </ul>
                        <Modal isOpen={this.state.showSetupWorkingPlaces} centered>
                            <span onClick={() => { this.closeShowSetupWorkingPlaces() }} className="la la-close close-modal"></span>
                            <ModalBody className="new--project">
                                Please, setup working places for at least three days forward.
                            </ModalBody>
                        </Modal>
                    </div>
                    {this.state.showUpdateProfile &&
                        <UpdateProfile
                            projectId="10190"
                            closeEditModal={this.closeUpdateProfile}
                            showPopup={this.state.showUpdateProfile}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default withCookies(Header);
