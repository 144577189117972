import React, { Component } from 'react';
import './KanbanDeskTask.scss';
import { withCookies } from 'react-cookie';


import { Row, Col, Progress } from 'reactstrap';


class KanbanDeskTask extends Component {

    render() {
        return (
            <div className="kd-task">
                <div className="kd-task--inner">
                    <Row>
                        <Col xs={12} >
                            <div className="kd-task--project-title"> {this.props.title}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} >
                            <div className="kd-task--target"><i className="la la-list-alt"></i><span className="nr">{this.props.progress}</span></div>
                        </Col>

                        <Col xs={3} >
                            <div className="kd-task--comments"><i className="la la-comments"></i><span className="nr">{this.props.taskComments}</span></div>
                        </Col>

                        <Col xs={5} >
                            <div className="kd-task--duedate"><i className="la la-calendar"></i><span className="nr">{this.props.dueDate}</span></div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col xs={12} >
                        <Progress color="warning" className="kd-task--progress-bar" value={this.props.progressBar} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withCookies(KanbanDeskTask);