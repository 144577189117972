import React, { Component } from 'react';
import './Approval.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";
import AddFeedbackApprove from "../../components/addFeedbackApprove/AddFeedbackApprove";
import {handleErrorResponse} from "../../utils/utils";
import DatePicker from "react-datepicker";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
var timer;

class Approval extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            tsEntries: [],
            userRole: -1,
            editTEId: -1,
            projectsList: [],
            usersList: [],
            filtersProjectId: null,
            filtersUserId: null,
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(),
            showAddFeedbackPopup: -1,
            isMultipleDecline: false,
            currentPage: 1,
            lastPage: 1,
            totalItems: '',
            seletedTimesheets: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if(parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        //role = 2 for superadmin or idg pm and 1 for devs
        this.setState({
            userRole: cookies.get('userRole')
        });

        this.getTSEntries(this.state.currentPage);
    }

    getTSEntries = (currentPage) => {
        const {cookies} = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'ts-for-approval', {
            params: {
                page: currentPage,
                project_id: this.state.filtersProjectId != null ? this.state.projectsList[this.state.filtersProjectId].id : null,
                user_id: this.state.filtersUserId != null ? this.state.usersList[this.state.filtersUserId].id : null,
                start_date: this.state.startDate,
                end_date:  this.state.endDate,
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                tsEntries: res.data.data,
                projectsList: this.state.projectsList.length ? this.state.projectsList : res.data.data.map((data) => {
                    return {
                        title: data.project_title,
                        id: data.project_id
                    }
                }).filter((value, index, self) => self.findIndex(val => (value.id === val.id)) === index),
                usersList: this.state.usersList.length ? this.state.usersList : res.data.data.map((data) => {
                    return {
                        user: data.user,
                        id: data.user_id
                    }
                }).filter((value, index, self) => self.findIndex(val => (value.id === val.id)) === index),
                lastPage: res.data.last_page,
                totalItems: res.data.total,
                loading: false,
                showAddFeedbackPopup: -1
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    };

    openModal = (params) => {
        this.setState({
            editTEId: params.teId,
            showAddFeedbackPopup: true,
            isMultipleDecline: params.isMultipleDecline
        });
    };

    closeModal = refreshList => {
        this.setState({
            showAddFeedbackPopup: -1
        });
        if(refreshList) {
            this.getTSEntries();
        }

        this.setState({
            isMultipleDecline: false
        });
    };

    approveTSEntries = (areApproved, feedbackApprove) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.post(process.env.REACT_APP_API_URL + 'mass-ts-for-approval' , {
            teIds: this.state.seletedTimesheets,
            decision: areApproved ? 1 : 2,
            feedbackApprove: feedbackApprove
        }, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken'),
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                const msg = areApproved ? 'TS Entries were approved.' : 'TS Entries were rejected.';
                const data = this.state.tsEntries.filter(i => !this.state.seletedTimesheets.includes(i.id));

                this.setState({
                    loading: false,
                    showAddFeedbackPopup: -1,
                    tsEntries: data
                });

                this.setState({
                    seletedTimesheets: []
                });

                toast.success(msg);
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }

                handleErrorResponse(err.response);
            })

        this.setState({
            isMultipleDecline: false
        });
    }

    approveTSEntry = (obj) => {
        const {cookies} = this.props;
        let data = JSON.stringify(obj);

        this.setState({
            loading: true
        });

        axios.post(process.env.REACT_APP_API_URL + 'ts-for-approval' , data, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken'),
                'Content-Type': 'application/json'
            }
        })
          .then(res => {
              const msg = (obj.decision === 1) ? 'TS Entry was approved.' : 'TS Entry was rejected.';
              const data = this.state.tsEntries.filter(i => i.id !== obj.teId);

              this.setState({
                  loading: false,
                  showAddFeedbackPopup: -1,
                  tsEntries: data
              });

              toast.success(msg);
          })
          .catch(err => {
              if(err.response.status === 401){
                  //redirect to login
                  this.context.redirectToLogin();
              } else {
                  this.setState({
                      loading: false
                  })
              }

              handleErrorResponse(err.response);
          })

    };

    handleChange(isChecked, id){
        let seletedTimesheets = this.state.seletedTimesheets;

        if(isChecked){
            seletedTimesheets.push(id);
        }else{
            let index = seletedTimesheets.indexOf(id);
            if (index > -1) {
                seletedTimesheets.splice(index, 1);
            }
        }

        this.setState({
            seletedTimesheets: seletedTimesheets
        });
    }

    handleStartDate = (date) => {
        let endDate = this.state.endDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getTSEntries(1));
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getTSEntries(1));
    }

    unSelectAll = () => {
        this.setState({
            seletedTimesheets: []
        });
    }

    selectAll = () => {
        this.setState({
            seletedTimesheets: this.state.tsEntries.map( (ts) => {
                return ts.id;
            })
        });
    }

    pageChanged = (page) => {
        window.clearTimeout(timer);

        timer = window.setTimeout(() => {
            page = this.state.currentPage + page;
            if ( 1 > page || page > this.state.lastPage )
                return;

            this.setState({
                currentPage: page
            });
            this.getTSEntries(page);
        },300);
    }

    filtersChanged = (filterId, filterValue) => {
        this.setState(
            {
                [filterId]: filterValue
            },
            () => {
                this.getTSEntries(1);
            });
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Entries for Approval"
                        roleNeeded={1}
                    />

                    <div className="TableHead">
                        <div className="TableHead--options">
                            <div className="filter">
                                <div className="filter--label">
                                    <i className="la la-line-chart"></i>
                                    Start date:
                                </div>
                                <div className="filter--dd xl">
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDate}
                                        className="start-date-col"
                                    />
                                </div>
                            </div>

                            <div className="filter">
                                <div className="filter--label">
                                    <i className="la la-line-chart"></i>
                                    End date:
                                </div>
                                <div className="filter--dd xl">
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDate}
                                        minDate={this.state.startDate}
                                        className="start-date-col"
                                    />
                                </div>
                            </div>
                            <div className="filter">
                                <div className="filter--label">
                                    <i className="la la-money"></i>
                                    Project:
                                </div>
                                <div className="filter--dd">
                                    <UncontrolledDropdown>
                                        <DropdownToggle nav>
                                            <div>
                                                { this.state.filtersProjectId !== null ? this.state.projectsList[this.state.filtersProjectId].title : 'Select' }
                                                <i className="la la-angle-down"></i>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.state.projectsList.map((project, index) => {
                                                    return (
                                                        <div key={'project-' + index} onClick={() => this.filtersChanged("filtersProjectId", index)}>
                                                            <DropdownItem>{project.title}</DropdownItem>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <div className="filter">
                                <div className="filter--label">
                                    <i className="la la-money"></i>
                                    User:
                                </div>
                                <div className="filter--dd">
                                    <UncontrolledDropdown>
                                        <DropdownToggle nav>
                                            <div>
                                                { this.state.filtersUserId !== null ? this.state.usersList[this.state.filtersUserId].user : 'Select' }
                                                <i className="la la-angle-down"></i>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.state.usersList.map((user, index) => {
                                                    return (
                                                        <div key={'user-' + index} onClick={() => this.filtersChanged("filtersUserId", index)}>
                                                            <DropdownItem>{user.user}</DropdownItem>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    { this.state.showAddFeedbackPopup !== -1 &&
                        <AddFeedbackApprove
                          teId={ this.state.editTEId !== -1 ? this.state.editTEId : '' }
                          isMultiple={this.state.isMultipleDecline}
                          closeModal={this.closeModal}
                          approveTSEntry={this.approveTSEntry}
                          approveTSEntries={this.approveTSEntries}
                          showPopup={this.state.showAddFeedbackPopup}
                        />
                    }

                    <div className="TSPage">
                        <div className="ts-head">
                            <div className="ts-pagination">
                                <button className="btn-pag btn-pag--prev" onClick={() => this.pageChanged(-1)}><i className="la la-chevron-circle-left"></i></button>
                                <span>Page: {this.state.currentPage}  / {this.state.lastPage} (total: {this.state.totalItems})</span>
                                <button className="btn-pag btn-pag--next" onClick={() => this.pageChanged(1)}><i className="la la-chevron-circle-right"></i></button>
                            </div>
                        </div>
                        <div className="mass-approve">
                            <button onClick={() => { this.approveTSEntries(true) }} disabled={!this.state.seletedTimesheets.length} className="btn btn-primary" >
                                Approve selected √
                            </button>
                            <button onClick={() => { this.openModal({isMultipleDecline: true}) }} disabled={!this.state.seletedTimesheets.length} className="btn btn-danger" >
                                Decline selected X
                            </button>
                            <button onClick={() => { this.selectAll() }} disabled={!this.state.tsEntries.length} className="btn btn-success" >
                                Select all
                            </button>
                            <button onClick={() => { this.unSelectAll() }} disabled={!this.state.seletedTimesheets.length} className="btn btn-warning" >
                                Unselect all
                            </button>
                        </div>
                        <div className="TableMainContainer">
                            <div className='daily-summary'></div>
                            {
                                this.state.tsEntries.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + item.project_id + index}
                                            tableCols = {
                                                [
                                                    {
                                                        checkBox: true,
                                                        handleChange: this.handleChange,
                                                        selected: this.state.seletedTimesheets,
                                                        id: item.id,
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: item.project_title,
                                                        label: 'Project',
                                                        link: '/project/' + item.project_id,
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: item.manual_date,
                                                        dateFormat: 'DD.MM.YYYY', //momentjs formats
                                                        label: 'Date'
                                                    },
                                                    {
                                                        value: item.user,
                                                        label: 'User'
                                                    },
                                                    {
                                                        value: item.hours,
                                                        label: 'h'
                                                    },
                                                    {
                                                        value: item.activity_name,
                                                        label: 'Activity',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: item.extra_request,
                                                        label: 'Extra',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        value: item.qa_issue,
                                                        label: 'QA',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        value: item.overtime,
                                                        label: 'OT',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        value: item.details,
                                                        label: 'Details',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: item.mjn,
                                                        label: 'MJN',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: item.is_approved,
                                                        label: 'Approve?',
                                                        customCssClass: 'row-details',
                                                        actions: [
                                                            {
                                                                label: '√',
                                                                name: 'approveTSEntry',
                                                                params: {teId: item.id, decision: 1}
                                                            },
                                                            {
                                                                label: 'X',
                                                                name: 'openModal',
                                                                params: {teId: item.id, decision: 2}
                                                            },
                                                        ]
                                                    }
                                                ]
                                            }
                                            approveTSEntry={this.approveTSEntry}
                                            openModal={this.openModal}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Approval.contextType = AppContext;
export default withCookies(Approval);
