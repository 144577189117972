import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import OverviewStats from "../OverviewStats/OverviewStats";
import axios from 'axios';
import Preloader from "../../preloader/Preloader";
import AppContext from "../../../context/index";

class ProjectsStats extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            projectsStats: []
        }
    }

    componentDidMount = () => {
        this.getProjectsStats();
    }

    getProjectsStats = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'projects-stats', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectsStats: res.data.data,
                loading: false
            });
        })
        .catch(err => {
            if(err && err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    render() {
        return (
            <div className="projects-overview">
                { this.state.loading && <Preloader/> }
                <h1 className="dash-section-title">Activity overview - 30 days</h1>
                <div className="row">
                    <div className="col-md-4">
                        <OverviewStats
                            title={this.state.projectsStats.wip}
                            subtitle='WIP projects'
                            percent={(parseInt(100 - this.state.projectsStats.wipPast/this.state.projectsStats.wip*100) || 0) + '%'}
                            icon='la la-spinner'
                            backgroundColor='#4D7CFE'
                            borderColor='#e5ecff'
                        />
                    </div>
                    <div className="col-md-4">
                        <OverviewStats
                            title={this.state.projectsStats.pending}
                            subtitle='Pending projects'
                            percent={(parseInt(100 - this.state.projectsStats.pendingPast/this.state.projectsStats.pending*100) || 0) + '%'}
                            icon='la la-hourglass-1'
                            backgroundColor='#FFAB2B'
                            borderColor='#fff3e0'
                        />
                    </div>
                    <div className="col-md-4">
                        <OverviewStats
                            title={this.state.projectsStats.completed}
                            subtitle='Completed projects'
                            percent={(parseInt(100 - this.state.projectsStats.completedPast/this.state.projectsStats.completed*100) || 0) + '%'}
                            icon='la la-check'
                            backgroundColor='#6DD230'
                            borderColor='#eaf9e1'
                        />
                    </div>
                </div>
            </div>
        );
    }

}
ProjectsStats.contextType = AppContext;
export default withCookies(ProjectsStats);