import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddBrand from "../../components/addBrand/AddBrand";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class Brands extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            brandsList: [],
            businessUnitsList: [], //pm filters
            selectedBusinessUnits: [],
            selectedBusinessUnitsIds: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddBrand: false,
            editBrandId: '',
            editBrandName: '',
            editBrandHogarthVisible: false
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getBrands();
    }

    openModal = (clearData) => {
        if (clearData) {
            this.setState({
                editBrandId: '',
                editBrandName: '',
                selectedBusinessUnits: [],
                editBrandHogarthVisible: false
            })
        }
        this.setState({
            showAddBrand: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddBrand: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getBrands();
            }
        );
    }

    searchBrandsByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getBrands();
        });

    }

    getBusinessUnits(){
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'business-units/all', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
            .then(res => {

                if(res.data){
                    let businessUnits = [];

                    for(let key in res.data){
                        businessUnits.push({
                            label: res.data[key].name,
                            value: res.data[key].id
                        });
                    }

                    this.setState({
                        businessUnitsList: businessUnits,
                        loading: false
                    });
                }
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }

    getBrands = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'brands', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                brandsList: res.data.data.paginatedBrands,
                totalResults: res.data.data.totalResults,
                loading: false
            }, () => { this.getBusinessUnits() });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editBrand = (brandId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'brand/' + brandId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editBrandId: res.data.data.id,
                editBrandName: res.data.data.name,
                selectedBusinessUnits: res.data.data.business_units ? res.data.data.business_units.map((item) => { return {
                    label: item.name,
                    value: item.id
                } } ) : [],
                selectedBusinessUnitsIds: res.data.data.business_units ? res.data.data.business_units.map((item) => { return item.id}) : [],
                // editBrandHogarthVisible: res.data.data.hogarth_visible,
                showAddBrand: true
            })

        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveBrand = (brandData, successCallback) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        //if we have a brand id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (brandData.brandId) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'brands' + (brandData.brandId ? ('/' + brandData.brandId) : ''),
            data: {
                name: brandData.brandName,
                business_units: brandData.selectedBusinessUnitsIds,
                // hogarth_visible: brandData.hogarthVisible
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getBrands();
            this.setState({
                loading: false,
                showAddBrand: false
            })

            if(successCallback){
                successCallback();
            }

            toast.success("Brand has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddBrand: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    deleteBrand = async (brandId) => {
        const { cookies } = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'brands/' + brandId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getBrands();
                toast.success("Activity type has been deleted.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
                toast.error("An error occured, please try again later.");
            })
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                {this.state.loading && <Preloader />}
                <Sidebar />
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Brands" 
                        headerOptions={[{
                            name: 'New Brand', 
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="BrandsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchBrandsByName} placeholder='Search brands by name' />
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => { this.openModal(true) }} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Brand
                                <AddBrand
                                    brandId={this.state.editBrandId}
                                    brandName={this.state.editBrandName}
                                    hogarthVisible={this.state.editBrandHogarthVisible}
                                    businessUnitsList={this.state.businessUnitsList}
                                    selectedBusinessUnitsIds={this.state.selectedBusinessUnitsIds}
                                    selectedBusinessUnits={this.state.selectedBusinessUnits}
                                    saveBrand={this.saveBrand}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddBrand}
                                />
                            </button>

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.brandsList.map((item, index) => {
                                    return (
                                        <TableRow
                                            key={'row-' + index}
                                            tableCols={
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Brand',
                                                        mobileFullWidth: true,
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    // {
                                                    //     value: item.hogarth_visible,
                                                    //     label: 'Hogarth Visible',
                                                    //     showAsCheckbox: true
                                                    // },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit this brand',
                                                                action: {
                                                                    name: 'editBrand',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove this brand',
                                                                action: {
                                                                    name: 'deleteBrand',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editBrand={this.editBrand}
                                            deleteBrand={this.deleteBrand}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)} />
                    </div>
                </div>
            </div>
        );
    }
}
Brands.contextType = AppContext;
export default withCookies(Brands);