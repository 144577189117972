import React, { Component,  } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import Select from 'react-select'
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import AppContext from "../../context/index";
import { userStore } from "../../stores/user";
import DatePicker from "react-datepicker";
import {CSVLink} from "react-csv";

class Projects extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            projectList: [],
            projectStatuses: [],
            jobNumbersList: [], //pm filters
            clientsList: [], //pm filters
            brandsList: [], //pm filters
            clientPMsList: [], //pm filters
            projectTypesList: [], //pm filters
            idgPMsList: [], //pm filters
            pcatsList: [], // cats filters
            loading: {
                PCastList: false,
                jobNumbers: false,
                projectStatuses: false,
                projectsList: false
            },
            page: 1,
            filtersStartDate: new Date(new Date().getFullYear(), 0, 1),
            filtersEndDate: new Date(),
            filtersStatus: -1,
            filtersClientId: -1,
            filtersBrandId: -1,
            filtersClientPMId: -1,
            filtersProjectTypeId: -1,
            filtersIdgPMId: -1,
            filtersCategoryId: -1,
            filtersTitle: '',
            filtersCostEstimation: '',
            filtersDescription: '',
            filtersJN: [],
            filtersJNForSelect: [],
            userRole: -1,
            editProjectId: -1
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount(){
        this.getProjectStatuses();

        const { cookies } = this.props;

        //role = 2 for superadmin or idg pm and 1 for devs
        this.setState({
            userRole: cookies.get('userRole')
        });

        if(parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getProjectTypesList();
        this.getPCatsList();
        this.getJobNumbers();

        this.setState({
            brandsList: userStore.brands,
            clientsList: userStore.clients,
            clientPMsList: userStore.getClientPMs(),
            idgPMsList: userStore.getIdPMs(),
            filtersIdgPMId: Object.keys(userStore.getIdPMs()).find(key => userStore.getIdPMs()[key].id === userStore.me.id)
        });

    }

    setLoading(name,status){
        let newData = {};
        newData[name] = status;

        this.setState({
            loading: {...this.state.loading,...newData}
        });
    }

    handleStartDate = (date) => {
        let filtersEndDate = this.state.filtersEndDate;

        if(date > this.state.filtersEndDate){
            filtersEndDate = date;
        }

        this.setState({
            filtersStartDate: date,
            filtersEndDate: filtersEndDate
        }, () => this.getProjectsList());
    }

    handleEndDate = (date) => {
        this.setState({
            filtersEndDate: date
        }, () => this.getProjectsList());
    }

    filtersChanged = (filterId, filterValue, filterMinLength) => {
        this.setState(
        {
            [filterId]: filterValue
        },
        () => {
            if(filterValue.length >= filterMinLength || (filterMinLength === undefined)){
                this.getProjectsList();
                this.getJobNumbers();
            }

            this.saveFilterInSession(filterId, filterValue);
        });
    }

    getProjectsList = () => {
        /* Projects List */
        const { cookies } = this.props;
        this.setLoading('projectsList', true);

        axios.get(process.env.REACT_APP_API_URL + 'reports-projects', {
            params: {
                startDate: this.state.filtersStartDate,
                endDate: this.state.filtersEndDate,
                status: this.state.filtersStatus !== -1 && this.state.projectStatuses[this.state.filtersStatus] ? this.state.projectStatuses[this.state.filtersStatus].id : -1,
                clientId: this.state.filtersClientId !== -1 && this.state.clientsList[this.state.filtersClientId] ? this.state.clientsList[this.state.filtersClientId].id : -1,
                brandId: this.state.filtersBrandId !== -1 && this.state.brandsList[this.state.filtersBrandId] ? this.state.brandsList[this.state.filtersBrandId].id : -1,
                clientPM: this.state.filtersClientPMId !== -1 && this.state.clientPMsList[this.state.filtersClientPMId] ? this.state.clientPMsList[this.state.filtersClientPMId].id : -1,
                type: this.state.filtersProjectTypeId !== -1 && this.state.projectTypesList[this.state.filtersProjectTypeId] ? this.state.projectTypesList[this.state.filtersProjectTypeId].id : -1,
                pm: this.state.filtersIdgPMId === 1000 ? 1000 : (this.state.filtersIdgPMId !== -1 && this.state.idgPMsList[this.state.filtersIdgPMId] ? this.state.idgPMsList[this.state.filtersIdgPMId].id : -1),
                categoryId: (this.state.filtersCategoryId !== -1 && this.state.pcatsList[this.state.filtersCategoryId]) ? this.state.pcatsList[this.state.filtersCategoryId].id : -1,
                title: this.state.filtersTitle,
                costEstimation: this.state.filtersCostEstimation,
                description: this.state.filtersDescription,
                jn: this.state.filtersJN
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setLoading('projectsList', false);
            this.setState({
                projectList: res.data.data.projects,
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setLoading('projectsList', false);
            }
        })
    }

    getJobNumbers(){
        const { cookies } = this.props;
        this.setLoading('jobNumbers', true);

        axios.get(process.env.REACT_APP_API_URL + 'job-numbers/load-all', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            },
            params: {
                project_status: this.state.filtersStatus == -1 ? 2 : this.state.filtersStatus
            }
        })
            .then(res => {

                if(res.data){
                    let jobNumbers = [];

                    for(let key in res.data){
                        jobNumbers.push({
                            label: res.data[key].name,
                            value: res.data[key].id
                        });
                    }

                    this.setState({
                        jobNumbersList: jobNumbers
                    });
                    this.setLoading('jobNumbers', false);
                }
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setLoading('jobNumbers', false);
                }
            })
    }

    /* Filter Options & Lists */
    getProjectStatuses = () => {
        /* Project Statuses - Dropdown Options */
        const { cookies } = this.props;
        this.setLoading('projectStatuses', true);

        axios.get(process.env.REACT_APP_API_URL + 'project-statuses', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectStatuses: res.data.data,
                filtersStatus: this.state.filtersStatus === - 1 ? 1 : this.state.filtersStatus,
            }, this.initFiltersFromSession);
            this.setLoading('projectStatuses', false);
        })
        .catch(err => {
            if(err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setLoading('projectStatuses', false);
            }
        })
    }

    getProjectTypesList = () => {
        /* Project Types - Dropdown Options */
        const { cookies } = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'project-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectTypesList: res.data.data
            });
        })
        .catch(err => {
            if(err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
        })
    }

    getPCatsList = () => {
        const { cookies } = this.props;
        this.setLoading('PCastList', true);

        axios.get(process.env.REACT_APP_API_URL + 'project-categories', {
            params: {
                page: 1,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
          .then(res => {
              this.setState({
                  pcatsList: res.data.data.paginatedProjectCategories,
              });
              this.setLoading('PCastList', false);
          })
          .catch(err => {
              this.setLoading('PCastList', false);
          })
    }

    clearFilters = () => {
        document.getElementById('filterByTitle').value = '';
        document.getElementById('filterByDescription').value = '';
        document.getElementById('filterByCostEstimation').value = '';

        let initialFilters = {
            filtersStatus: 1,
            filtersClientId: -1,
            filtersBrandId: -1,
            filtersClientPMId: -1,
            filtersProjectTypeId: -1,
            filtersIdgPMId: -1,
            filtersCategoryId: -1,
            filtersTitle: '',
            filtersDescription: '',
            filtersCostEstimation: '',
            filtersJN: [],
            filtersJNForSelect: []
        };

        this.setState(initialFilters, () => this.getProjectsList());
        sessionStorage.setItem('projectsList', JSON.stringify(initialFilters));
    }

    jobNumbersChange(jobNumbers){
        let jobNumbersValues = jobNumbers.map((item) => { return item.value });

        this.setState({
            filtersJN: jobNumbersValues,
            filtersJNForSelect: jobNumbers
        }, () => this.getProjectsList());

        this.saveFilterInSession('filtersJN', jobNumbersValues);
        this.saveFilterInSession('filtersJNForSelect', jobNumbers);
    }

    initFiltersFromSession(){
        let currentSessionProjectListObj = this.getCurrentFiltersInSession();
        this.setState(currentSessionProjectListObj, this.getProjectsList);
    }

    getCurrentFiltersInSession(){
        return sessionStorage.getItem('projectsList') ? JSON.parse(sessionStorage.getItem('projectsList')) : {};
    }

    saveFilterInSession(filterId, filterValue){
        let currentSessionProjectListObj = this.getCurrentFiltersInSession();
        currentSessionProjectListObj[filterId] = filterValue;

        sessionStorage.setItem('projectsList', JSON.stringify(currentSessionProjectListObj));
    }

    render() {
        let csvData = [];
        csvData.push(["Project", "Job numbers", "Client", "Brand", "Client PM", "IDG PM", "Start", "Deadline", "Type", "Act", "Profit"])

        this.state.projectList.map((project, key) => {
            csvData.push([
                project.title || '',
                project.job_numbers.map((item) => { return item.name }).join(','),
                project.client_name || '',
                project.brand_name || '',
                project.hg_pm_name || '',
                project.idg_pm_name || '',
                project.start_date || '',
                project.deadline || '',
                parseFloat(project.estimated_cost == null ? 0 : project.estimated_cost).toFixed(2),
                parseFloat(project.actual_cost).toFixed(2),
                parseFloat(project.estimated_cost - project.actual_cost).toFixed(2),
            ]);
        });

        const styleSelect = {
            control: (base, state) => ({
                ...base,
                minWidth: '150px',
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
                border: userStore.me.settings.theme === 'theme-dark' ? '1px solid #2a2d2e !important' : '1px solid #d3d3d3 !important',
                boxShadow: 'none'
            }),
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    color: userStore.me.settings.theme === 'theme-dark' ? '#778ca2' : '#000',
                }
            },
            option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                color: userStore.me.settings.theme === 'theme-dark'  ? (state.isFocused ? '#FFF' : '#778ca2') : '#000',
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
            }),
            input: (provided, state) => ({
                ...provided,
                color: userStore.me.settings.theme === 'theme-dark'  ? (state.isFocused ? '#FFF' : '#778ca2') : '#000',
            }),
            singleValue: (provided, state) => ({
                ...provided,
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
            }),
            menuList: (provided, state) => ({
                ...provided,
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF'
            })
        };
        return (
            <div className="page--with-header-sidebar">
                { (this.state.loading.PCastList || this.state.loading.jobNumbers || this.state.loading.projectStatuses || this.state.loading.projectsList) && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle={ "Reports::Projects • total: " + this.state.projectList.length }
                    />
                    <div className="ReportsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-line-chart"></i>
                                            Status:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersStatus != -1 && this.state.projectStatuses[this.state.filtersStatus] ? this.state.projectStatuses[this.state.filtersStatus].name : 'ALL' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <div key={'status-all'} onClick={() => this.filtersChanged("filtersStatus", -1)}>
                                                    <DropdownItem>ALL</DropdownItem>
                                                </div>
                                                {
                                                this.state.projectStatuses.map((status, index) => {
                                                    return (
                                                    <div key={'status-' + index} onClick={() => this.filtersChanged("filtersStatus", index)}>
                                                        <DropdownItem>{status.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-filter"></i>
                                            Job numbers:
                                        </div>
                                        {/*<div className="filter--dd">*/}
                                        {/*    <input type="text" id="filterByJN" onChange={(event) => (event.target.value.length >= 3 || event.target.value.length == 0) ? this.filtersChanged("filtersJN", event.target.value) : {}}></input>*/}
                                        {/*</div>*/}
                                        <Select
                                            styles={styleSelect}
                                            value={this.state.filtersJNForSelect}
                                            options={this.state.jobNumbersList}
                                            onChange={(selectedOptions) => {
                                                this.jobNumbersChange(selectedOptions)
                                            }}
                                            isMulti
                                        />
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-money"></i>
                                            Cost estimation:
                                        </div>
                                        <div className="filter--dd">
                                            <input type="text" id="filterByCostEstimation" value={this.state.filtersCostEstimation}
                                                   onChange={(event) => this.filtersChanged("filtersCostEstimation", event.target.value, 0)}></input>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-keyboard-o"></i>
                                            Title:
                                        </div>
                                        <div className="filter--dd">
                                            <input type="text" id="filterByTitle" value={this.state.filtersTitle} onChange={(event) =>  this.filtersChanged("filtersTitle", event.target.value, 3)}></input>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la la-leanpub"></i>
                                            Description:
                                        </div>
                                        <div className="filter--dd">
                                            <input type="text" id="filterByDescription" value={this.state.filtersDescription} onChange={(event) => this.filtersChanged("filtersDescription", event.target.value, 3)}></input>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-money"></i>
                                            Client:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersClientId != -1 ? this.state.clientsList[this.state.filtersClientId].name.substr(0, 10) + '...' : 'Select' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                this.state.clientsList.map((client, index) => {
                                                    return (
                                                    <div key={'client-' + index} onClick={() => this.filtersChanged("filtersClientId", index)}>
                                                        <DropdownItem>{client.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-registered"></i>
                                            Brand:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersBrandId != -1 ? this.state.brandsList[this.state.filtersBrandId].name : 'Select' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                this.state.brandsList.map((brand, index) => {
                                                    return (
                                                    <div key={'brand-' + index} onClick={() => this.filtersChanged("filtersBrandId", index)}>
                                                        <DropdownItem>{brand.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-male"></i>
                                            Client PM:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersClientPMId != -1 ? this.state.clientPMsList[this.state.filtersClientPMId].name : 'Select' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                this.state.clientPMsList.map((clientPM, index) => {
                                                    return (
                                                    <div key={'client-pm-' + index} onClick={() => this.filtersChanged("filtersClientPMId", index)}>
                                                        <DropdownItem>{clientPM.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-magic"></i>
                                            Type:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersProjectTypeId != -1 ? this.state.projectTypesList[this.state.filtersProjectTypeId].name : 'Select' }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                this.state.projectTypesList.map((type, index) => {
                                                    return (
                                                    <div key={'type-' + index} onClick={() => this.filtersChanged("filtersProjectTypeId", index)}>
                                                        <DropdownItem>{type.name}</DropdownItem>
                                                    </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-male"></i>
                                            PM:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                { this.state.filtersIdgPMId === 1000 ? 'ALL' : (this.state.filtersIdgPMId != -1 ? this.state.idgPMsList[this.state.filtersIdgPMId].name : 'Select') }
                                                <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <div key={'idg-pm-all'} onClick={() => this.filtersChanged("filtersIdgPMId", 1000)}>
                                                    <DropdownItem>ALL</DropdownItem>
                                                </div>
                                                {
                                                this.state.idgPMsList.map((pm, index) => {
                                                    return (
                                                        <div key={'idg-pm-' + index} onClick={() => this.filtersChanged("filtersIdgPMId", index)}>
                                                            <DropdownItem>{pm.name}</DropdownItem>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-flask"></i>
                                        Category:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                    { this.state.filtersCategoryId === -1 ? 'ALL' : this.state.pcatsList[this.state.filtersCategoryId].title }
                                                    <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <div key={'idg-dev-all'} onClick={() => this.filtersChanged("filtersCategoryId", -1)}>
                                                    <DropdownItem>ALL</DropdownItem>
                                                </div>
                                                {
                                                    this.state.pcatsList.map((cat, index) => {
                                                        return (
                                                          <div key={'idg-cat-' + index} onClick={() => this.filtersChanged("filtersCategoryId", index)}>
                                                              <DropdownItem>{cat.title}</DropdownItem>
                                                          </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.filtersStartDate}
                                            onChange={this.handleStartDate}
                                            className="start-date-col"
                                        />
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <DatePicker
                                            selected={this.state.filtersEndDate}
                                            onChange={this.handleEndDate}
                                            className="start-date-col"
                                        />
                                    </div>
                                </div>
                                <div className="filter filter--reset">
                                    <button className="btn--ts btn--ts-grey" onClick={() => this.clearFilters()}>Clear filters</button>
                                </div>
                                {(this.state.projectList && this.state.projectList.length > 0) &&
                                    <div className="export-link">
                                        <CSVLink data={csvData} filename={"projects.csv"}>
                                            Export to CSV
                                        </CSVLink>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="TableMainContainer">
                            {
                                this.state.projectList.map((item, index) => {
                                    return (
                                        <TableRow
                                                key={'row-' + index}
                                                customRowClass = {item.parent_id ? 'child-project' : ''}
                                                tableCols={
                                                    [
                                                        {
                                                            value: item.title,
                                                            label: 'Project title',
                                                            link: '/project/' + item.id,
                                                            mobileFullWidth: true,
                                                            customCssClass: 'row-details row-head-mobile'
                                                        },
                                                        {
                                                            value: item.business_units,
                                                            label: "Business units",
                                                            customCssClass: 'row-details'
                                                        },
                                                        {
                                                            value: item.job_numbers.map((item) => { return item.name }).join(','),
                                                            label: 'Job numbers',
                                                            width: 200,
                                                            customCssClass: 'row-details'
                                                        },
                                                        {
                                                            value: item.client_name,
                                                            label: 'Client',
                                                            hiddenMobile: true,
                                                            customCssClass: 'row-details'
                                                        },
                                                        {
                                                            value: item.brand_name,
                                                            label: 'Brand',
                                                            hiddenMobile: true
                                                        },
                                                        {
                                                            value: item.hg_pm_name,
                                                            label: 'Client PM',
                                                            hiddenMobile: true,
                                                            customCssClass: 'row-details'
                                                        },
                                                        {
                                                            value: item.idg_pm_name,
                                                            label: 'IDG PM',
                                                            hiddenMobile: true,
                                                            customCssClass: 'row-details'
                                                        },
                                                        {
                                                            value: item.start_date,
                                                            dateFormat: 'DD.MM.YYYY', //momentjs formats
                                                            label: 'Start',
                                                            hiddenMobile: true
                                                        },
                                                        {
                                                            value: item.deadline,
                                                            dateFormat: 'DD.MM.YYYY', //momentjs formats
                                                            label: 'Deadline',
                                                            hiddenMobile: true
                                                        },
                                                        {
                                                            value: item.type,
                                                            label: 'Type',
                                                            hiddenMobile: true
                                                        },
                                                        {
                                                            value: parseFloat(item.estimated_cost == null ? 0 : item.estimated_cost).toFixed(2),
                                                            label: 'Est',
                                                            hiddenMobile: true
                                                        },
                                                        {
                                                            value: parseFloat(item.actual_cost).toFixed(2),
                                                            label: 'Act',
                                                            hiddenMobile: true
                                                        },
                                                        {
                                                            value: parseFloat(item.estimated_cost - item.actual_cost).toFixed(2),
                                                            label: 'Profit',
                                                            hiddenMobile: true,
                                                            customCssClass: parseFloat(item.estimated_cost - item.actual_cost) < 0 ? 'danger' : ''
                                                        }
                                                    ]
                                                }
                                            />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Projects.contextType = AppContext;
export default withCookies(Projects);
