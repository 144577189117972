import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddIdgUnits from "../../components/addIdgUnits/AddIdgUnits";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class IdgUnits extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            idgUnitsList: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddIdgUnits: false,
            editIdgUnitsId: '',
            editIdgUnitsName: '',
            editEntityName: '',
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getIdgUnits();
    }

    openModal = (clearData) => {
        if (clearData) {
            this.setState({
                editIdgUnitsId: '',
                editIdgUnitsName: '',
                editEntityName: '',
            })
        }
        this.setState({
            showAddIdgUnits: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddIdgUnits: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getIdgUnits();
            }
        );
    }

    searchIdgUnitsByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getIdgUnits();
        });
        
    }

    getIdgUnits = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'idg-units', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                idgUnitsList: res.data.data.paginatedInfodesignUnits,
                totalResults: res.data.data.totalResults,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editIdgUnits = (idgUnitsId) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'idg-unit/' + idgUnitsId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editIdgUnitsId: res.data.data.id,
                editIdgUnitsName: res.data.data.name,
                editEntityName: res.data.data.legal_entity,
                showAddIdgUnits: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveIdgUnits = (idgUnitsData) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        //if we have an idg id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (idgUnitsData.idgUnitsId) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'idg-units' + (idgUnitsData.idgUnitsId ? ('/' + idgUnitsData.idgUnitsId) : ''),
            data: {
                name: idgUnitsData.idgUnitsName,
                legal_entity: idgUnitsData.idgUnitsEntity
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getIdgUnits();
            this.setState({
                loading: false,
                showAddIdgUnits: false
            })
            toast.success("IDG unity has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddIdgUnits: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    deleteIdgUnits = async (idgUnitsId) => {
        const {cookies} = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'idg-units/' + idgUnitsId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getIdgUnits();
                toast.success("IDG unity has been deleted.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
                toast.error("An error occured, please try again later.");
            })
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="IDG units"
                        headerOptions={[{
                            name: 'New IDG unit',
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="IdgUnitsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchIdgUnitsByName} placeholder='Search idg unit by name'/>
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => {this.openModal(true)}} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Unit
                                <AddIdgUnits
                                    idgUnitsId={this.state.editIdgUnitsId}
                                    idgUnitsName={this.state.editIdgUnitsName}
                                    idgUnitsEntity={this.state.editEntityName}
                                    saveIdgUnits={this.saveIdgUnits}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddIdgUnits}
                                />
                            </button>    

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.idgUnitsList.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Name',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.legal_entity,
                                                        label: 'Legal entity',
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit unit',
                                                                action: {
                                                                    name: 'editIdgUnits',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove unit',
                                                                action: {
                                                                    name: 'deleteIdgUnits',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editIdgUnits={this.editIdgUnits}
                                            deleteIdgUnits={this.deleteIdgUnits}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)}/>
                    </div>
                </div>
            </div>
        );
    }
}
IdgUnits.contextType = AppContext;
export default withCookies(IdgUnits);
