import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import TableRow from "../../components/tableRow/TableRow";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Preloader from "../../components/preloader/Preloader";
import AddResource from "../../components/addResource/AddResource";
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';
import AppContext from "../../context/index";

class ProjectResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            resources: [],
            showAddResource: false
        };
    }

    componentDidMount() {
        this.getProjectResources();
    }

    getProjectResources = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'project-resources/' + this.props.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                resources: res.data.data,
                loading: false
            });
            this.syncResources();
        })
        .catch(err => {
            if(typeof err.response !== 'undefined' && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    addProjectResource = () => {
        this.setState({
            resourceEdit: {},
            showAddResource: true
        });
    };

    editProjectResource = (resourceId) => {
        this.setState({
            resourceEdit:  this.state.resources.filter(i => i.id === resourceId)[0],
            showAddResource: true
        });
    };

    removeProjectResource = async (resourceId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            });

            axios.delete(process.env.REACT_APP_API_URL + 'project-resources/' + resourceId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getProjectResources(this.props.projectId);
                toast.success("Resource has been removed.");
            })
            .catch(err => {
                if (typeof err.response !== 'undefined' && err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
        }
    }

    openResourceModal = () => {
        this.setState({
            showAddResource: true
        });
    }
    closeResourceModal = refreshList => {
        this.setState({
            showAddResource: false
        });
        if(refreshList) {
            this.getProjectResources(this.props.projectId);
        }
    };
    syncResources = () => {
        this.props.syncResources(this.state.resources);
    }

    render() {

        return (
            <div className="project-subsection">
                { this.state.loading && <Preloader/> }
                <div className="row project-subsection--head">
                    <div className="col-10"><h1>Assigned resources</h1></div>
                    <div className="col-2 ta-right">
                        <UncontrolledDropdown>
                            <DropdownToggle nav>
                                <i className="la la-plus-circle"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem key={'rs-1'} onClick={this.addProjectResource}>New resource</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                {this.state.showAddResource &&
                    <AddResource
                        resourceEdit={this.state.resourceEdit}
                        projectId={this.props.projectId}
                        closeResourceModal={this.closeResourceModal}
                        showPopup={this.state.showAddResource}
                    />
                }
                <div className="project-subsection--body">
                    {this.state.resources.map((resource, index) => {
                        return(
                            <TableRow
                                key={'pr-' + index}
                                tableCols={
                                    [
                                        {
                                            value: resource.user_name,
                                            label: 'User',
                                            mobileFullWidth: true,
                                            customCssClass: 'row-head-mobile'
                                        },
                                        {
                                            value: resource.activity,
                                            label: 'Activity'
                                        },
                                        {
                                            value: parseFloat(resource.max_hours),
                                            label: 'Hours'
                                        },
                                        {
                                            ddOptions: [
                                                {
                                                    value: 'Edit',
                                                    action: {
                                                        name: 'editProjectResource',
                                                        params: resource.id
                                                    }
                                                },
                                                {
                                                    value: 'Remove',
                                                    action: {
                                                        name: 'removeProjectResource',
                                                        params: resource.id
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                                removeProjectResource={this.removeProjectResource}
                                editProjectResource={this.editProjectResource}
                            />
                        )
                    })}
                </div>
            </div>
        );
    }
}
ProjectResources.contextType = AppContext;
export default withCookies(ProjectResources);
