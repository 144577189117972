import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts';
import AppContext from "../../context/index";
import { CSVLink, CSVDownload } from "react-csv";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import DatePicker from "react-datepicker";
import userStore from "../../stores/user";

class MissingCEs extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            reportData: [],
            reportDetails: [],
            reportByPM: [],
            reportByClientPM: [],
            reportByProjectType: [],
            totalMissingValue: 0,
            filtersClientId: -1,
            filtersBrandId: -1,
            filtersClientPMId: -1,
            filtersProjectTypeId: -1,
            filtersIdgPMId: -1,
            filtersTitle: '',
            filtersJN: '',
            filtersProjectStatus: -1,
            clientsList: [], //filters dd
            brandsList: [], //filters dd
            clientPMsList: [], //filters dd
            projectTypesList: [], //filters dd
            idgPMsList: [], //filters dd
            projectStatuses: [
                {
                    id: '3',
                    name: 'Closed'
                },
                {
                    id: 2,
                    name: 'WIP'
                }
            ]
        }
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();

        //filters
        this.getProjectTypesList();

        this.setState({
            brandsList: userStore.brands,
            clientsList: userStore.clients,
            clientPMsList: userStore.getClientPMs(),
            idgPMsList: userStore.getIdPMs(),
            filtersIdgPMId: Object.keys(userStore.getIdPMs()).find(key => userStore.getIdPMs()[key].id === userStore.me.id)
        });
    }

    filtersChanged = (filterId, filterValue) => {
        this.setState(
            {
                [filterId]: filterValue,
                page: 1, // reset pagination when status filter is changed
                paginationKey: new Date().getTime() //unique key to reset pagination component
            },
            () => {
                this.getReportData();
            });
    }

    getProjectTypesList = () => {
        /* Project Types - Dropdown Options */
        const { cookies } = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'project-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectTypesList: res.data.data
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
        })
    }

    clearFilters = () => {
        document.getElementById('filterByTitle').value = '';
        document.getElementById('filterByJN').value = '';
        this.setState({
            filtersClientId: -1,
            filtersBrandId: -1,
            filtersClientPMId: -1,
            filtersProjectTypeId: -1,
            filtersIdgPMId: Object.keys(userStore.getIdPMs()).find(key => userStore.getIdPMs()[key].id === userStore.me.id),
            filtersTitle: '',
            filtersJN: '',
            filtersProjectStatus: -1
        }, () => this.getReportData());
    }

    getReportData = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'reports-missing', {
            params: {
                /* Type of report of missing */
                section: 'ce',
                clientId: this.state.filtersClientId != -1 ? this.state.clientsList[this.state.filtersClientId].id : -1,
                brandId: this.state.filtersBrandId != -1 ? this.state.brandsList[this.state.filtersBrandId].id : -1,
                projectStatusId: this.state.filtersProjectStatus != -1 ? this.state.projectStatuses[this.state.filtersProjectStatus].id : -1,
                clientPM: this.state.filtersClientPMId != -1 ? this.state.clientPMsList[this.state.filtersClientPMId].id : -1,
                type: this.state.filtersProjectTypeId != -1 ? this.state.projectTypesList[this.state.filtersProjectTypeId].id : -1,
                pm: this.state.filtersIdgPMId === 1000 ? 1000 : (this.state.filtersIdgPMId != -1 && typeof this.state.idgPMsList[this.state.filtersIdgPMId] !== "undefined" ? this.state.idgPMsList[this.state.filtersIdgPMId].id : -1),
                title: this.state.filtersTitle,
                jn: this.state.filtersJN
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            res = res.data.data;

            let repByPM = [];
            let repByClientPM = [];
            let repByProjectType = [];
            let totalMissing = 0;

            for(let i in res) {
                repByPM[res[i].idg_pm_name] = (repByPM[res[i].idg_pm_name] || 0) + 1;
                repByClientPM[res[i].client_pm_name] = (repByClientPM[res[i].client_pm_name] || 0) + 1;
                repByProjectType[res[i].project_type_name] = (repByProjectType[res[i].project_type_name] || 0) + 1;
                totalMissing += 1;
            }

            let reportByPM = [];
            for(let i in repByPM) {
                if(repByPM[i] != 0) {
                    reportByPM.push({
                        name: i,
                        value: repByPM[i]
                    })
                }
            }

            let reportByClientPM = [];
            for(let i in repByClientPM) {
                if(repByClientPM[i] != 0) {
                    reportByClientPM.push({
                        name: i,
                        value: repByClientPM[i]
                    })
                }
            }

            let reportByProjectType = [];
            for(let i in repByProjectType) {
                if(repByProjectType[i] != 0) {
                    reportByProjectType.push({
                        name: i,
                        value: repByProjectType[i]
                    })
                }
            }

            this.setState({
                loading: false,
                reportData: res,
                reportByPM: reportByPM,
                reportByClientPM: reportByClientPM,
                reportByProjectType: reportByProjectType,
                totalMissingValue: Object.keys(res).length
            });
        })
        .catch(err => {
            if(err && err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                console.error(err);
                this.setState({
                    loading: false
                })
            }
        })
    }

    render() {

        const COLORS = ['#0043fe', '#4173ff', '#608afd', '#81a3fe', '#a4bcff', '#c4d4ff', '#002da9', '#002489', '#3955a2', '#061a52', '#5971b6', '#6886d9'];

        let csvData = [];
        csvData.push(["JN", "Project", "Status", "Type", "Brand", "Client PM", "PM", "Created"])

        for(let i in this.state.reportData) {
            csvData.push([
                this.state.reportData[i].project_job_number || '',
                this.state.reportData[i].project_title || '',
                this.state.reportData[i].project_status_name,
                this.state.reportData[i].project_type_name,
                this.state.reportData[i].brand_name,
                this.state.reportData[i].client_pm_name,
                this.state.reportData[i].idg_pm_name,
                this.state.reportData[i].created_at
            ]);
        }

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="80"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Reports::Missing CEs"
                    />
                    <div className="ReportsPage">
                        <div className="total-missing">
                            <span className="label">Number of projects: </span>{this.state.totalMissingValue.toLocaleString()}
                        </div>

                        <div className="responsive-chart">
                            <div className="row">
                                <div className="col-md-4">
                                    <span className="chart--custom-label">Project managers</span>
                                    <div className="chart">
                                        <ResponsiveContainer>
                                            <PieChart>
                                                <Pie
                                                    data={this.state.reportByPM}
                                                    fill="#4D7CFE"
                                                    dataKey="value"
                                                    >
                                                    {
                                                        this.state.reportByPM.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                    }
                                                </Pie>
                                                <Tooltip/>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <span className="chart--custom-label">Client PMs</span>
                                    <div className="chart">
                                        <ResponsiveContainer>
                                        <PieChart>
                                                <Pie
                                                    data={this.state.reportByClientPM}
                                                    fill="#4D7CFE"
                                                    dataKey="value"
                                                    >
                                                    {
                                                        this.state.reportByClientPM.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                    }
                                                </Pie>
                                                <Tooltip/>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <span className="chart--custom-label">Project types</span>
                                    <div className="chart">
                                        <ResponsiveContainer>
                                            <PieChart>
                                                <Pie
                                                    data={this.state.reportByProjectType}
                                                    fill="#4D7CFE"
                                                    dataKey="value"
                                                    >
                                                    {
                                                        this.state.reportByProjectType.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                    }
                                                </Pie>
                                                <Tooltip/>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.reportData &&
                            <div className="detailed-chart">
                                <div className="export-link">
                                    <CSVLink data={csvData} filename={"missing_ce.csv"}>
                                        Export to CSV
                                    </CSVLink>
                                </div>

                                <div className="row">
                                    <div className="TableHead" style={{padding: 0}}>
                                        <div className="TableHead--options">

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-filter"></i>
                                                    Job number:
                                                </div>
                                                <div className="filter--dd">
                                                    <input type="text" id="filterByJN" onChange={(event) => (event.target.value.length >= 3 || event.target.value.length == 0) ? this.filtersChanged("filtersJN", event.target.value) : {}}></input>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-keyboard-o"></i>
                                                    Title:
                                                </div>
                                                <div className="filter--dd">
                                                    <input type="text" id="filterByTitle" onChange={(event) => (event.target.value.length >= 3 || event.target.value.length == 0) ? this.filtersChanged("filtersTitle", event.target.value) : {}}></input>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-money"></i>
                                                    Project status:
                                                </div>
                                                <div className="filter--dd">
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <div>
                                                        { this.state.filtersProjectStatus != -1 ? this.state.projectStatuses[this.state.filtersProjectStatus].name.substr(0, 10) + '...' : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                        this.state.projectStatuses.map((ps, index) => {
                                                            return (
                                                            <div key={'project-status-' + index} onClick={() => this.filtersChanged("filtersProjectStatus", index)}>
                                                                <DropdownItem>{ps.name}</DropdownItem>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-money"></i>
                                                    Client:
                                                </div>
                                                <div className="filter--dd">
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <div>
                                                        { this.state.filtersClientId != -1 ? this.state.clientsList[this.state.filtersClientId].name.substr(0, 10) + '...' : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                        this.state.clientsList.map((client, index) => {
                                                            return (
                                                            <div key={'client-' + index} onClick={() => this.filtersChanged("filtersClientId", index)}>
                                                                <DropdownItem>{client.name}</DropdownItem>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-registered"></i>
                                                    Brand:
                                                </div>
                                                <div className="filter--dd">
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <div>
                                                        { this.state.filtersBrandId != -1 ? this.state.brandsList[this.state.filtersBrandId].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                        this.state.brandsList.map((brand, index) => {
                                                            return (
                                                            <div key={'brand-' + index} onClick={() => this.filtersChanged("filtersBrandId", index)}>
                                                                <DropdownItem>{brand.name}</DropdownItem>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-male"></i>
                                                    Client PM:
                                                </div>
                                                <div className="filter--dd">
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <div>
                                                        { this.state.filtersClientPMId != -1 ? this.state.clientPMsList[this.state.filtersClientPMId].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                        this.state.clientPMsList.map((clientPM, index) => {
                                                            if(!clientPM.disabled)
                                                            return (
                                                            <div key={'client-pm-' + index} onClick={() => this.filtersChanged("filtersClientPMId", index)}>
                                                                <DropdownItem>{clientPM.name}</DropdownItem>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-magic"></i>
                                                    Type:
                                                </div>
                                                <div className="filter--dd">
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <div>
                                                        { this.state.filtersProjectTypeId != -1 ? this.state.projectTypesList[this.state.filtersProjectTypeId].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                        this.state.projectTypesList.map((type, index) => {
                                                            return (
                                                            <div key={'type-' + index} onClick={() => this.filtersChanged("filtersProjectTypeId", index)}>
                                                                <DropdownItem>{type.name}</DropdownItem>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <div className="filter">
                                                <div className="filter--label">
                                                    <i className="la la-male"></i>
                                                    PM:
                                                </div>
                                                <div className="filter--dd">
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <div>
                                                        { this.state.filtersIdgPMId === 1000 ? 'ALL' : (this.state.filtersIdgPMId != -1 ? this.state.idgPMsList[this.state.filtersIdgPMId].name : 'Select') }
                                                        <i className="la la-angle-down"></i>
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <div key={'idg-pm-all'} onClick={() => this.filtersChanged("filtersIdgPMId", 1000)}>
                                                            <DropdownItem>ALL</DropdownItem>
                                                        </div>
                                                        {
                                                        this.state.idgPMsList.map((pm, index) => {
                                                            if(!pm.disabled)
                                                            return (
                                                            <div key={'idg-pm-' + index} onClick={() => this.filtersChanged("filtersIdgPMId", index)}>
                                                                <DropdownItem>{pm.name}</DropdownItem>
                                                            </div>
                                                            )
                                                        })
                                                        }
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>

                                            <div className="filter filter--reset">
                                                <button className="btn--ts btn--ts-grey" onClick={() => this.clearFilters()}>Clear filters</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.reportData.map((ce, index) => {
                                    return (
                                        <TableRow
                                            key={'ce-details-' + index}
                                            tableCols={
                                                [
                                                    {
                                                        value: ce.project_job_number || '',
                                                        label: 'JN',
                                                        link: '/project/' + ce.project_id,
                                                        customCssClass: 'row-details row-head-mobile'
                                                    },
                                                    {
                                                        value: ce.project_title || '',
                                                        label: 'Project',
                                                        link: '/project/' + ce.project_id,
                                                        customCssClass: 'row-details row-head-mobile'
                                                    },
                                                    {
                                                        value: ce.project_status_name,
                                                        label: 'Project status'
                                                    },
                                                    {
                                                        value: ce.project_type_name,
                                                        label: 'Project type'
                                                    },
                                                    {
                                                        value: ce.brand_name,
                                                        label: 'Brand'
                                                    },
                                                    {
                                                        value: ce.client_pm_name,
                                                        label: 'Client\'s PM',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: ce.idg_pm_name,
                                                        label: 'PM',
                                                        customCssClass: 'row-details'
                                                    },
                                                    {
                                                        value: '#'+ce.created_at,
                                                        label: 'Created',
                                                        dateFormat: 'DD.MM.YYYY'
                                                    }
                                                ]
                                            }
                                        />
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

MissingCEs.contextType = AppContext;
export default withCookies(MissingCEs);
