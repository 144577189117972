import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import Masonry from 'react-masonry-component';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Autosuggest from 'react-autosuggest';
import {DropdownItem, DropdownMenu, DropdownToggle, FormGroup, UncontrolledDropdown} from 'reactstrap';
import AppContext from "../../context/index";
import userStore from "../../stores/user";

class Occupancy extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        let today = new Date();
        let yesterday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

        this.state = {
            reportData: [],
            reportByActivityType: [],
            developers: [],
            startDate: yesterday,
            endDate: today,
            hoursGraph: [],
            selectedUser: '',
            selectedUserId: -1,
            devsList: [],
            clientsList:  userStore.clients, //pm filters
            brandsList:  userStore.brands, //pm filters
            filtersClientId: -1,
            filtersBrandId: -1,
            usersSuggestions: [],
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);

    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();
        this.setState({
            devsList: userStore.getDEVs()
        })
    }

    onChangeDeveloper = (event, { newValue }) => {
        this.setState({
            selectedUser: newValue
        });
    };
    getUsersSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.devsList.filter(user =>
            user.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !user.disabled
        );
    };
    onUsersSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            usersSuggestions: this.getUsersSuggestions(value)
        });
    };
    onUsersSuggestionsClearRequested = () => {
        this.setState({
            usersSuggestions: []
        });
    };
    renderUsersSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onUsersSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedUserId: suggestion.id
        }, () => {
            this.getReportData();
        })
    }
    getUsersSuggestionValue = suggestion => suggestion.name;

    handleStartDate = (date) => {
        let endDate = this.state.endDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getReportData());
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getReportData());
    }

    filtersChanged = (filterId, filterValue, filterMinLength) => {
        this.setState(
            {
                [filterId]: filterValue,
                page: 1, // reset pagination when status filter is changed
                paginationKey: new Date().getTime() //unique key to reset pagination component
            },
            () => {
               this.getReportData();
            });
    }

    getReportData = () => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'reports-occupancy', {
            params: {
                userId: this.state.selectedUserId || -1,
                clientId: this.state.filtersClientId !== -1 && this.state.clientsList[this.state.filtersClientId] ? this.state.clientsList[this.state.filtersClientId].id : -1,
                brandId: this.state.filtersBrandId !== -1 && this.state.brandsList[this.state.filtersBrandId] ? this.state.brandsList[this.state.filtersBrandId].id : -1,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            console.log(res);

            let graphData = [];
            let activityData = [];
            if(res.data.data.ts) {
                for(let i in res.data.data.ts) {
                    let totalHours = res.data.data.ts[i].reduce((total, line) => total + parseInt(line.hours), 0)

                    if(totalHours != 0) {
                        graphData.push({
                            name: i,
                            hours: totalHours
                        })
                    }

                    res.data.data.ts[i].map(tsEntry => {
                        if(!tsEntry.disabled) {
                            activityData[tsEntry.name] = (activityData[tsEntry.name] || 0) + parseInt(tsEntry.hours)
                        }
                    })

                }
            }

            let graphActivity = [];
            for(let i in activityData) {
                graphActivity.push({
                    name: i,
                    value: activityData[i]
                })
            }

            this.setState({
                loading: false,
                reportData: res.data.data,
                reportByActivityType: graphActivity,
                developers: res.data.data.devs,
                hoursGraph: graphData
            });
        })
        .catch(err => {
            if(err && err.response && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    render() {

        const COLORS = ['#4D7CFE', '#FFAB2B', '#6DD230', '#FE4D97', '#2CE5F6', '#778ca2', '#ff0000', '#00ff6c', '#ff9c00', '#ff6000', '#00ffde', '#27c3af'];

        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            return(this.state.reportByActivityType[index].name + ': ' + this.state.reportByActivityType[index].value);
        };

        const developers = this.state.developers.map((dev, index) => {
            if(this.state.selectedUserId == -1 || (this.state.selectedUserId != -1 && this.state.selectedUserId == dev.id))
            return (
                <div key={'dev-' + index} className="col-md-4">
                    <div className="dev-outter">
                        <div>
                            <h3>{dev.name}</h3>
                            <div>
                                <ul className="projects-list">
                                    {
                                        dev.projects.map((project) => {
                                            return (<li key={'proj-'+project.id}><Link to={'/project/' + project.id}>{project.title}</Link></li>);
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
             );
        });

        const userInputProps = {
            placeholder: 'Developer\'s name',
            value: this.state.selectedUser,
            onChange: this.onChangeDeveloper
        };

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="74"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Reports::Occupancy"
                    />
                    <div className="ReportsPage">
                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Developer:
                                    </div>
                                    <div className="filter--dd xxl">
                                        <FormGroup>
                                            <Autosuggest
                                                suggestions={this.state.usersSuggestions}
                                                onSuggestionsFetchRequested={this.onUsersSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onUsersSuggestionsClearRequested}
                                                getSuggestionValue={this.getUsersSuggestionValue}
                                                renderSuggestion={this.renderUsersSuggestion}
                                                onSuggestionSelected={this.onUsersSuggestionSelected}
                                                inputProps={userInputProps}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-money"></i>
                                        Client:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filtersClientId != -1 ? this.state.clientsList[this.state.filtersClientId].name.substr(0, 10) + '...' : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.clientsList.map((client, index) => {
                                                            return (
                                                                <div key={'client-' + index} onClick={() => this.filtersChanged("filtersClientId", index)}>
                                                                    <DropdownItem>{client.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-registered"></i>
                                        Brand:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filtersBrandId != -1 ? this.state.brandsList[this.state.filtersBrandId].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.brandsList.map((brand, index) => {
                                                            return (
                                                                <div key={'brand-' + index} onClick={() => this.filtersChanged("filtersBrandId", index)}>
                                                                    <DropdownItem>{brand.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={(!this.state.reportByActivityType.length && !this.state.hoursGraph.length)?'responsive-chart no-data':'responsive-chart'}>
                            <div className="row">
                                {this.state.reportByActivityType.length?
                                    <div className="col-md-6">
                                        <div className="chart">
                                            <ResponsiveContainer>
                                                <PieChart>
                                                    <Pie
                                                        data={this.state.reportByActivityType}
                                                        fill="#4D7CFE"
                                                        dataKey="value"
                                                        label={renderCustomizedLabel}
                                                        >
                                                        {
                                                            this.state.reportByActivityType.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                        }
                                                    </Pie>
                                                    <Tooltip/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                : '' }
                                {this.state.hoursGraph.length?
                                    <div className="col-md-6">
                                        <div className="chart">
                                            <ResponsiveContainer>
                                            <BarChart data={this.state.hoursGraph}>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <XAxis dataKey="name"/>
                                                <YAxis/>
                                                <Tooltip/>
                                                <Bar dataKey="hours" fill="#4D7CFE" />
                                            </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                : ''}
                            </div>
                        </div>

                        <Masonry>
                            {developers}
                        </Masonry>
                    </div>
                </div>
            </div>
        );
    }
}
Occupancy.contextType = AppContext;
export default withCookies(Occupancy);
