import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Particles from 'react-particles-js';

class ParticlesComponent extends Component {
    render() {

        return (
            <div className="particles--component">
                    <Particles params={{
                        particles: {
                            number: {
                              value: 30,
                              density: {
                                enable: true,
                                value_area: 800
                              }
                            },
                            color: {
                              value: "#cccccc"
                            },
                            shape: {
                              type: "circle",
                              stroke: {
                                width: 0,
                                color: "#FFFFFF"
                              },
                              polygon: {
                                nb_sides: 5
                              }
                            },
                            opacity: {
                              value: 1,
                              random: false,
                              anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.1,
                                sync: false
                              }
                            },
                            size: {
                              value: 5,
                              random: true,
                              anim: {
                                enable: false,
                                speed: 80,
                                size_min: 0.1,
                                sync: false
                              }
                            },
                            line_linked: {
                              enable: true,
                              distance: 300,
                              color: "#cccccc",
                              opacity: 0.3,
                              width: 1
                            },
                            move: {
                              enable: true,
                              speed: 6,
                              direction: "none",
                              random: false,
                              straight: false,
                              out_mode: "out",
                              bounce: false,
                              attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                              }
                            }
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: false,
                                    mode: "repulse"
                                },
                                onclick: {
                                    enable: true,
                                    mode: "push"
                                },
                                resize: true
                            },
                            modes: {
                                grab: {
                                    distance: 800,
                                    line_linked: {
                                        opacity: 1
                                    }
                                },
                                bubble: {
                                    distance: 800,
                                    size: 80,
                                    duration: 2,
                                    opacity: 0.4,
                                    speed: 3
                                },
                                repulse: {
                                    distance: 400,
                                    duration: 0.4
                                },
                                push: {
                                    particles_nb: 4
                                },
                                remove: {
                                    particles_nb: 2
                                }
                            }
                        },
                        "retina_detect": true
                    }}/>
                </div>
        );
    }
}

export default withCookies(ParticlesComponent);