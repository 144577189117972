import React, { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from "react-router-dom";

class Logout extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { cookies } = this.props;
        cookies.remove('authToken');
    }

    render() {
        return (
            <Redirect to="/login"/>
        );
    }
}

export default withCookies(Logout);