import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import './UpdateProfile.scss';
import "@pathofdev/react-tag-input/build/index.css"
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import {validateRules} from '../../utils/utils';
import AppContext from "../../context/index";
import profilePictureM from "../../assets/img/profile-pic.png";
import profilePictureF from "../../assets/img/profile-pic-f.png";

class UpdateProfile extends Component {

    constructor(props) {
        super(props);
        const { cookies } = this.props;

        this.state = {
            avatarFileName: 'UPLOAD AVATAR',
            avatarFile: '',
            avatarPreview: null,
            currentAvatar: cookies.get('userAvatar') ? cookies.get('userAvatar') : (cookies.get('userSex') == 1 ? profilePictureM : profilePictureF),
            userSex: cookies.get('userSex'),
            src: null,
            projectTitle: '',
            redirectToLogin: false,
            loading: false,
            renderForm: true
        };
        this.onClose = this.onClose.bind(this)
        this.handleAvatarFileChange = this.handleAvatarFileChange.bind(this)
    }

    onClose() {
        this.setState({preview: null})
    }

    handleAvatarFileChange = e => {
        this.setState({
            avatarFileName: e.target.files[0].name,
            avatarFile: e.target.files[0],
            avatarPreview: URL.createObjectURL(e.target.files[0])
        })

    }

    clearInputFile = e => {
        this.setState({
            avatarFileName: 'UPLOAD AVATAR',
            avatarFile: '',
            avatarPreview: ''
        })
    }

    /* Update Profile */
    updateProfile = (data) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        let formData = new FormData();
        formData.append("old_password", data.old_password);
        formData.append("password", data.password);
        formData.append("password_confirmation", data.password_confirmation);

        if(this.state.avatarFile){
            formData.append("avatar", this.state.avatarFile);
        }

        axios.post(process.env.REACT_APP_API_URL + `profile/update`, formData, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken'),
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            const { cookies } = this.props;
            this.setState({
                loading: false,
            })
            this.props.closeEditModal(true);
            cookies.set('userAvatar', res.data.avatar);
            toast.success("Profile has been updated.");

            setTimeout(() => {
                window.location.reload();
            }, 1000);

        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
                // this.props.closeEditModal(true);
            }

            if(err.response.status === 422){
                if(err.response.data.errors && err.response.data.errors){
                    for(let key in err.response.data.errors){
                        toast.error(err.response.data.errors[key][0]);
                    }
                }
            }else{
                toast.error("An error occurred, please try again later.");
            }
        })
    }

    render() {
        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} wrapClassName="component--modal-update-profile">
                {this.state.renderForm &&
                    <Formik
                            initialValues = {{
                                old_password: '',
                                password: '',
                                password_confirmation: ''
                            }}
                            validate={values => {
                                let errors = {};
                                let rules = {}

                                if(values.password){
                                    rules['old_password'] = 'Required';
                                    rules['password_confirmation'] = 'Required';
                                }

                                Object.keys(rules).forEach(key => {
                                    let validation =  validateRules(values[key], rules[key]);

                                    if(validation){
                                        errors[key] = validation;
                                    }
                                });

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.updateProfile(values);
                                setSubmitting(false);
                            }}
                             >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                                /* and other goodies */
                            }) => (
                                <Form>
                                    <ModalHeader >Update profile</ModalHeader>
                                    <ModalBody className="new--project">
                                        <div id="current-avatar">
                                            <img align="center" src={this.state.avatarPreview ? this.state.avatarPreview : this.state.currentAvatar} />
                                        </div>
                                        <FormGroup className="file-input--container">
                                            <div className="file-input-placeholder">
                                                <i className="la la-cloud-upload"></i> {this.state.avatarFileName}
                                                <input
                                                    type="file"
                                                    name="projectFile"
                                                    id="project--file"
                                                    value={values.avatarFile}
                                                    onChange={this.handleAvatarFileChange}
                                                    className={errors.avatarFile ? 'form-control-file error' : 'form-control-file'}
                                                />
                                                <i className="btn btn--ts-grey btn-del" onClick={this.clearInputFile}>X</i>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <label htmlFor="old_password" className="text-left--ts">Current password</label>
                                            <input
                                                type="password"
                                                name="old_password"
                                                id="old_password"
                                                value={values.old_password}
                                                onChange={handleChange}
                                                className={errors.old_password ? 'form-control error' : 'form-control'}
                                                placeholder="Current password"
                                            />
                                            <ErrorMessage name="password" className="form-error" component="div" />
                                        </FormGroup>
                                        <FormGroup>
                                            <label htmlFor="password" className="text-left--ts">New password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                className={errors.password ? 'form-control error' : 'form-control'}
                                                placeholder="New password"
                                            />
                                            <ErrorMessage name="password" className="form-error" component="div" />
                                        </FormGroup>
                                        <FormGroup>
                                            <label htmlFor="password_confirmation" className="text-left--ts">Password confirmation</label>
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                id="password_confirmation"
                                                value={values.password_confirmation}
                                                onChange={handleChange}
                                                className={errors.password_confirmation ? 'form-control error' : 'form-control'}
                                                placeholder="Password confirmation"
                                            />
                                            <ErrorMessage name="password_confirmation" className="form-error" component="div" />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE'}</button>
                                        <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeEditModal}>CANCEL</button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
UpdateProfile.contextType = AppContext;
export default withCookies(UpdateProfile);
