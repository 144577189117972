import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import DatePicker from "react-datepicker";
import Autosuggest from 'react-autosuggest';
import { FormGroup } from 'reactstrap';
import AppContext from "../../context/index";

class LoadingByActivityType extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        let today = new Date();
        let yesterday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

        this.state = {
            reportData: [],
            reportDetails: [],
            startDate: yesterday,
            endDate: today,
            selectedAT: '',
            selectedATId: -1,
            atsList: [],
            atsSuggestions: [],
            capacity: 0,
            usersData: [],
            projectsData: []
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();
        this.getATsList();
    }

    getATsList = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'activity-types', {
            params: {
                page: 1,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                atsList: res.data.data.paginatedActivityTypes,
                loading: false
            });
        })
        .catch(err => {
            this.setState({
                loading: false
            });
        })
    }
    onChangeDeveloper = (event, { newValue }) => {
        this.setState({
            selectedAT: newValue
        });
    };
    getUsersSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.atsList.filter(at =>
            at.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !at.disabled
        );
    };
    onUsersSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            atsSuggestions: this.getUsersSuggestions(value)
        });
    };
    onUsersSuggestionsClearRequested = () => {
        this.setState({
            atsSuggestions: []
        });
    };
    renderUsersSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onUsersSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedATId: suggestion.id
        }, () => {
            this.getReportData();
        })
    }
    getUsersSuggestionValue = suggestion => suggestion.name;

    handleStartDate = (date) => {
        let endDate = this.state.filtersEndDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getReportData());
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getReportData());
    }

    getReportData = () => {
        const {cookies} = this.props;

        if(this.state.selectedATId && this.state.selectedATId != -1) {
            this.setState({
                loading: true
            });

            axios.get(process.env.REACT_APP_API_URL + 'reports-occupancy-activity-type', {
                params: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    activityTypeId: this.state.selectedATId
                },
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                console.log(res);

                let repData = [];
                let usersData = [];
                let projectsData = [];

                for(let i in res.data.data.ts) {
                    repData.push({
                        name: i,
                        hours: res.data.data.ts[i].reduce((total, line) => total + parseInt(line.hours), 0),
                        capacity: res.data.data.capacity
                    });

                    for(let j in res.data.data.ts[i]) {
                        usersData[res.data.data.ts[i][j].user_name] = (usersData[res.data.data.ts[i][j].user_name] || 0) + parseFloat(res.data.data.ts[i][j].hours);
                        projectsData[res.data.data.ts[i][j].project_title] = (projectsData[res.data.data.ts[i][j].project_title] || 0) + parseFloat(res.data.data.ts[i][j].hours);
                    }
                }

                let finalUserData = [];
                for(let i in usersData) {
                    finalUserData.push({
                        user_name: i,
                        hours: usersData[i]
                    })
                }

                let finalProjectsData = [];
                for(let i in projectsData) {
                    finalProjectsData.push({
                        project_title: i,
                        hours: projectsData[i]
                    })
                }

                this.setState({
                    loading: false,
                    reportData: repData,
                    capacity: res.data.data.capacity,
                    usersData: finalUserData,
                    projectsData: finalProjectsData
                });
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
        }
    }

    render() {

        const userInputProps = {
            placeholder: 'Activity type',
            value: this.state.selectedAT,
            onChange: this.onChangeDeveloper
        };

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="76"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Reports::Loading by activity type" 
                    />
                    <div className="ReportsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Activity:
                                    </div>
                                    <div className="filter--dd xxl">
                                        <FormGroup>
                                            <Autosuggest
                                                suggestions={this.state.atsSuggestions}
                                                onSuggestionsFetchRequested={this.onUsersSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onUsersSuggestionsClearRequested}
                                                getSuggestionValue={this.getUsersSuggestionValue}
                                                renderSuggestion={this.renderUsersSuggestion}
                                                onSuggestionSelected={this.onUsersSuggestionSelected}
                                                inputProps={userInputProps}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDate}
                                                minDate={this.state.startDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.reportData.length ? 
                            <div className="responsive-chart">
                                <ResponsiveContainer>
                                    <AreaChart data={this.state.reportData}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#4d7cfe" stopOpacity={0.9}/>
                                            <stop offset="100%" stopColor="#4d7cfe" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <defs>
                                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#252631" stopOpacity={0.9}/>
                                            <stop offset="100%" stopColor="#252631" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Area type='monotone' dataKey='hours' stackId="1" stroke='#252631' fill="url(#colorPv)" />
                                        <Area type='monotone' dataKey='capacity' stackId="2" stroke='#4d7cfe' fill="url(#colorUv)"  />
                                    </AreaChart>
                                </ResponsiveContainer> 
                            </div>
                        : ''}

                        <div className="row">
                            <div className="col-6">
                                {this.state.usersData.length ?
                                    <div className="detailed-chart">
                                        {this.state.usersData.map((po, index) => {
                                            return (
                                                <TableRow
                                                    key={'user-details-' + index}
                                                    tableCols={
                                                        [
                                                            {
                                                                value: po.user_name,
                                                                label: 'User',
                                                                customCssClass: 'row-details'
                                                            },
                                                            {
                                                                value: po.hours.toFixed(2),
                                                                label: this.state.selectedAT + ' hours'
                                                            }
                                                        ]
                                                    }
                                                /> 
                                            )
                                        })}
                                    </div>
                                : ''}
                            </div>
                            <div className="col-6">
                                {this.state.projectsData.length ?
                                    <div className="detailed-chart">
                                        {this.state.projectsData.map((po, index) => {
                                            return (
                                                <TableRow
                                                    key={'project-details-' + index}
                                                    tableCols={
                                                        [
                                                            {
                                                                value: po.project_title,
                                                                label: 'Project',
                                                                link: '/project/' + po.id,
                                                                mobileFullWidth: true,
                                                                customCssClass: 'row-details'
                                                            },
                                                            {
                                                                value: po.hours.toFixed(2),
                                                                label: this.state.selectedAT + ' hours'
                                                            }
                                                        ]
                                                    }
                                                /> 
                                            )
                                        })}
                                    </div>
                                : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
LoadingByActivityType.contextType = AppContext;
export default withCookies(LoadingByActivityType);
