import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';

class AddExpensesCategory extends Component {

    render() {
        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-category">
                    <Formik
                        initialValues = {{ catId: this.props.catId, catTitle: this.props.catTitle }}
                        validate={values => {
                            let errors = {};
                            if (!values.catTitle) {
                                errors.catTitle = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.saveCategory(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>Add new category</ModalHeader>
                                <ModalBody className="new--category">
                                    <input type="hidden" name="catId" value={values.catId}/>
                                    <FormGroup>
                                        <label htmlFor="category--name" className="text-left--ts">Title</label>
                                        <input
                                            type="text"
                                            name="catTitle"
                                            id="category-title"
                                            value={values.catTitle}
                                            onChange={handleChange}
                                            className={errors.catTitle ? 'form-control error' : 'form-control'}
                                            placeholder="Title"
                                        />
                                        <ErrorMessage name="catTitle" className="form-error" component="div" />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE CATEGORY'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddExpensesCategory);
