import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { toast } from 'react-toastify';
import { createAction } from '../../utils/utils';
import userStore from "../../stores/user";

class AddResource extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resourceId: (typeof this.props.resourceEdit.id !== "undefined") ? this.props.resourceEdit.id : '',
            selectedUser: (typeof this.props.resourceEdit.user_name !== "undefined") ? this.props.resourceEdit.user_name : '',
            selectedActivity: (typeof this.props.resourceEdit.activity !== "undefined") ? this.props.resourceEdit.activity : '',
            selectedUserId: (typeof this.props.resourceEdit.user_id !== "undefined") ? this.props.resourceEdit.user_id : -1,
            selectedActivityId: (typeof this.props.resourceEdit.activity_type_id !== "undefined")  ? this.props.resourceEdit.activity_type_id : -1,
            maxHours: (typeof this.props.resourceEdit.max_hours !== "undefined")  ? this.props.resourceEdit.max_hours : 0,
            devsList: [],
            activitiesList: [],
            usersSuggestions: [],
            activitiesSuggestions: [],
        };
    }

    componentDidMount() {
        this.setState({
            devsList: userStore.getDEVs()
        });
        if (this.state.selectedUserId) {
            this.getActivities();
        }
    }

    getActivities = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'activity-types-by-user/' + this.state.selectedUserId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {

            this.setState({
                activitiesList: res.data.data,
                loading: false
            });
        })
        .catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    onChangeDeveloper = (event, { newValue }) => {
        this.setState({
            selectedUser: newValue
        });
    };
    onChangeActivity = (event, { newValue }) => {
        this.setState({
            selectedActivity: newValue
        });
    };

    getUsersSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.devsList.filter(user =>
            user.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 && !user.disabled
        );
    };
    getActivitiesSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.activitiesList.filter(act =>
            act.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1  && !act.disabled
        );
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onUsersSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            usersSuggestions: this.getUsersSuggestions(value)
        });
    };
    onActivitiesSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            activitiesSuggestions: this.getActivitiesSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onUsersSuggestionsClearRequested = () => {
        this.setState({
            usersSuggestions: []
        });
    };
    onActivitiesSuggestionsClearRequested = () => {
        this.setState({
            activitiesSuggestions: []
        });
    };

    renderUsersSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    renderActivitiesSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    onUsersSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedUserId: suggestion.id
        }, () => {
            this.getActivities();
        })
    }
    onActivitiesSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedActivityId: suggestion.id
        })
    }

    getUsersSuggestionValue = suggestion => suggestion.name;
    getActivitiesSuggestionValue = suggestion => suggestion.name;

    saveResource(values) {
        const { cookies } = this.props;
        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + 'project-resources',
            data: {
                project_id: values.projectId,
                id: values.resourceId,
                user_id: this.state.selectedUserId,
                activity_type_id: this.state.selectedActivityId,
                max_hours: values.maxHours
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.props.closeResourceModal(true);
            toast.success("Project's resource has been saved.");

            createAction(3, 'projects', values.projectId, cookies.get('authToken'), this.state.selectedUserId);
        })
    }

    render() {
        const userInputProps = {
            placeholder: 'Developer\'s name',
            value: this.state.selectedUser,
            onChange: this.onChangeDeveloper
        };
        const activityInputProps = {
            placeholder: 'Activity type',
            value: this.state.selectedActivity,
            onChange: this.onChangeActivity,
            disabled: this.state.selectedUser < 1
        };

        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                      initialValues = {{ projectId: this.props.projectId, maxHours: this.state.maxHours, resourceId: this.state.resourceId }}
                        validate={values => {
                            let errors = {};
                            if (this.state.selectedUserId === -1) {
                                errors.userName = 'Required';
                            }
                            if (this.state.selectedActivityId === -1) {
                                errors.activityName = 'Required';
                            }
                            if(this.state.selectedActivityId !== 83){
                                if (!values.maxHours || !/^[0-9.]*[0-9]$/.test(values.maxHours) || (values.maxHours < 0) || (values.maxHours > 1000000)) {
                                    errors.maxHours = 'Required';
                                }
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.saveResource(values);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>Add new resource</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="projectId" value={values.projectId}/>
                                    <input type="hidden" name="resourceId" value={values.resourceId}/>

                                    <FormGroup className={errors.userName ? 'error-container': ''}>
                                        <label htmlFor="te--userId" className="text-left--ts">Developer</label>
                                        <Autosuggest
                                            suggestions={this.state.usersSuggestions}
                                            onSuggestionsFetchRequested={this.onUsersSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onUsersSuggestionsClearRequested}
                                            getSuggestionValue={this.getUsersSuggestionValue}
                                            renderSuggestion={this.renderUsersSuggestion}
                                            onSuggestionSelected={this.onUsersSuggestionSelected}
                                            inputProps={userInputProps}
                                        />
                                        {errors.userName &&
                                            <div className="form-error">{errors.userName}</div>
                                        }
                                    </FormGroup>

                                    <FormGroup className={errors.activityName ? 'error-container': ''}>
                                        <label htmlFor="te--activityId" className="text-left--ts">Activity</label>
                                        <Autosuggest
                                            suggestions={this.state.activitiesSuggestions}
                                            onSuggestionsFetchRequested={this.onActivitiesSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onActivitiesSuggestionsClearRequested}
                                            getSuggestionValue={this.getActivitiesSuggestionValue}
                                            renderSuggestion={this.renderActivitiesSuggestion}
                                            onSuggestionSelected={this.onActivitiesSuggestionSelected}
                                            inputProps={activityInputProps}
                                        />
                                        {errors.activityName &&
                                            <div className="form-error">{errors.activityName}</div>
                                        }
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="resource--hours" className="text-left--ts">Hours</label>
                                        <input
                                            type="text"
                                            name="maxHours"
                                            id="resource--hours"
                                            value={values.maxHours}
                                            onChange={handleChange}
                                            className={errors.maxHours ? 'form-control error' : 'form-control'}
                                            placeholder="Hours"
                                        />
                                        <ErrorMessage name="maxHours" className="form-error" component="div" />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE RESOURCE'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeResourceModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddResource);
