import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Col, Row } from 'reactstrap';
import './AddCostEstimate.scss';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import TableRow from "../../components/tableRow/TableRow";
import confirm from 'reactstrap-confirm';
import AppContext from "../../context/index";
import Select from "react-select";
import { userStore } from "../../stores/user";

class AddCostEstimate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            statusesList: [],
            ceItemTypes: [],
            activityTypes: [],
            clientUnitsList: [],
            jobNumbersList: [],
            clientUnitsSuggestions: [],
            selectedClientUnit: '',
            selectedClientUnitId: -1,
            selectedJobNumbers: [],
            selectedJobNumbersIds: [],
            idgUnitsList: [],
            renderForm: false,
            loading: false,
            ceEditStatusId: 3,
            ceEditClientUnitId: -1,
            ceEditIDGUnitId: -1,
            ceLines: [],
            ceError: 0,
            ceErrorMessage: '',
            ceLineEditId: -1,
            ceLineDetails: '',
            ceLineTypeId: -1,
            ceLineActivityTypeId: -1,
            ceLineUnit: '',
            ceLineQuantity: 0,
            ceLineUnitCost: '',
            ceLineErrorDetails: 0,
            ceLineErrorTypeId: 0,
            ceLineErrorActivityTypeId: 0,
            ceLineErrorQuantity: 0,
            ceErrorClientUnitId: false,
            ceErrorJobNumbers: false
        };
    }

    onChangeClientUnit = (event, { newValue }) => {
        this.setState({
            selectedClientUnit: newValue
        });
    };

    getClientUnitsSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.clientUnitsList.filter(cu =>
            cu.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !cu.disabled
        );
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onClientUnitsSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            clientUnitsSuggestions: this.getClientUnitsSuggestions(value)
        });
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onClientUnitsSuggestionsClearRequested = () => {
        this.setState({
            clientUnitsSuggestions: []
        });
    };
    renderClientUnitsSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onClientUnitsSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedClientUnitId: suggestion.id
        });
    }
    getClientUnitsSuggestionValue = suggestion => suggestion.name;

    componentDidMount = () => {
        this.getStatuses();
        this.getCEItemTypes();
        this.getCEActivityTypes();
        this.getClientUnits();
        this.getIdgUnits();
        this.getJobNumbers();

        if(this.props.ceId && this.props.ceId != -1) {
            //get details, it's an update
            this.getCEDetails(this.props.ceId);
        }
        else {
            this.setState({
                renderForm: true
            })
        }
    }

    getCEDetails = (ceId) => {
        const { cookies } = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimate/' + ceId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                ceEditId: res.data.data.id,
                ceEditStatusId: res.data.data.status_id,
                ceEditClientUnitId: res.data.data.client_unit_id,
                ceEditIDGUnitId: res.data.data.infodesign_unit_id,
                ceLines: res.data.data.CostEstimateLines,
                selectedClientUnit: res.data.data.client_unit_name,
                selectedClientUnitId: res.data.data.client_unit_id,
                selectedJobNumbers: res.data.data.job_numbers.map((item) => { return {
                    label: item.name,
                    value: item.id
                } } ),
                selectedJobNumbersIds: res.data.data.job_numbers.map((item) => { return item.id}),
                renderForm: true,
                loading: false
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getCEActivityTypes = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'activity-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                activityTypes: res.data.data.paginatedActivityTypes,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getIdgUnits = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'idg-units', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                idgUnitsList: res.data.data.paginatedInfodesignUnits,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getClientUnits = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'client-units', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                clientUnitsList: res.data.data.paginatedClientUnits,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getStatuses = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimate-statuses', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                statusesList: res.data.data,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getCEItemTypes = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimate-item-types', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                ceItemTypes: res.data.data,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    addLine = () => {
        this.setState({
            ceLineErrorDetails: this.state.ceLineDetails.trim() ? 0 : 1,
            ceLineErrorTypeId: (this.state.ceLineTypeId || (this.state.ceLineTypeId !== -1)) ? 0 : 1,
            ceLineErrorActivityTypeId: (this.state.ceLineActivityTypeId || (this.state.ceLineActivityTypeId !== -1) && (this.state.ceLineTypeId == 3)) ? 0 : 1,
            ceLineErrorQuantity: parseFloat(this.state.ceLineQuantity) ? 0 : 1
        },() => {
            if(!this.state.ceLineErrorDetails && !this.state.ceLineErrorTypeId && !this.state.ceLineErrorActivityTypeId && !this.state.ceLineErrorQuantity){
                let auxCELines = this.state.ceLines;
                if(this.state.ceLineEditId != -1) {
                    auxCELines.forEach((line, key) => {
                        if(line.id == this.state.ceLineEditId) {
                            auxCELines.splice(key, 1)
                            this.setState({
                                ceLineEditId: -1
                            });
                        }
                    })
                }

                auxCELines.push({
                    id: this.state.ceLines.length,
                    details: this.state.ceLineDetails,
                    item_type_id: this.state.ceLineTypeId,
                    activity_type_id: this.state.ceLineActivityTypeId,
                    unit: this.state.ceLineUnit,
                    ...(this.state.ceLineTypeId == 3 ? { discount: this.state.ceLineQuantity } : { quantity: this.state.ceLineQuantity }),
                    unit_cost: this.state.ceLineUnitCost
                })
                this.setState({
                    ceLines: auxCELines,
                    ceError: 0,
                    ceErrorMessage: '',
                    ceLineDetails: '',
                    ceLineTypeId: -1,
                    ceLineActivityTypeId: -1,
                    ceLineQuantity: 0,
                    ceLineUnitCost: '',
                    ceLineErrorDetails: 0,
                    ceLineErrorTypeId: 0,
                    ceLineErrorActivityTypeId: 0,
                    ceLineErrorQuantity: 0,
                })
            }
        });

        // if(!this.state.ceLineDetails.trim()) {
        //     this.setState({
        //         ceLineErrorDetails: 1
        //     })
        // }
        // else
        // if(!this.state.ceLineTypeId || this.state.ceLineTypeId === -1) {
        //     this.setState({
        //         ceLineErrorTypeId: 1
        //     })
        // }
        // else
        // if(!this.state.ceLineActivityTypeId || this.state.ceLineActivityTypeId === -1 && this.state.ceLineTypeId != 3) {
        //     this.setState({
        //         ceLineErrorActivityTypeId: 1
        //     })
        // }
        // else
        // if(!parseInt(this.state.ceLineQuantity)) {
        //     this.setState({
        //         ceLineErrorQuantity: 1
        //     })
        // }
        // else {
        //     //save line
        //
        //
        // }
    }

    removeCELine = async (lineId) => {
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            let auxCELines = this.state.ceLines;
            auxCELines.forEach((line, key) => {
                if(line.id == lineId) {
                    auxCELines.splice(key, 1)
                }
            })

            this.setState({
                ceLine: auxCELines
            })
        }
    }
    editCELine = async (lineId) => {
        this.setState({
            ceLineEditId: lineId
        })
        this.state.ceLines.forEach((line, key) => {
            if(line.id == lineId) {
                this.setState({
                    ceLineDetails: this.state.ceLines[key].details,
                    ceLineTypeId: this.state.ceLines[key].item_type_id,
                    ceLineActivityTypeId: this.state.ceLines[key].activity_type_id,
                    ceLineQuantity: this.state.ceLines[key].quantity,
                    ceLineUnitCost: this.state.ceLines[key].unit_cost
                })
            }
        });
    }

    saveCE = (values) => {
        if(!this.state.selectedClientUnitId || this.state.selectedClientUnitId === -1) {
            this.setState({
                ceErrorClientUnitId: true
            })
            return
        }

        this.setState({
            loading: true
        })

        let requestMethod = "POST";
        if (values.ceId) {
            requestMethod = "PUT";
        }
        const { cookies } = this.props;

        let totalVal = this.state.ceLines.filter(line => line.item_type_id == 1 || line.item_type_id == 2)
                        .reduce((total, line) => total + line.quantity * line.unit_cost, 0)

        let totalDiscount = this.state.ceLines.filter(line => line.item_type_id == 3)
                            .reduce((total, line) => total + line.discount, 0)

        if(totalDiscount != 0) {
            totalVal = totalVal - ((totalDiscount / 100) * totalVal)
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'cost-estimates' + (values.ceId ? ('/' + values.ceId) : ''),
            data: {
                project_id: this.props.projectId,
                value: totalVal,
                status_id: values.ceStatusId,
                job_numbers: this.state.selectedJobNumbersIds,
                client_unit_id: this.state.selectedClientUnitId,
                infodesign_unit_id: values.ceIdgUnitId
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            let ceId = res.data.data.id;
            if(ceId) {
                //save lines
                let auxLines = [];
                this.state.ceLines.forEach(line => {
                    auxLines.push({
                        details: line.details,
                        cost_estimate_id: ceId,
                        item_type_id: line.item_type_id,
                        ...(line.activity_type_id != -1 ? { activity_type_id: line.activity_type_id } : {}),
                        ...(line.item_type_id == 3 ? { discount: line.discount } : { quantity: line.quantity }),
                        unit_cost: line.unit_cost
                    })
                })
                axios({
                    method: "POST",
                    url: process.env.REACT_APP_API_URL + 'cost-estimate-lines/' + ceId,
                    data: {
                        lines: auxLines
                    },
                    headers: {
                        'Authorization': 'Bearer ' + cookies.get('authToken')
                    }
                })
                .then(res => {
                    this.setState({
                        loading: false
                    })
                    this.props.closeEditModal(true);
                    toast.success("Cost estimate has been saved.");
                })
                .catch(err => {
                    if(err && err.response && err.response.status && err.response.status === 401){
                        //redirect to login
                        this.context.redirectToLogin();
                    }
                    this.setState({
                        loading: false
                    })
                    toast.error("An error occured, please try again later.");
                })
            }
        })
        .catch(err => {
            if(err && err.response && err.response.status && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
            this.setState({
                loading: false
            })
            toast.error("An error occured, please try again later.");
        })
    }

    updateInputValue(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        }, () => {
            // console.log();
            if(this.state.ceLineActivityTypeId && this.state.ceLineTypeId == 1) {
                let selectedActivity = this.state.activityTypes.filter(item => {
                    return item.id == this.state.ceLineActivityTypeId;
                })
                if(selectedActivity[0] && selectedActivity[0].rate){
                    this.setState({
                        ceLineUnitCost: selectedActivity[0].rate
                    })
                }
            }
        });
    }

    getJobNumbers(){
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'job-numbers/load-by-project/' + this.props.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
            .then(res => {

                if(res.data){
                    let jobNumbers = [];

                    for(let key in res.data){
                        jobNumbers.push({
                            label: res.data[key].name,
                            value: res.data[key].id
                        });
                    }

                    this.setState({
                        jobNumbersList: jobNumbers,
                        loading: false
                    });
                }
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }

    jobNumbersChange(jobNumbers){
        let jobNumbersValues = jobNumbers.map((item) => { return item.value });

        this.setState({
            selectedJobNumbersIds: jobNumbersValues,
            selectedJobNumbers: jobNumbers,
            ceErrorJobNumbers: !jobNumbers.length
        });
    }

    render() {

        const clientUnitInputProps = {
            placeholder: 'Client unit\'s name',
            value: this.state.selectedClientUnit,
            onChange: this.onChangeClientUnit
        };

        const units = [
            '',
            'hour',
            'piece',
            'percent'
        ];

        const getTypeName = (typeId) => {
            let itemName = '';
            this.state.ceItemTypes.forEach(item => {
                if (item.id == typeId) {
                    itemName = item.name
                }
            })
            return itemName
        }

        const getActivityTypeName = (typeId) => {
            let itemName = '';
            this.state.activityTypes.forEach(item => {
                if (item.id == typeId) {
                    itemName = item.name
                }
            })
            return itemName
        }

        const calculateDiscount = (discount) => {
            let total = 0;
            this.state.ceLines.forEach(line => {
                total += (line.item_type_id == 1 || line.item_type_id == 2) ? line.quantity * line.unit_cost : 0
            })
            return (-1 * (discount / 100) * total).toFixed(2);
        }

        const styleSelect = {
            control: (base, state) => ({
                ...base,
                minWidth: '150px',
                height: '50px',
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
                border: this.state.ceErrorJobNumbers ? '1px solid red' : (userStore.me.settings.theme === 'theme-dark' ? '1px solid #2a2d2e !important' : '1px solid #d3d3d3 !important'),
                boxShadow: 'none'
            }),
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    color: userStore.me.settings.theme === 'theme-dark' ? '#778ca2' : '#000',
                    marginLeft: '5px'
                }
            },
            option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                color: userStore.me.settings.theme === 'theme-dark'  ? (state.isFocused ? '#FFF' : '#778ca2') : '#000',
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
            }),
            singleValue: (provided, state) => ({
                ...provided,
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF',
            }),
            menuList: (provided, state) => ({
                ...provided,
                background: userStore.me.settings.theme === 'theme-dark' ? '#000' : '#FFF'
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '50px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            })
        };

        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} wrapClassName="component--modal-new-ce">
                {this.state.renderForm &&
                    
                    <Formik
                            initialValues = {{ 
                                ceId: this.state.ceEditId,
                                ceStatusId: this.state.ceEditStatusId || -1,
                                ceClientUnitId: this.state.ceEditClientUnitId || -1,
                                ceIdgUnitId: this.state.ceEditIDGUnitId || -1,
                                ceLineItemTypeId: -1,
                                ceLineActivityTypeId: -1,
                                ceLine: {
                                    details: ''
                                }
                            }}
                            validate={values => {
                                // console.log(values);
                                let errors = {};
                                if (!values.ceStatusId || values.ceStatusId === -1) {
                                    errors.ceStatusId = 'Required';
                                }
                                if (!values.ceIdgUnitId || values.ceIdgUnitId === -1) {
                                    errors.ceIdgUnitId = 'Required';
                                }

                                if(!this.state.selectedJobNumbersIds.length){
                                    this.state.ceErrorJobNumbers = true;
                                    errors.ceErrorJobNumbers = 'Required';
                                }

                                if(this.state.selectedClientUnitId == -1){
                                    this.state.ceErrorClientUnitId = true;
                                    errors.ceErrorClientUnitId = 'Required';
                                }

                                if (!this.state.ceLines.length) {
                                    this.setState({
                                        ceError: 1,
                                        ceErrorMessage: 'Please add at least one line before saving the cost estimate.'
                                    });
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.saveCE(values);
                                setSubmitting(false);
                            }}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <Form>
                                    <input type="hidden" name="ceId" value={values.ceId}/>
                                    <ModalHeader toggle={this.toggle}>Cost estimate details</ModalHeader>
                                    <ModalBody className="new--ce">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <FormGroup>
                                                    <label htmlFor="ce--status" className="text-left--ts">Status</label>
                                                    <select
                                                        name="ceStatusId"
                                                        id="ce--status"
                                                        value={values.ceStatusId}
                                                        onChange={handleChange}
                                                        className={errors.ceStatusId ? 'form-control error' : 'form-control'}
                                                    >
                                                        {
                                                            this.state.statusesList.map(status => {
                                                                return (
                                                                    <option key={'status-' + status.id} value={status.id}>{status.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <ErrorMessage name="ceStatusId" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup className={this.state.ceErrorClientUnitId ? 'error-container': ''}>
                                                    <label htmlFor="cd--clientUnitId" className="text-left--ts">Client unit </label>
                                                    <Autosuggest
                                                        suggestions={this.state.clientUnitsSuggestions}
                                                        onSuggestionsFetchRequested={this.onClientUnitsSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onClientUnitsSuggestionsClearRequested}
                                                        getSuggestionValue={this.getClientUnitsSuggestionValue}
                                                        renderSuggestion={this.renderClientUnitsSuggestion}
                                                        onSuggestionSelected={this.onClientUnitsSuggestionSelected}
                                                        inputProps={clientUnitInputProps}
                                                    />
                                                    {this.state.ceErrorClientUnitId &&
                                                        <div className="form-error">Required</div>
                                                    }
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup>
                                                    <label htmlFor="ce--idgunit" className="text-left--ts">IDG Unit</label>
                                                    <select
                                                        name="ceIdgUnitId"
                                                        id="ce--idgunit"
                                                        value={values.ceIdgUnitId}
                                                        onChange={handleChange}
                                                        className={errors.ceIdgUnitId ? 'form-control error' : 'form-control'}
                                                    >
                                                        <option>Select</option>
                                                        {
                                                            this.state.idgUnitsList.map(idg => {
                                                                return (
                                                                    <option key={'idg-' + idg.id} value={idg.id}>{idg.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <ErrorMessage name="ceIdgUnitId" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup>
                                                    <label htmlFor="cd--clientUnitId" className="text-left--ts">Job numbers</label>
                                                    <Select
                                                        styles={styleSelect}
                                                        value={this.state.selectedJobNumbers}
                                                        options={this.state.jobNumbersList}
                                                        onChange={(selectedOptions) => {
                                                            this.jobNumbersChange(selectedOptions)
                                                        }}
                                                        isMulti
                                                    />
                                                    {this.state.ceErrorJobNumbers &&
                                                        <div className="form-error">Required</div>
                                                    }
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="section-title">Cost estimate lines</h3>
                                            </div>
                                        </div>
                                        
                                        <div className="ce-lines">
                                            {this.state.ceLines.map((ceLine, index) => {
                                                return(
                                                    <TableRow
                                                        key={'ce-' + index}
                                                        tableCols={
                                                            [
                                                                {
                                                                    value: ceLine.details,
                                                                    label: 'Details',
                                                                    customCssClass: 'row-details'
                                                                },
                                                                {
                                                                    value: getTypeName(ceLine.item_type_id),
                                                                    label: 'Type'
                                                                },
                                                                {
                                                                    value: getActivityTypeName(ceLine.activity_type_id),
                                                                    label: 'Activity type'
                                                                },
                                                                {
                                                                    value: units[ceLine.item_type_id],
                                                                    label: 'Unit'
                                                                },
                                                                {
                                                                    value: ceLine.quantity || ceLine.discount,
                                                                    label: 'Quantity'
                                                                },
                                                                {
                                                                    value: ceLine.unit_cost,
                                                                    label: 'Unit cost'
                                                                },
                                                                {
                                                                    value: ceLine.item_type_id == 3 ? calculateDiscount(ceLine.discount) : ceLine.quantity * ceLine.unit_cost,
                                                                    label: 'Value',
                                                                    customCssClass: ceLine.item_type_id == 3 ? 'danger' : ''
                                                                },
                                                                {
                                                                    ddOptions: [
                                                                        {
                                                                            value: 'Remove item',
                                                                            action: {
                                                                                name: 'removeCELine',
                                                                                params: ceLine.id
                                                                            }
                                                                        },
                                                                        {
                                                                            value: 'Edit',
                                                                            action: {
                                                                                name: 'editCELine',
                                                                                params: ceLine.id
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                        removeCELine={this.removeCELine}
                                                        editCELine={this.editCELine}
                                                    />
                                                )
                                            })}

                                            
                                            <div className="ce-line-form">
                                                <div className="row">
                                                    <div className="col-6 col-md-2">
                                                        <FormGroup>
                                                            <label htmlFor="ce-line--details" className="text-left--ts">Details</label>
                                                            <input
                                                                type="text"
                                                                name="ceLineDetails"
                                                                id="ce-line--details"
                                                                value={this.state.ceLineDetails}
                                                                onChange={evt => this.updateInputValue(evt)}
                                                                className={this.state.ceLineErrorDetails ? 'form-control error' : 'form-control'}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-6 col-md-1">
                                                        <FormGroup>
                                                            <label htmlFor="ce-line--type" className="text-left--ts">Type</label>
                                                            <select
                                                                name="ceLineTypeId"
                                                                id="ce-line--type"
                                                                value={this.state.ceLineTypeId}
                                                                onChange={evt => this.updateInputValue(evt)}
                                                                className={this.state.ceLineErrorTypeId ? 'form-control error' : 'form-control'}
                                                            >
                                                                <option>Select</option>
                                                                {
                                                                    this.state.ceItemTypes.map(type => {
                                                                        return (
                                                                            <option key={'ce-type-' + type.id} value={type.id}>{type.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-6 col-md-2">
                                                        {this.state.ceLineTypeId != 3 &&
                                                            <FormGroup>
                                                                <label htmlFor="ce-line--at" className="text-left--ts">Activity</label>
                                                                <select
                                                                    name="ceLineActivityTypeId"
                                                                    id="ce-line--at"
                                                                    value={this.state.ceLineActivityTypeId}
                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                    className={this.state.ceLineErrorActivityTypeId ? 'form-control error' : 'form-control'}
                                                                >
                                                                    <option>Select</option>
                                                                    {
                                                                        this.state.activityTypes.map(at => {
                                                                            return (
                                                                                !at.disabled ?
                                                                                    <option key={'at-' + at.id} value={at.id}>{at.name}</option>
                                                                                :''
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </FormGroup>
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        <FormGroup>
                                                            <label htmlFor="ce-line--unit" className="text-left--ts">Unit</label>
                                                            <input
                                                                type="text"
                                                                name="ceLineUnit"
                                                                id="ce-line--unit"
                                                                readOnly
                                                                value={this.state.ceLineUnit}
                                                                placeholder={units[this.state.ceLineTypeId]}
                                                                onChange={evt => this.updateInputValue(evt)}
                                                                className={errors.ceLineUnit ? 'form-control error' : 'form-control'}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col">
                                                        <FormGroup>
                                                            <label htmlFor="ce-line--quantity" className="text-left--ts">Quantity</label>
                                                            <input
                                                                type="number"
                                                                name="ceLineQuantity"
                                                                id="ce-line--quantity"
                                                                value={this.state.ceLineQuantity || ''}
                                                                onChange={evt => this.updateInputValue(evt)}
                                                                className={this.state.ceLineErrorQuantity ? 'form-control error' : 'form-control'}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col">
                                                        {this.state.ceLineTypeId != 3 &&
                                                            <FormGroup>
                                                                <label htmlFor="ce-line--unitcost" className="text-left--ts">Unit cost</label>
                                                                <input
                                                                    type="text"
                                                                    name="ceLineUnitCost"
                                                                    id="ce-line--unitcost"
                                                                    readOnly = {this.state.ceLineTypeId == 1}
                                                                    value={this.state.ceLineUnitCost}
                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                    className={errors.ceLineUnitCost ? 'form-control error' : 'form-control'}
                                                                />
                                                            </FormGroup>
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        {this.state.ceLineTypeId != 3 &&
                                                            <FormGroup>
                                                                <label htmlFor="ce-line--value" className="text-left--ts">Value</label>
                                                                <input
                                                                    type="text"
                                                                    name="ceLineValue"
                                                                    id="ce-line--value"
                                                                    readOnly
                                                                    value={(this.state.ceLineUnitCost * this.state.ceLineQuantity) || 0}
                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                    className={errors.ceLineValue ? 'form-control error' : 'form-control'}
                                                                />
                                                            </FormGroup>
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        <label>&nbsp; </label>
                                                        <button type="button" onClick={() => this.addLine()} disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                                    
                                        </div>
                                            
                                        <div className="row">
                                            {this.state.ceError ? 
                                                <div className="form-error general-error">{this.state.ceErrorMessage}</div>
                                            : ''}
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        {this.state.ceLines.length > 0 ?
                                            <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE COST ESTIMATE'}</button>
                                        : ''}
                                        <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeEditModal}>CANCEL</button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
AddCostEstimate.contextType = AppContext;
export default withCookies(AddCostEstimate);