import React, { Component } from 'react';
import './Reports.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import DatePicker from "react-datepicker";
import Autosuggest from 'react-autosuggest';
import { FormGroup } from 'reactstrap';
import AppContext from "../../context/index";
import {CSVLink} from "react-csv";

class ByProjectCategory extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        let today = new Date();
        let yesterday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

        this.state = {
            reportData: [],
            startDate: yesterday,
            endDate: today,
            selectedPCat: '',
            selectedPCatId: -1,
            pcatsList: [],
            pcatsSuggestions: [],
            capacity: 0,
            projectsData: [],
            totalEstimatedHours: 0,
            totalSpentHours: 0,
            totalEstimatedCost: 0,
            totalActualCost: 0
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getReportData();
        this.getPCatsList();
    }

    getPCatsList = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'project-categories', {
            params: {
                page: 1,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                pcatsList: res.data.data.paginatedProjectCategories,
                loading: false
            });
        })
        .catch(err => {
            this.setState({
                loading: false
            });
        })
    }
    onChangeDeveloper = (event, { newValue }) => {
        this.setState({
            selectedPCat: newValue
        });
    };
    getPCSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.pcatsList.filter(pcat =>
            pcat.title.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !pcat.disabled
        );
    };
    onPCSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            pcatsSuggestions: this.getPCSuggestions(value)
        });
    };
    onPCSuggestionsClearRequested = () => {
        this.setState({
            pcatsSuggestions: []
        });
    };
    renderPCSuggestion = suggestion => (
        <div>
            {suggestion.title}
        </div>
    );
    onPCSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedPCatId: suggestion.id
        }, () => {
            this.getReportData();
        })
    }
    getPCSuggestionValue = suggestion => suggestion.title;

    handleStartDate = (date) => {
        let endDate = this.state.endDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getReportData());
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getReportData());
    }

    getReportData = () => {
        const {cookies} = this.props;

        if(this.state.selectedPCatId && this.state.selectedPCatId != -1) {
            this.setState({
                loading: true
            });

            axios.get(process.env.REACT_APP_API_URL + 'reports-occupancy-project-category', {
                params: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    projectCategoryId: this.state.selectedPCatId
                },
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                res = res.data.data;

                let repData = [],
                    cap = Number(res.users_category.length) * 8,
                    totalEstimatedHours = 0, totalSpentHours = 0, totalEstimatedCost = 0, totalActualCost = 0;

                res.projects.map((item, index) => {
                    totalEstimatedHours += Number(item.estimated_hours);
                    totalSpentHours += Number(item.spent_hours);
                    totalEstimatedCost += Number(item.estimated_cost);
                    totalActualCost += Number(item.actual_cost);
                });

                for(let i in res.ts) {
                    repData.push({
                        name: i,
                        hours: res.ts[i].reduce((total, line) => total + parseInt(line), 0),
                        capacity: cap
                    });
                }

                this.setState({
                    loading: false,
                    reportData: repData,
                    capacity: cap,
                    projectsData: res.projects,
                    totalEstimatedHours: totalEstimatedHours,
                    totalSpentHours: totalSpentHours,
                    totalEstimatedCost: totalEstimatedCost,
                    totalActualCost: totalActualCost,
                });
            })
            .catch(err => {
                if(typeof err.response !== "undefined" && err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    console.error(err);
                    this.setState({
                        loading: false
                    })
                }
            })
        }
    }

    render() {

        const userInputProps = {
            placeholder: 'Project Category',
            value: this.state.selectedPCat,
            onChange: this.onChangeDeveloper
        };

        let csvData = [];
        csvData.push(["Project", "Start Date", "End Date", "Owner", "Estimated Hours", "Spent Hours", "Estimated Cost", "Actual Cost"]);

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar selectedItem="7" selectedSubmenu="82"/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle={"Reports::Projects by category " + this.state.selectedPCat }
                    />
                    <div className="ReportsPage">
                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Project Category:
                                    </div>
                                    <div className="filter--dd xxl">
                                        <FormGroup>
                                            <Autosuggest
                                                suggestions={this.state.pcatsSuggestions}
                                                onSuggestionsFetchRequested={this.onPCSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onPCSuggestionsClearRequested}
                                                getSuggestionValue={this.getPCSuggestionValue}
                                                renderSuggestion={this.renderPCSuggestion}
                                                onSuggestionSelected={this.onPCSuggestionSelected}
                                                inputProps={userInputProps}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        Start date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-line-chart"></i>
                                        End date:
                                    </div>
                                    <div className="filter--dd xl">
                                        <FormGroup>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDate}
                                                minDate={this.state.startDate}
                                                className="start-date-col"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.reportData.length ?
                            <div className="responsive-chart">
                                <ResponsiveContainer>
                                    <AreaChart data={this.state.reportData}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#4d7cfe" stopOpacity={0.9}/>
                                            <stop offset="100%" stopColor="#4d7cfe" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <defs>
                                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#252631" stopOpacity={0.9}/>
                                            <stop offset="100%" stopColor="#252631" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Area type='monotone' dataKey='hours' stackId="1" stroke='#252631' fill="url(#colorPv)" />
                                        <Area type='monotone' dataKey='capacity' stackId="2" stroke='#4d7cfe' fill="url(#colorUv)"  />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        : ''}

                        <div className="row">
                            <div className="col-12">
                                {this.state.projectsData.length ?
                                    <div className="detailed-chart">
                                        <div className="export-link">
                                            <CSVLink data={csvData} filename={"category_profitability"+ this.state.selectedPCat +".csv"}>
                                                Export to CSV
                                            </CSVLink>
                                        </div>

                                        {this.state.projectsData.map((item, index) => {
                                            csvData.push([
                                                this.state.projectsData[index].project_title || '',
                                                this.state.projectsData[index].start_date || '',
                                                this.state.projectsData[index].end_date,
                                                this.state.projectsData[index].owner,
                                                this.state.projectsData[index].estimated_hours,
                                                this.state.projectsData[index].spent_hours,
                                                this.state.projectsData[index].estimated_cost,
                                                this.state.projectsData[index].actual_cost,
                                            ]);


                                            return (
                                                <TableRow
                                                    key={'project-details-' + index}
                                                    tableCols={
                                                        [
                                                            {
                                                                value: item.project_title,
                                                                label: 'Project',
                                                                link: '/project/' + item.id,
                                                                mobileFullWidth: true,
                                                                customCssClass: 'row-details'
                                                            },
                                                            {
                                                                value: item.start_date,
                                                                dateFormat: 'DD.MM.YYYY',
                                                                label: 'Start Date'
                                                            },
                                                            {
                                                                value: item.end_date,
                                                                dateFormat: 'DD.MM.YYYY',
                                                                label: 'End Date'
                                                            },
                                                            {
                                                                value: item.owner,
                                                                label: 'Owner'
                                                            },
                                                            {
                                                                value: item.estimated_hours,
                                                                label: 'Estimated Hours'
                                                            },
                                                            {
                                                                value: item.spent_hours,
                                                                label: 'Spent Hours'
                                                            },
                                                            {
                                                                value: item.estimated_cost,
                                                                label: 'Estimated Cost'
                                                            },
                                                            {
                                                                value: item.actual_cost,
                                                                label: 'Actual Cost'
                                                            }
                                                        ]
                                                    }
                                                />
                                            )
                                        })}

                                        <div className="text-hide">
                                            { csvData.push(['TOTAL','','','',this.state.totalEstimatedHours, this.state.totalSpentHours, this.state.totalEstimatedCost, this.state.totalActualCost]) }
                                        </div>
                                        <TableRow
                                          key={'project-details-' + this.state.projectsData.length}
                                          tableCols={
                                              [
                                                  {
                                                      value: 'TOTAL',
                                                      label: ' ',
                                                      customCssClass: 'row-details total'
                                                  },
                                                  {
                                                      value: ' ',
                                                      label: ' '
                                                  },
                                                  {
                                                      value: ' ',
                                                      label: ' '
                                                  },
                                                  {
                                                      value: ' ',
                                                      label: ' '
                                                  },
                                                  {
                                                      value: this.state.totalEstimatedHours,
                                                      label: 'Total',
                                                      customCssClass: 'total'
                                                  },
                                                  {
                                                      value: this.state.totalSpentHours,
                                                      label: 'Total',
                                                      customCssClass: 'total'
                                                  },
                                                  {
                                                      value: this.state.totalEstimatedCost,
                                                      label: ' Total',
                                                      customCssClass: 'total'
                                                  },
                                                  {
                                                      value: this.state.totalActualCost,
                                                      label: 'Total',
                                                      customCssClass: 'total'
                                                  }
                                              ]
                                          }
                                        />
                                    </div>
                                : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
ByProjectCategory.contextType = AppContext;
export default withCookies(ByProjectCategory);
