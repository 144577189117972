import React, { Component } from 'react';
import './Timesheet.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import AddTimesheet from "../../components/addTimesheet/AddTimesheet";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import confirm from 'reactstrap-confirm';
import Moment from 'react-moment';
import moment from 'moment';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";
import {CSVLink} from "react-csv";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import { userStore } from "../../stores/user";
import DatePicker from "react-datepicker";

class Timesheet extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            tsEntries: [],
            clientsList: [], //pm filters
            usersList: [], //pm filters
            brandsList: [], //pm filters
            businessUnitsList: [], //pm filters
            projectTypesList: [], //pm filters
            filters: {
                client_id: '',
                user_id: '',
                brand_id: '',
                business_unit_id: '',
                project_type_id: '',
            },
            tsWeek: 'x',
            startDate: '',
            endDate: '',
            userRole: -1,
            editTEId: -1
        }
    }

    componentDidMount() {
        const { cookies } = this.props;

        //role = 2 for superadmin or idg pm and 1 for devs
        this.setState({
            userRole: cookies.get('userRole')
        })

        if(parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        if (userStore.me.role == 2) {
            this.getProjectTypesList();
            let devs = userStore.getDEVs();

            this.setState({
                userRole: cookies.get('userRole'),
                brandsList: userStore.brands,
                clientsList: userStore.clients,
                usersList: devs
            })
        }

        this.getTSEntries();
    }

    getProjectTypesList = () => {
        /* Project Types - Dropdown Options */
        const { cookies } = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'project-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
            .then(res => {
                this.setState({
                    projectTypesList: res.data.data
                });
            })
            .catch(err => {
                if(err.response && err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                }
            })
    }

    getBusinessUnits(){
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'business-units/all', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
            .then(res => {

                this.setState({
                    businessUnitsList: res.data,
                    loading: false
                });

                // if(res.data){
                //     let businessUnits = [];
                //
                //     for(let key in res.data){
                //         businessUnits.push({
                //             label: res.data[key].name,
                //             value: res.data[key].id
                //         });
                //     }
                //
                //     this.setState({
                //         businessUnitsList: businessUnits,
                //         loading: false
                //     });
                // }
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }

    getTSEntries = () => {
        const {cookies} = this.props;
        let listsByKeys = {
            user_id: "usersList",
            client_id: "clientsList",
            brand_id: "brandsList",
            business_unit_id: "businessUnitsList",
            project_type_id: "projectTypesList",
        };

        let filters = {};
        let ignore = ['start_date', 'end_date'];
        for(let [key, value] of Object.entries(this.state.filters)){
            filters[key] = this.state[listsByKeys[key]][value] ? this.state[listsByKeys[key]][value].id : '';
        }

        axios.get(process.env.REACT_APP_API_URL + 'ts-by-week/' + this.state.tsWeek, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            },
            params: {
                filters: filters
            }
        })
        .then(res => {
            this.setState({
                tsEntries: res.data.data,
                loading: false,
                startDate: res.data.startDate,
                endDate: res.data.endDate,
                tsWeek: res.data.weekNo,
                showAddTimesheet: false,
                editTEId: -1
            }, () => {
                this.getBusinessUnits();
            });
        })
        .catch(err => {
            if(typeof err.response != "undefined" && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    pageChanged = (increment) => {
        this.setState({
            loading: true
        })

        let weekNo = this.state.tsWeek;
        this.setState(
            { tsWeek: parseInt(weekNo) + increment },
            () => {
                this.getTSEntries();
            }
        );
    }

    openModal = () => {
        this.setState({
            showAddTimesheet: true
        });
    }

    closeModal = refreshList => {
        this.setState({
            showAddTimesheet: false,
            editTEId: -1
        });
        if(refreshList) {
            this.getTSEntries();
        }
    }

    editTSEntry = (teId) => {
        this.setState({
            editTEId: teId,
            showAddTimesheet: true
        })
    }

    deleteTSEntry = async (teId) => {
        const { cookies } = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'ts/' + teId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getTSEntries();
                toast.success("Timesheet entry has been deleted.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    })
                }
                toast.error("An error occured, please try again later.");
            })
        }
    }

    filtersChanged = (filterId, filterValue) => {
        this.setState(
            {
                    filters: {...this.state.filters,...{
                        [filterId]: filterValue
                    }}
                }
            ,
            () => {
                this.getTSEntries();
            });
    }

    clearFilters = () => {
        let filters = {};
        for(let [key, value] of Object.entries(this.state.filters)){
            filters[key] = '';
        }

        this.setState({
            filters: filters
        }, () => {
            this.getTSEntries()
        });
    }

    render() {
        let csvData = [];
        csvData.push(["Project", "Date", "User", "Client", "Brand","Job number", "Hours", "Activity", "Extra request", "QA", "Overtime","Approved","Details", "MJN"])

        Object.keys(this.state.tsEntries).map((dailyEntries, key) => {
            this.state.tsEntries[dailyEntries].timesheet_entries.map((item, index) => {
                csvData.push([
                    item.project_title || '',
                    item.manual_date || '',
                    item.user || '',
                    item.clients_name || '',
                    item.brands_name || '',
                    item.job_number || '',
                    item.hours || '',
                    item.activity_name || '',
                    item.extra_request || '',
                    item.qa_issue || '',
                    item.overtime || '',
                    item.is_approved ? "yes" : "no",
                    item.details || '',
                    item.mjn || '',
                ]);
            });
        });

        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Timesheet"
                        headerOptions={[{
                            name: 'New Entry',
                            action: 'openModal', 
                            params: true
                        }]}
                        roleNeeded={1}
                        openModal={this.openModal}
                    />
                    <div className="TSPage">
                        <div className="ts-head">
                            <div className="ts-pagination">
                                <button className="btn-pag btn-pag--prev" onClick={() => this.pageChanged(-1)}><i className="la la-chevron-circle-left"></i></button>
                                <span>Week: {this.state.startDate} - {this.state.endDate}</span>
                                <button className="btn-pag btn-pag--next" onClick={() => this.pageChanged(1)}><i className="la la-chevron-circle-right"></i></button>
                            </div>
                        </div>

                        {this.state.userRole == 1 &&
                            <div className="ts-form">
                                <button onClick={(e) => { this.openModal() }} className="btn-new-container">
                                    <i className="la la-plus-circle"></i>
                                    New entry
                                </button>
                                {this.state.showAddTimesheet &&
                                    <AddTimesheet
                                        teId={this.state.editTEId != -1 ? this.state.editTEId : ''}
                                        closeModal={this.closeModal}
                                        showPopup={this.state.showAddTimesheet}
                                    />
                                }
                            </div>
                        }
                        <div className="TableHead">
                            <div className="TableHead--options">
                                {this.state.userRole == 2 &&
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-money"></i>
                                            Client:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filters.client_id !== '' ? this.state.clientsList[this.state.filters.client_id].name.substr(0, 10) + '...' : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.clientsList.map((client, index) => {
                                                            return (
                                                                <div key={'client-' + index} onClick={() => this.filtersChanged("client_id", index)}>
                                                                    <DropdownItem>{client.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                }
                                {this.state.userRole == 2 &&
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-money"></i>
                                            User:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filters.user_id !== '' ? this.state.usersList[this.state.filters.user_id].name.substr(0, 10) + '...' : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.usersList.map((user, index) => {
                                                            return (
                                                                <div key={'user-' + index} onClick={() => this.filtersChanged("user_id", index)}>
                                                                    <DropdownItem>{user.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                }
                                {this.state.userRole == 2 &&
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-registered"></i>
                                            Brand:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filters.brand_id !== '' ? this.state.brandsList[this.state.filters.brand_id].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.brandsList.map((brand, index) => {
                                                            return (
                                                                <div key={'brand-' + index} onClick={() => this.filtersChanged("brand_id", index)}>
                                                                    <DropdownItem>{brand.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                }
                                {this.state.userRole == 2 &&
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-money"></i>
                                            Business Unit:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filters.business_unit_id !== '' ? this.state.businessUnitsList[this.state.filters.business_unit_id].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.businessUnitsList.map((businessUnit, index) => {
                                                            return (
                                                                <div key={'business-unit-' + index} onClick={() => this.filtersChanged("business_unit_id", index)}>
                                                                    <DropdownItem>{businessUnit.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                }
                                {this.state.userRole == 2 &&
                                    <div className="filter">
                                        <div className="filter--label">
                                            <i className="la la-magic"></i>
                                            Type:
                                        </div>
                                        <div className="filter--dd">
                                            <UncontrolledDropdown>
                                                <DropdownToggle nav>
                                                    <div>
                                                        { this.state.filters.project_type_id !== '' ? this.state.projectTypesList[this.state.filters.project_type_id].name : 'Select' }
                                                        <i className="la la-angle-down"></i>
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        this.state.projectTypesList.map((type, index) => {
                                                            return (
                                                                <div key={'type-' + index} onClick={() => this.filtersChanged("project_type_id", index)}>
                                                                    <DropdownItem>{type.name}</DropdownItem>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                }
                                {this.state.userRole == 2 &&
                                    <div className="filter filter--reset">
                                        <button className="btn--ts btn--ts-grey"
                                                onClick={() => this.clearFilters()}>Clear filters
                                        </button>
                                    </div>
                                }
                                {(this.state.userRole == 2) &&
                                    <div className="export-link">
                                        <CSVLink data={csvData} filename={"timesheet.csv"}>
                                            Export to CSV
                                        </CSVLink>
                                    </div>
                                }
                            </div>
                        </div>

                        {
                            Object.keys(this.state.tsEntries).map((dailyEntries, key) => {
                                return (
                                    <div key={'day-' + key} className="TableMainContainer">
                                        <div className={this.state.tsEntries[dailyEntries].total_hours < 8 ? 'daily-summary danger' : 'daily-summary success'}><Moment format={'dddd DD.MM.YYYY'}>{dailyEntries}</Moment> - <span className="col--value">{this.state.tsEntries[dailyEntries].total_hours} hours</span></div>
                                        {
                                            this.state.tsEntries[dailyEntries].timesheet_entries.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key = {'row-' + index}
                                                        tableCols = {
                                                            [
                                                                {
                                                                    value: item.project_title,
                                                                    label: ![3, 5].includes(item.project_status) ? "Project (activ)" : "Project (inactiv)",
                                                                    link: '/project/' + item.project_id,
                                                                    customCssClass: 'row-details'
                                                                },
                                                                {
                                                                    value: item.business_units,
                                                                    label: "Business units",
                                                                    customCssClass: 'row-details'
                                                                },
                                                                {
                                                                    value: item.manual_date,
                                                                    dateFormat: 'DD.MM.YYYY', //momentjs formats
                                                                    label: 'Date'
                                                                },
                                                                {
                                                                    value: item.user,
                                                                    label: 'User'
                                                                },
                                                                {
                                                                    value: item.hours,
                                                                    label: 'Hours'
                                                                },
                                                                {
                                                                    value: item.activity_name,
                                                                    label: 'Activity',
                                                                    customCssClass: 'row-details'
                                                                },
                                                                {
                                                                    value: item.extra_request,
                                                                    label: 'Extra',
                                                                    showAsCheckbox: true
                                                                },
                                                                {
                                                                    value: item.qa_issue,
                                                                    label: 'QA',
                                                                    showAsCheckbox: true
                                                                },
                                                                {
                                                                    value: item.overtime,
                                                                    label: 'OT',
                                                                    showAsCheckbox: true
                                                                },
                                                                {
                                                                    value: item.is_approved,
                                                                    label: (item.is_approved == 1) ? 'approved' : "not approved",
                                                                    showAsCheckbox: true,
                                                                    tooltip: item.approve_feedback
                                                                },
                                                                {
                                                                    value: item.details,
                                                                    label: 'Details',
                                                                    customCssClass: 'row-details'
                                                                },
                                                                {
                                                                    value: item.mjn,
                                                                    label: 'MJN',
                                                                    customCssClass: 'row-details'
                                                                },
                                                                (this.state.userRole == 1 && ![3, 5].includes(item.project_status)) ? {
                                                                    ddOptions: [
                                                                        {
                                                                            value: 'Edit entry',
                                                                            action: {
                                                                                name: 'editTSEntry',
                                                                                params: item.id
                                                                            }
                                                                        },
                                                                        {
                                                                            value: 'Remove entry',
                                                                            action: {
                                                                                name: 'deleteTSEntry',
                                                                                params: item.id
                                                                            }
                                                                        }
                                                                    ]
                                                                } : {}
                                                            ]
                                                        }
                                                        editTSEntry={this.editTSEntry}
                                                        deleteTSEntry={this.deleteTSEntry}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        )}

                        {(this.state.userRole == 1 && Object.keys(this.state.tsEntries).length) ?
                            <div className="ts-form">
                                <button onClick={(e) => { this.openModal() }} className="btn-new-container">
                                    <i className="la la-plus-circle"></i>
                                    New entry
                                </button>
                            </div>
                        : ''}
                    </div>    
                </div>
            </div>
        );
    }
}
Timesheet.contextType = AppContext;
export default withCookies(Timesheet);
