import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import './MissingDays.scss';
import Moment from 'react-moment';

class MissingDays extends Component {

    constructor(props) {
        super(props);

        console.log(this.props.days);
    }
    render() {
        return (
            <div>
                <Modal isOpen={this.props.showDetailsPopup} centered wrapClassName="component--modal-missing-days">
                    <ModalHeader>{this.props.days.user_name}</ModalHeader>
                    <ModalBody>
                        <ul>
                            {this.props.days.missing_days.map((day, index) => {
                                return <li key={index}><Moment format="DD MMM YYYY">{day}</Moment> </li>
                            })}
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CLOSE</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withCookies(MissingDays);