import React, { Component } from 'react';
import './UserWorkingPlaces.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import AppContext from "../../context/index";
import DatePicker from "react-datepicker";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import AddUserWorkingPlace from "../../components/addUserWorkingPlace/AddUserWorkingPlace";
import confirm from "reactstrap-confirm";
import {toast} from "react-toastify";
import {handleErrorResponse} from "../../utils/utils";
import moment from "moment";
import userStore from "../../stores/user";
var timer;

class UserWorkingPlaces extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            upwData: [],
            userRole: null,
            editUWPId: '',
            usersList: userStore.getDEVs(),
            workingPlaces: {
                1: 'Work from office',
                2: 'Work from home',
                3: 'Holiday'
            },
            filtersUserId: null,
            startDate: new Date(),
            endDate: new Date().setMonth(new Date().getMonth() + 1),
            showAddUserWorkingPlace: false,
            currentPage: 1,
            lastPage: 1,
            totalItems: ''
        }

        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if(parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        //role = 2 for superadmin or idg pm and 1 for devs
        this.setState({
            userRole: cookies.get('userRole')
        });

        this.getUWP(this.state.currentPage);
    }

    getUWP = (currentPage) => {
        const {cookies} = this.props;

        axios.get(process.env.REACT_APP_API_URL + 'uwp', {
            params: {
                page: currentPage,
                user_id: this.state.filtersUserId != null ? this.state.usersList[this.state.filtersUserId].id : null,
                start_date: moment(this.state.startDate).format('YYYY-MM-DD'),
                end_date:  moment(this.state.endDate).format('YYYY-MM-DD'),
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                upwData: res.data.data,
                lastPage: res.data.last_page,
                totalItems: res.data.total,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    };

    openModal = (params) => {
        this.setState({
            // editTEId: params.teId,
            showAddUserWorkingPlace: true
        });
    };

    closeModal = refreshList => {
        this.setState({
            showAddUserWorkingPlace: false,
            editUWPId: ''
        });
        if(refreshList) {
            this.getUWP();
        }
    };

    editUWPEntry = (UWPId) => {
        this.setState({
            editUWPId: UWPId,
            showAddUserWorkingPlace: true
        })
    }

    deleteUWPEntry = async (teId) => {
        const { cookies } = this.props;
        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'uwp/' + teId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
                .then(res => {
                    this.getUWP();
                    toast.success("Working place entry has been deleted.");
                })
                .catch(err => {
                    console.log(err)
                    if(err.response.status === 401){
                        //redirect to login
                        this.context.redirectToLogin();
                    } else {
                        this.setState({
                            loading: false
                        })
                    }
                    handleErrorResponse(err.response);
                })
        }
    }


    handleStartDate = (date) => {
        let endDate = this.state.endDate;

        if(date > this.state.endDate){
            endDate = date;
        }

        this.setState({
            startDate: date,
            endDate: endDate
        }, () => this.getUWP(1));
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date
        }, () => this.getUWP(1));
    }

    pageChanged = (page) => {
        window.clearTimeout(timer);

        timer = window.setTimeout(() => {
            page = this.state.currentPage + page;
            if ( 1 > page || page > this.state.lastPage )
                return;

            this.setState({
                currentPage: page
            });
            this.getUWP(page);
        },300);
    }

    filtersChanged = (filterId, filterValue) => {
        this.setState(
            {
                [filterId]: filterValue
            },
            () => {
                this.getUWP(1);
            });
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="User working places"
                        roleNeeded={1}
                    />
                    <div className="TableHead">
                        <div className="TableHead--options">
                            <div className="filter">
                                <div className="filter--label">
                                    <i className="la la-line-chart"></i>
                                    Start date:
                                </div>
                                <div className="filter--dd xl">
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDate}
                                        className="start-date-col"
                                    />
                                </div>
                            </div>

                            <div className="filter">
                                <div className="filter--label">
                                    <i className="la la-line-chart"></i>
                                    End date:
                                </div>
                                <div className="filter--dd xl">
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDate}
                                        minDate={this.state.startDate}
                                        className="start-date-col"
                                    />
                                </div>
                            </div>
                            {this.state.userRole == 2 &&
                                <div className="filter">
                                    <div className="filter--label">
                                        <i className="la la-money"></i>
                                        User:
                                    </div>
                                    <div className="filter--dd">
                                        <UncontrolledDropdown>
                                            <DropdownToggle nav>
                                                <div>
                                                    {this.state.filtersUserId !== null ? this.state.usersList[this.state.filtersUserId].name : 'Select'}
                                                    <i className="la la-angle-down"></i>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    this.state.usersList.map((user, index) => {
                                                        return (
                                                            <div key={'user-' + index}
                                                                 onClick={() => this.filtersChanged("filtersUserId", index)}>
                                                                <DropdownItem>{user.name}</DropdownItem>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            }
                            {this.state.userRole == 1 &&
                                <div className="add-button">
                                    <button onClick={(e) => { this.openModal() }} className="btn-new-container">
                                        <i className="la la-plus-circle"></i>
                                        New entry
                                    </button>
                                    {this.state.showAddUserWorkingPlace &&
                                        <AddUserWorkingPlace
                                            UWPId={this.state.editUWPId}
                                            closeModal={this.closeModal}
                                            showPopup={this.state.showAddUserWorkingPlace}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    <div className="TSPage">
                        <div className="ts-head">
                            <div className="ts-pagination">
                                <button className="btn-pag btn-pag--prev" onClick={() => this.pageChanged(-1)}><i className="la la-chevron-circle-left"></i></button>
                                <span>Page: {this.state.currentPage}  / {this.state.lastPage} (total: {this.state.totalItems})</span>
                                <button className="btn-pag btn-pag--next" onClick={() => this.pageChanged(1)}><i className="la la-chevron-circle-right"></i></button>
                            </div>
                        </div>
                        <div className="TableMainContainer">
                            <div className='daily-summary'></div>
                            {
                                this.state.upwData.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + item.id + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: this.state.workingPlaces[item.working_place],
                                                        label: 'Working place'
                                                    },
                                                    (this.state.userRole == 2) ?
                                                        {
                                                            value: item.user ? item.user.name : '',
                                                            label: 'User'
                                                        }: {},
                                                    {
                                                        value: item.manual_date,
                                                        dateFormat: 'DD.MM.YYYY', //momentjs formats
                                                        label: 'Date'
                                                    },
                                                    (this.state.userRole == 1) ? {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit entry',
                                                                action: {
                                                                    name: 'editUWPEntry',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: 'Remove entry',
                                                                action: {
                                                                    name: 'deleteUWPEntry',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    } : {}
                                                ]
                                            }
                                            openModal={this.openModal}
                                            editUWPEntry={this.editUWPEntry}
                                            deleteUWPEntry={this.deleteUWPEntry}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
UserWorkingPlaces.contextType = AppContext;
export default withCookies(UserWorkingPlaces);
