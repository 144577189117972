import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddActivityType from "../../components/addActivityType/AddActivityType";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class ActivityTypes extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            activityTypes: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddAT: false,
            editATId: '',
            editATName: '',
            editATRate: 0,
            editATUSDCurrency: 0
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;

        if((cookies.get('userRole') != 2) || parseInt(cookies.get('isLimitedPm'))) {
            this.context.redirectToLogin();
        }

        this.getATs();
    }

    openModal = (clearData) => {
        if(clearData){
            this.setState({
                editATId: '',
                editATName: '',
                editATRate: 0,
                editATUSDCurrency: 0
            })
        }
        this.setState({
            showAddAT: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddAT: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getATs();
            }
        );
    }

    searchATsByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getATs();
        });
        
    }

    getATs = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'activity-types', {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                activityTypes: res.data.data.paginatedActivityTypes,
                totalResults: res.data.data.totalResults,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editAT = (atId) => {
        const {cookies} = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'activity-type/' + atId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editATId: res.data.data.id,
                editATName: res.data.data.name,
                editATRate: res.data.data.rate,
                editATUSDCurrency: res.data.data.usd_currency,
                showAddAT: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveAT = (atData) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        //if we have an activity type id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if(atData.atId){
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'activity-types' + (atData.atId ? ('/' + atData.atId) : ''),
            data: {
                name: atData.atName,
                rate: atData.atRate,
                usd_currency: atData.atUSDCurrency
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getATs();
            this.setState({
                loading: false,
                showAddAT: false
            })
            toast.success("Activity type has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddAT: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    toggleAT = async (atId) => {
        const { cookies } = this.props;
        
        this.setState({
            loading: true
        })

        axios.delete(process.env.REACT_APP_API_URL + 'activity-types/' + atId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getATs();
            toast.success("Activity type has been disabled.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    <Header
                        pageTitle="Activity Types" 
                        headerOptions={[{
                            name: 'New Activity Type', 
                            action: 'openModal', 
                            params: true
                        }]}
                        openModal = {this.openModal}
                    />
                    <div className="ClientsPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchATsByName} placeholder='Search activity types'/>
                                    </div>
                                </div>
                            </div>

                            <button onClick={(e) => {this.openModal(true)}} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add Activity Type
                                <AddActivityType
                                    atId={this.state.editATId}
                                    atName={this.state.editATName}
                                    atRate={this.state.editATRate}
                                    atUSDCurrency={this.state.editATUSDCurrency}
                                    saveAT={this.saveAT}
                                    closeModal={this.closeModal}
                                    showPopup={this.state.showAddAT}
                                />
                            </button>    

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.activityTypes.map((item, index) => {
                                    return (
                                        <TableRow
                                            key = {'row-' + index}
                                            tableCols = {
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Activity Type',
                                                        mobileFullWidth: true,
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.rate,
                                                        label: 'Rate'
                                                    },
                                                    {
                                                        value: item.usd_currency,
                                                        label: 'USD Currency',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        value: !item.disabled,
                                                        label: 'Enabled',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit this activity type',
                                                                action: {
                                                                    name: 'editAT',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: !item.disabled ? 'Disable this activity type' : 'Enable this activity type',
                                                                action: {
                                                                    name: 'toggleAT',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editAT={this.editAT}
                                            toggleAT={this.toggleAT}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)}/>
                    </div>
                </div>
            </div>
        );
    }
}
ActivityTypes.contextType = AppContext;
export default withCookies(ActivityTypes);
