import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import Autosuggest from 'react-autosuggest';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import AppContext from "../../context/index";


class AddTimesheet extends Component {

    constructor(props) {
        super(props);
        let today = new Date();

        this.state = {
            selectedProject: '',
            selectedProjectId: this.props.projectId || -1,
            selectedProjectTypeId: null,
            projectMjn: this.props.projectMjn || '',
            activitiesList: [],
            suggestions: [],
            minDate: new Date(),
            maxDate: new Date(),
            projectsList: [],
            editTEProjectId: -1,
            editTEProjectTitle: '',
            editTEManualDate: today,
            editTEUserId: -1,
            editTEHours: 0,
            editTEActivityId: -1,
            editTEExtra: false,
            editTEOvertime: false,
            editTEQA: false,
            editTEDetails: '',
            editTEMJN: this.props.projectMjn || '',
            renderForm: false
        };
    }

    componentDidMount() {
        if(this.props.teId && this.props.teId != -1) {
            this.getTSEntry(this.props.teId);
        } else {
            this.getProjects();
        }
    }

    /* GET TS ENTRY DETAILS */
    getTSEntry = (teId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'ts/' + teId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false,
                editTEId: res.data.data.id,
                editTEProjectId: res.data.data.project_id,
                editTEProjectTitle: res.data.data.project_title,
                selectedProject: res.data.data.project_title,
                selectedProjectId: res.data.data.project_id,
                editTEManualDate: res.data.data.manual_date,
                editTEUserId: res.data.data.user_id,
                editTEHours: res.data.data.hours,
                editTEActivityId: res.data.data.activity_type_id,
                editTEExtra: res.data.data.extra_request,
                editTEOvertime: res.data.data.overtime,
                editTEQA: res.data.data.qa_issue,
                editTEDetails: res.data.data.details,
                editTEMJN: res.data.data.mjn
            });

            this.getProjects();
            this.getActivities();
            this.getProjectDates();
        })
    }

    saveTE = (teData, manualDate, projectId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        //if we have a brand id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (teData.teId) {
            requestMethod = "PUT";
        }

        let auxManualDate = new Date(manualDate);

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'ts' + (teData.teId ? ('/' + teData.teId) : ''),
            data: {
                project_id: projectId,
                activity_type_id: teData.teActivityId,
                hours: teData.teHours,
                extra_request: teData.teExtra ? 1 : 0,
                overtime: teData.teOvertime ? 1 : 0,
                qa_issue: teData.teQA ? 1 : 0,
                manual_date: auxManualDate.getFullYear() + '-' + parseInt(parseInt(auxManualDate.getMonth()) + 1) + '-' + auxManualDate.getDate(),
                details: teData.teDetails,
                mjn: teData.teMJN,
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false
            })
            this.props.closeModal(true);
            toast.success("Timesheet entry has been saved.");
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddTimesheet: false
                })
            }
            toast.error("An error occured, please try again later.");
        })
    }

    /* FORM LISTS */
    getProjects = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'projects', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                projectsList: res.data.data.projects,
                suggestions: res.data.data.projects,
                loading: false
            }, () => {
                if(this.props.projectId) { //if we are on a project's page, auto select this project
                    for(let i in res.data.data.projects) {
                        if(res.data.data.projects[i].id == this.props.projectId) {
                            this.setState({
                                selectedProject: res.data.data.projects[i].title,
                                selectedProjectTypeId: res.data.data.projects[i].type_id,
                                minDate: res.data.data.projects[i].start_date
                            }, () => this.getActivities())
                        }
                    }
                }
                else {
                    this.setState({
                        renderForm: true
                    })
                }
            });
        })
        .catch(err => {
            if( typeof err.response !== "undefined" && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    handleManualDate = (date) => {
        this.setState({
            editTEManualDate: date
        });
    }

    onChange = (event, { newValue }) => {
        this.setState({
            selectedProject: newValue
        });
    };

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.projectsList.filter(proj =>
            proj.title.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && proj.status_id != 3 && proj.status_id != 5
        );
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
          suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
          suggestions: []
        });
    };

    renderSuggestion = suggestion => (
        <div>
            {suggestion.title}
        </div>
    );

    getSuggestionValue = suggestion => suggestion.title;

    getActivities = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true,
            renderForm: false
        })
        axios.get(process.env.REACT_APP_API_URL + 'activity-types-by-project-user/' + this.state.selectedProjectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                activitiesList: res.data.data,
                loading: false
            });

            //if we have only one activity, auto select it
            if(res.data.data.length == 1) {
                this.setState({
                    editTEActivityId: res.data.data[0].id,
                    renderForm: true
                })
            } else {
                this.setState({
                    renderForm: true
                })
            }
        })
        .catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    getProjectDates = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'project/' + this.state.selectedProjectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                minDate: res.data.data.min_te_date,
                maxDate: res.data.data.is_vacation ? '' : new Date(),
                loading: false
            });
        })
        .catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    onSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedProjectId: suggestion.id,
            selectedProjectTypeId: suggestion.type_id,
            projectMjn: suggestion.mjn
        }, () => {
            this.getActivities();
            this.getProjectDates();
        })
    }

    keyDown = (e) => {
        if (e.key === 'Tab') {
            if(this.state.suggestions.length == 1) {
                this.setState({
                    selectedProjectId: this.state.suggestions[0].id,
                    selectedProject: this.state.suggestions[0].title
                }, () => this.getActivities())
            }
        }
    };

    render() {
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Type a project\'s name',
            value: this.state.selectedProject,
            onChange: this.onChange,
            onKeyDown: this.keyDown
        };

        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    {this.state.renderForm &&
                    <Formik
                        initialValues = {{
                            teId: this.props.teId,
                            teProjectId: this.state.editTEProjectId,
                            teHours: this.state.editTEHours,
                            teActivityId: this.state.editTEActivityId,
                            teExtra: this.state.editTEExtra,
                            teOvertime: this.state.editTEOvertime,
                            teQA: this.state.editTEQA,
                            teDetails: this.state.editTEDetails || '',
                            teMJN: this.state.editTEMJN || this.state.projectMjn
                        }}
                        validate={values => {
                            let errors = {};
                            if (!values.teProjectId) {
                                errors.teProjectId = 'Required';
                            }
                            if (!this.state.editTEManualDate) {
                                errors.teManualDate = 'Required';
                            }
                            if (!values.teMJN && (this.state.selectedProjectTypeId == 2) && (this.state.selectedProjectId != '10417')) {
                                errors.teMJN = 'Required';
                            }
                            if (!values.teHours || !/^[0-9.]*[0-9]$/.test(values.teHours) || (values.teHours <= 0.1) || (values.teHours > 100)) {
                                errors.teHours = 'Required';
                            }
                            if ((values.teActivityId === -1) || (values.teActivityId === 'Select')) {
                                errors.teActivityId = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.saveTE(values, this.state.editTEManualDate, this.state.selectedProjectId);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>{this.state.editTEId ? 'Edit' : 'Add new'} timesheet entry</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="teId" value={values.teId}/>

                                    <FormGroup>
                                        <label htmlFor="te--projectId" className="text-left--ts">Project </label>
                                        <Autosuggest
                                            suggestions={this.state.suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            inputProps={inputProps}
                                        />
                                        <ErrorMessage name="teProjectId" className="form-error" component="div" />
                                    </FormGroup>

                                    {this.state.selectedProjectId != -1 &&
                                    <div>
                                        <div className="row">
                                            <div className="col-8 manual-date-container">
                                                <FormGroup>
                                                    <label><span>Date </span></label>
                                                    <DatePicker
                                                        selected={new Date(this.state.editTEManualDate)}
                                                        onChange={this.handleManualDate}
                                                        className="manual-date-input"
                                                        minDate={new Date(this.state.minDate)}
                                                        maxDate={this.state.maxDate}
                                                        showMonthYearDropdown
                                                        disabled={this.state.editTEId ? true : false}
                                                    />
                                                    <ErrorMessage name="teManualDate" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-4">
                                                <FormGroup>
                                                    <label htmlFor="te--hours" className="text-left--ts">Hours</label>
                                                    <input
                                                        type="text"
                                                        name="teHours"
                                                        id="te--hours"
                                                        value={values.teHours}
                                                        onChange={handleChange}
                                                        className={errors.teHours ? 'form-control error' : 'form-control'}
                                                        placeholder="Hours"
                                                    />
                                                    <ErrorMessage name="teHours" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                        </div>


                                        <FormGroup>
                                            <label htmlFor="te--activity" className="text-left--ts">Activity type</label>
                                            <select
                                                name="teActivityId"
                                                id="te--activity"
                                                value={values.teActivityId}
                                                onChange={handleChange}
                                                className={errors.teActivityId ? 'form-control error' : 'form-control'}
                                            >
                                                <option>Select</option>
                                                {
                                                    this.state.activitiesList.map(activity => {
                                                        return (
                                                            <option key={'act-' + activity.id} value={activity.id}>{activity.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <ErrorMessage name="teActivityId" className="form-error" component="div" />
                                        </FormGroup>
                                    </div>
                                    }

                                    <FormGroup>
                                        <label htmlFor="te--details" className="text-left--ts">Details</label>
                                        <input
                                            type="text"
                                            name="teDetails"
                                            id="te--details"
                                            value={values.teDetails}
                                            onChange={handleChange}
                                            className={errors.teDetails ? 'form-control error' : 'form-control'}
                                            placeholder="Details"
                                        />
                                        <ErrorMessage name="teDetails" className="form-error" component="div" />
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="te--mjn" className="text-left--ts">MJN</label>
                                        <input
                                            type="text"
                                            name="teMJN"
                                            id="te--mjn"
                                            value={values.teMJN}
                                            onChange={handleChange}
                                            className={errors.teMJN ? 'form-control error' : 'form-control'}
                                            placeholder="MJN"
                                        />
                                        <ErrorMessage name="teMJN" className="form-error" component="div"/>
                                    </FormGroup>
                                    <div className="row">
                                        <div className="col-4">
                                            <FormGroup>
                                                <label className="chk-container">Extra request
                                                    <input type="checkbox" name="teExtra" checked={values.teExtra} onChange={handleChange} id="te--extra-request" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-4">
                                            <FormGroup>
                                                <label className="chk-container">QA issue
                                                    <input type="checkbox" name="teQA" checked={values.teQA} onChange={handleChange} id="te--qa-issue" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-4">
                                            <FormGroup>
                                                <label className="chk-container">Overtime
                                                    <input type="checkbox" name="teOvertime" checked={values.teOvertime} onChange={handleChange} id="te--overtime" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </FormGroup>
                                        </div>
                                    </div>


                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE ENTRY'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
AddTimesheet.contextType = AppContext;
export default withCookies(AddTimesheet);
