import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Preloader from "../../components/preloader/Preloader";
import ParticlesComponent from "../../components/particles/Particles";
import { Animated } from "react-animated-css";
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';

class Forgot extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            loading: false,
            redirectToHomepage: false
        };
    }

    setRedirect = () => {
        this.setState({
            redirectToHomepage: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirectToHomepage) {
            return <Redirect to='/' />
        }
    }

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            loading: true
        });

        let loginData = JSON.stringify({
            email: this.state.email
        })

        axios.post(process.env.REACT_APP_API_URL + 'password/create', loginData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if(res.data.success){
                toast.success(res.data.message);
                this.setState({
                    email: ''
                })
            }
            else{
                toast.error(res.data.message);
            }

            this.setState({
                loading: false
            })
        })
        .catch(err => {
            toast.error(err.message);
            this.setState({
                loading: false
            });
        });
            
    };

    componentDidMount() {
        document.body.className = "login";
    }

    render() {
        const LoginSchema = Yup.object().shape({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required')
        });

        return (
            this.state.loading ? <Preloader/> :
            <div className="LoginPage">
                <ParticlesComponent/>
                
                <Animated animationIn="fadeInLeft" animationOut="fadeOutLeft" isVisible={true}>
                    <div className="login-wrapper">
                        <div className="full-center centerer">
                            <Formik
                                validationSchema = {LoginSchema}
                            >
                                { props => {
                                    const {
                                        touched,
                                        errors,
                                        isSubmitting
                                    } = props;
                                    return (
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email">
                                                    Email
                                                </label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    value={this.state.email}
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    className={
                                                        errors.email && touched.email ? 'form-control error' : 'form-control'
                                                    }
                                                />

                                                {errors.email &&
                                                touched.email && <div className="input-feedback">{errors.email}</div>}
                                            </div>

                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                SEND RECOVERY LINK
                                            </button>

                                            <div className="remember-forgot">
                                                <Link to="/login" className="link-register">Back to login</Link>
                                            </div>
                                        </form>
                                    );
                                } }
                            </Formik>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
}

export default withCookies(Forgot);