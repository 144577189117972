import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { toast } from 'react-toastify';

class AddParent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProject: '',
            selectedProjectId: -1,
            projectsList: [],
            projectsSuggestions: []
        };
    }

    componentDidMount() {
        this.getProjectsList();
    }

    getProjectsList = () => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })
        axios.get(process.env.REACT_APP_API_URL + 'project-parents/' + this.props.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            console.log(res);
            this.setState({
                projectsList: res.data.data,
                loading: false
            });
        })
        .catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    onChangeProject = (event, { newValue }) => {
        this.setState({
            selectedProject: newValue
        });
    };

    getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.projectsList.filter(project =>
            project.title.toLowerCase().indexOf(inputValue.toLowerCase()) != -1
        );
    };
    
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            projectsSuggestions: this.getSuggestions(value)
        });
    };
    
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            getSuggestions: []
        });
    };

    renderSuggestion = suggestion => (
        <div>
            {suggestion.title}
        </div>
    );

    onSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedProjectId: suggestion.id
        })
    }

    getSuggestionValue = suggestion => suggestion.title;

    saveParent(projectId, selectedProjectId) {
        const { cookies } = this.props;
        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + 'project-parents',
            data: {
                project_id: projectId,
                parent_id: selectedProjectId,
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.props.closeModal(true);
            toast.success("Project's parent has been saved.");
        })
    }

    render() {
        const userInputProps = {
            placeholder: 'Project',
            value: this.state.selectedProject,
            onChange: this.onChangeProject
        };

        return (
            <div>
                <Modal isOpen={this.props.showPopup} centered wrapClassName="component--modal-new-project">
                    <Formik
                        initialValues = {{ projectId: this.props.projectId }}
                        validate={values => {
                            let errors = {};
                            if (this.state.selectedProjectId === -1) {
                                errors.projectName = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.saveParent(values.projectId, this.state.selectedProjectId);
                            setSubmitting(false);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <ModalHeader>Set parent</ModalHeader>
                                <ModalBody className="new--project">
                                    <input type="hidden" name="projectId" value={values.projectId}/>

                                    <FormGroup className={errors.projectName ? 'error-container': ''}>
                                        <label htmlFor="te--userId" className="text-left--ts">Project's title </label>
                                        <Autosuggest
                                            suggestions={this.state.projectsSuggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            inputProps={userInputProps}
                                        />
                                        {errors.projectName &&
                                            <div className="form-error">{errors.projectName}</div>
                                        }
                                    </FormGroup>
                                
                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE PARENT'}</button>
                                    <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeModal}>CANCEL</button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        );
    }
}

export default withCookies(AddParent);