import React, { Component } from 'react';
import './Preloader.scss';

class Preloader extends Component {
    render() {
        return (
            <div className="Preloader" id="preloader">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }
}

export default Preloader;
