import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label } from 'reactstrap';
import './AddPO.scss';
import { withCookies } from 'react-cookie';
import { Formik, Form, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Preloader from "../../components/preloader/Preloader";
import { toast } from 'react-toastify';
import Autosuggest from 'react-autosuggest';
import AppContext from "../../context/index";
import userStore from "../../stores/user";
import moment from "moment";

class AddPO extends Component {

    constructor(props) {
        super(props);

        let today = new Date();

        this.state = {
            renderForm: false,
            loading: false,
            clientUnitsList: [],
            clientUnitsSuggestions: [],
            selectedClientUnit: '',
            selectedClientUnitId: -1,
            selectedCostEstimateId: null,
            clientPMsList: [],
            clientPMsSuggestions: [],
            selectedClientPM: '',
            selectedClientPMId: -1,
            costEstimates: [],
            plajas: [],
            ceEditId: -1,
            poEditCEId: -1,
            poEditPlajaId: -1,
            poEditNumber: '',
            poEditInvoiceUM: '',
            poEditDate: today,
            poEditInvoiceDate: today,
            poEditValue: '',
            poErrorClientUnitId: '',
            poErrorClientPMId: '',
            minInvoiceNumber: Date.parse('08 Mar 2022 00:00:00 GMT')
        };

        this.handleDate = this.handleDate.bind(this);
        this.handleInvoiceDate = this.handleInvoiceDate.bind(this);
    }

    componentDidMount = () => {
        this.getClientUnits();
        this.getCostEstimates();

        this.setState({
            clientPMsList: userStore.getClientPMs()
        });

        if(this.props.ceId && this.props.ceId != -1) {
            //get details, it's an update
            this.getPODetails(this.props.ceId);
        }
        else {
            this.setState({
                renderForm: true
            })
        }
    }

    onChangeClientUnit = (event, { newValue }) => {
        this.setState({
            selectedClientUnit: newValue
        });
    };

    getClientUnitsSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.clientUnitsList.filter(cu =>
            cu.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !cu.disabled
        );
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onClientUnitsSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            clientUnitsSuggestions: this.getClientUnitsSuggestions(value)
        });
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onClientUnitsSuggestionsClearRequested = () => {
        this.setState({
            clientUnitsSuggestions: []
        })
    };
    renderClientUnitsSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onClientUnitsSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedClientUnitId: suggestion.id,
            poErrorClientUnitId: false
        });
    }
    getClientUnitsSuggestionValue = suggestion => suggestion.name;

    /* Client PMs */
    onChangeClientPM = (event, { newValue }) => {
        this.setState({
            selectedClientPM: newValue
        });
    };

    getClientPMsSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.clientPMsList.filter(cu =>
            cu.name.toLowerCase().indexOf(inputValue.toLowerCase()) != -1 && !cu.disabled
        );
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onClientPMsSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            clientPMsSuggestions: this.getClientPMsSuggestions(value)
        });
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onClientPMsSuggestionsClearRequested = () => {
        this.setState({
            clientPMsSuggestions: []
        })
    };
    renderClientPMsSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );
    onClientPMsSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            selectedClientPMId: suggestion.id,
            poErrorClientPMId: false
        });
    }
    getClientPMsSuggestionValue = suggestion => suggestion.name;


    handleDate = (date) => {
        this.setState({
            poEditDate: date
        });
    }

    handleInvoiceDate = (date) => {
        this.setState({
            poEditInvoiceDate: date
        });
    }

    getClientUnits = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'client-units', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                clientUnitsList: res.data.data.paginatedClientUnits,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getPlajas = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'plajas/' + this.state.selectedCostEstimateId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
            .then(res => {
                this.setState({
                    plajas: res.data,
                    loading: false
                });
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }

    getCostEstimates = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimates/' + this.props.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                costEstimates: res.data.data.cost_estimates,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getPODetails = () => {}

    savePO = (values, poDate) => {

        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        let requestMethod = "POST";
        if (values.poId && values.poId != -1) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'purchase-orders' + ((values.poId && values.poId != -1) ? ('/' + values.poId) : ''),
            data: {
                project_id: this.props.projectId,
                status_id: 2,
                value: values.poValue,
                cost_estimate_id: values.poCEId,
                po_number: values.poNumber,
                po_invoice_um: values.poInvoiceUM,
                plaja_id: values.poPlajaId,
                invoice_date: this.state.poEditInvoiceDate,
                date: poDate.getFullYear() + '-' + ("0" + parseInt(parseInt(poDate.getMonth()) + 1)).slice(-2) + '-' + ("0" + poDate.getDate()).slice(-2),
                client_pm_id: this.state.selectedClientPMId,
                client_unit_id: this.state.selectedClientUnitId
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                loading: false
            })
            this.props.closeEditModal(true);
            toast.success("Purchase order has been saved.");
        })
        .catch(err => {
            if(err && err.response && err.response.status && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
            this.setState({
                loading: false
            })
            toast.error("An error occured, please try again later.");
        })
    }

    render() {

        const clientUnitInputProps = {
            placeholder: 'Client unit\'s name',
            value: this.state.selectedClientUnit,
            onChange: this.onChangeClientUnit
        };
        const clientPMInputProps = {
            placeholder: 'Client PM',
            value: this.state.selectedClientPM,
            onChange: this.onChangeClientPM
        };

        return (
            <div>
                { this.state.loading && <Preloader/> }
                <Modal isOpen={this.props.showPopup} wrapClassName="component--modal-new-po">
                {this.state.renderForm &&

                    <Formik
                            initialValues = {{
                                poId: this.state.ceEditId,
                                poCEId: this.state.poEditCEId,
                                poPlajaId: this.state.poEditPlajaId,
                                poNumber: this.state.poEditNumber,
                                poInvoiceUM: this.state.poEditInvoiceUM,
                                poValue: this.state.poEditValue,
                                poInvoiceDate: this.state.poEditInvoiceDate
                            }}
                            validate={values => {
                                // console.log(values);

                                if((values.poCEId !== this.state.selectedCostEstimateId) && (values.poCEId !== 'Select')){
                                    this.setState({
                                        selectedCostEstimateId: values.poCEId
                                    }, () => {this.getPlajas()});
                                }


                                let errors = {};
                                if (!values.poCEId || values.poCEId === -1) {
                                    errors.poCEId = 'Required';
                                }
                                if (!values.poPlajaId || values.poPlajaId === -1) {
                                    errors.poPlajaId = 'Required';
                                }
                                if(!values.poNumber) {
                                    errors.poNumber = 'Required';
                                }
                                if (!this.state.poEditDate) {
                                    errors.poDate = 'Required';
                                }
                                if (!values.poValue) {
                                    errors.poValue = 'Required';
                                }
                                if(!this.state.selectedClientUnitId || this.state.selectedClientUnitId === -1) {
                                    this.setState({
                                        poErrorClientUnitId: true
                                    })
                                    errors.poClientUnitId = 'Required';
                                }
                                if(!this.state.selectedClientPMId || this.state.selectedClientPMId === -1) {
                                    this.setState({
                                        poErrorClientPMId: true
                                    })
                                    errors.poClientPMId = 'Required';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.savePO(values, new Date(this.state.poEditDate));
                                setSubmitting(false);
                            }}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <Form>
                                    <input type="hidden" name="poId" value={values.poId}/>
                                    <ModalHeader toggle={this.toggle}>Add new purchase order</ModalHeader>
                                    <ModalBody className="new--ce">
                                        <div className="row">
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="po--ceId" className="text-left--ts">Cost estimate</label>
                                                    <select
                                                        name="poCEId"
                                                        id="po--ceId"
                                                        value={values.poCEId}
                                                        onChange={handleChange}
                                                        className={errors.poCEId ? 'form-control error' : 'form-control'}
                                                    >
                                                        <option>Select</option>
                                                        {
                                                            this.state.costEstimates.map(ce => {
                                                                return (
                                                                    <option key={'ce-' + ce.id} value={ce.id}>{'#' + ce.id}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <ErrorMessage name="poCEId" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="po--ceId" className="text-left--ts">Plajas</label>
                                                    <select
                                                        name="poPlajaId"
                                                        id="po--plajaId"
                                                        value={values.poPlajaId}
                                                        onChange={handleChange}
                                                        className={errors.poPlajaId ? 'form-control error' : 'form-control'}
                                                    >
                                                        <option>Select</option>
                                                        {
                                                            this.state.plajas.map(plaja => {
                                                                return (
                                                                    <option key={'ce-' + plaja.id} value={plaja.id}>{plaja.nume}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <ErrorMessage name="poPlajaId" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 date-container">
                                                <FormGroup>
                                                    <Label className="text-left--ts"><span>Date </span></Label>
                                                    <DatePicker
                                                        selected={this.state.poEditDate}
                                                        onChange={this.handleDate}
                                                        className="start-date-col"
                                                    />
                                                    <ErrorMessage name="poDate" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-6 date-container">
                                                <FormGroup>
                                                    <Label className="text-left--ts"><span>Invoice Date </span></Label>
                                                    <DatePicker
                                                        selected={this.state.poEditInvoiceDate}
                                                        minDate={this.state.minInvoiceNumber}
                                                        onChange={this.handleInvoiceDate}
                                                        className="start-date-col"
                                                    />
                                                    <ErrorMessage name="poInvoiceDate" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <FormGroup className={this.state.poErrorClientUnitId ? 'error-container': ''}>
                                                    <label htmlFor="po--clientUnitId" className="text-left--ts">Client unit </label>
                                                    <Autosuggest
                                                        suggestions={this.state.clientUnitsSuggestions}
                                                        onSuggestionsFetchRequested={this.onClientUnitsSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onClientUnitsSuggestionsClearRequested}
                                                        getSuggestionValue={this.getClientUnitsSuggestionValue}
                                                        renderSuggestion={this.renderClientUnitsSuggestion}
                                                        onSuggestionSelected={this.onClientUnitsSuggestionSelected}
                                                        inputProps={clientUnitInputProps}
                                                    />
                                                    {this.state.poErrorClientUnitId &&
                                                        <div className="form-error">Required</div>
                                                    }
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup className={this.state.poErrorClientPMId ? 'error-container': ''}>
                                                    <label htmlFor="po--clientUnitId" className="text-left--ts">Client PM </label>
                                                    <Autosuggest
                                                        suggestions={this.state.clientPMsSuggestions}
                                                        onSuggestionsFetchRequested={this.onClientPMsSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onClientPMsSuggestionsClearRequested}
                                                        getSuggestionValue={this.getClientPMsSuggestionValue}
                                                        renderSuggestion={this.renderClientPMsSuggestion}
                                                        onSuggestionSelected={this.onClientPMsSuggestionSelected}
                                                        inputProps={clientPMInputProps}
                                                    />
                                                    {this.state.poErrorClientPMId &&
                                                        <div className="form-error">Required</div>
                                                    }
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="po--number" className="text-left--ts">PO number</label>
                                                    <input
                                                        type="text"
                                                        name="poNumber"
                                                        id="po--number"
                                                        value={values.poNumber}
                                                        onChange={handleChange}
                                                        className={errors.poNumber ? 'form-control error' : 'form-control'}
                                                    />
                                                    <ErrorMessage name="poNumber" className="form-error" component="div" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-6">
                                                <FormGroup>
                                                    <label htmlFor="po--value" className="text-left--ts">Value </label>
                                                    <input
                                                        type="text"
                                                        name="poValue"
                                                        id="po--value"
                                                        value={values.poValue}
                                                        onChange={handleChange}
                                                        className={errors.poValue ? 'form-control error' : 'form-control'}
                                                    />
                                                </FormGroup>
                                                <ErrorMessage name="poValue" className="form-error" component="div" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <FormGroup>
                                                    <label htmlFor="po--invoice-um" className="text-left--ts">PO invoice UM</label>
                                                    <input
                                                        type="text"
                                                        name="poInvoiceUM"
                                                        id="po--invoice-um"
                                                        value={values.poInvoiceUM}
                                                        onChange={handleChange}
                                                        className='form-control'
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button type="submit" disabled={isSubmitting} className="btn--ts-primary btn--ts">{isSubmitting ? 'SAVING ...' : 'SAVE PO'}</button>
                                        <button type="button" className="btn--ts-grey btn--ts" onClick={this.props.closeEditModal}>CANCEL</button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    }
                </Modal>
            </div>
        );
    }
}
AddPO.contextType = AppContext;
export default withCookies(AddPO);
