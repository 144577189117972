import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import TableRow from "../../components/tableRow/TableRow";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Preloader from "../../components/preloader/Preloader";
import AddCostEstimate from "../../components/addCostEstimate/AddCostEstimate";
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import AppContext from "../../context/index";

class CostEstimates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            estimatedTotalCost: typeof this.props.estimatedTotalCost !== "undefined" ? this.props.estimatedTotalCost : 0,
            editCEId: -1,
            showAddCE: false
        };
    }

    openModal = () => {
        this.setState({
            showAddCE: true
        });
    }

    closeModal = refreshList => {
        this.setState({
            showAddCE: false,
            editCEId: -1
        });
        if(refreshList) {
            this.props.getCostEstimates();
        }
    }

    editCE = (ceId) => {
        this.setState({
            editCEId: ceId,
            showAddCE: true
        });
    }

    moveToParentCE = async (ceId) => {
        const { cookies } = this.props;

        if(this.props.projectParentId){
            let result = await confirm({
                title: 'Are you sure?',
                message: "This action is irreversible",
                confirmText: "MOVE TO PARENT",
                confirmColor: "primary",
                cancelColor: "text-danger"
            });

            if (result) {
                this.setState({
                    loading: true
                })

                axios.post(process.env.REACT_APP_API_URL + 'cost-estimates/move-to-parent-project/' + ceId,{}, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.get('authToken')
                    }
                })
                    .then(res => {
                        this.setState({
                            loading: false
                        });
                        this.props.getCostEstimates();
                        toast.success("Cost estimate has been moved to parent project.");
                    })
                    .catch(err => {
                        if(err.response.status === 401){
                            //redirect to login
                            this.context.redirectToLogin();
                        } else {
                            this.setState({
                                loading: false
                            });
                        }
                    })
            }
        }else{
            toast.error("Current project doesn't have a parent project");
        }
    }

    removeCE = async (ceId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "This action is irreversible",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            this.setState({
                loading: true
            })

            axios.delete(process.env.REACT_APP_API_URL + 'cost-estimates/' + ceId, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.setState({
                    loading: false
                });
                this.props.getCostEstimates();
                toast.success("Cost estimate has been removed.");
            })
            .catch(err => {
                if(err.response.status === 401){
                    //redirect to login
                    this.context.redirectToLogin();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
        }
    }

    downloadCE = async (ceId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'download-ce/' + ceId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            let fileName = res.data;

            axios({
                url: 'https://codezilla.timecast.io/back/' + res.data,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
            this.setState({
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    render() {

        return (
            <div className="project-subsection" data-posnumber={this.props.posNumber}>
                { this.state.loading && <Preloader/> }
                <div className="row project-subsection--head">
                    <div className="col-10"><h1>Cost Estimates <span className="small"> { this.props.estimatedTotalCost ? '•  Total: '+ this.props.estimatedTotalCost : '' }</span></h1></div>
                    <div className="col-2 ta-right">
                        <UncontrolledDropdown>
                            <DropdownToggle nav>
                                <i className="la la-plus-circle"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={(e) => {this.openModal()}} key={'rs-1'}>New cost estimate</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>

                {this.state.showAddCE &&
                    <AddCostEstimate
                        ceId={this.state.editCEId}
                        projectId={this.props.projectId}
                        closeEditModal={this.closeModal}
                        showPopup={this.state.showAddCE}
                    />
                }

                <div className="project-subsection--body">
                    {this.props.costEstimates.map((ce, index) => {
                            return(
                                <TableRow
                                    key={'ce-' + index}
                                    tableCols={
                                        [
                                            {
                                                value: ce.id,
                                                label: 'ID'
                                            },
                                            {
                                                value: ce.created_at,
                                                label: 'Date',
                                                dateFormat: 'DD.MM.YYYY', //momentjs formats
                                            },
                                            {
                                                value: ce.client_unit_name,
                                                label: 'Client Unit',
                                                customCssClass: 'row-details'
                                            },
                                            {
                                                value: ce.job_numbers ? ce.job_numbers.map((item) => { return item.name }).join(',') : '',
                                                label: 'Job numbers',
                                                customCssClass: 'row-details'
                                            },
                                            {
                                                value: ce.idg_unit_name,
                                                label: 'Invoiceable Unit',
                                                customCssClass: 'row-details'
                                            },
                                            {
                                                value: ce.idg_pm_name,
                                                label: 'PM',
                                                customCssClass: 'row-details'
                                            },
                                            {
                                                value: ce.ce_status_name,
                                                label: 'Status',
                                                customCssClass: (ce.status_id == 2 ? 'danger' : (ce.status_id == 3 ? 'warning' : 'success'))
                                            },
                                            {
                                                value: ce.po_emis == 0 ? 'Waiting for PO' : (ce.partially_invoiced ? 'Partially invoiced' : (ce.not_invoiced ? 'Not invoiced' : 'Invoiced')),
                                                label: 'PO Status',
                                                customCssClass: (ce.po_emis == 0 || ce.not_invoiced) ? 'row-details danger' : (ce.partially_invoiced ? 'row-details warning' : (ce.po_status == 2 ? 'row-details warning' : 'row-details success'))
                                            },
                                            {
                                                value: ce.value,
                                                label: 'Value'
                                            },
                                            {
                                                ddOptions: [
                                                    {
                                                        value: 'Download',
                                                        action: {
                                                            name: 'downloadCE',
                                                            params: ce.id
                                                        }
                                                    },
                                                    {
                                                        value: 'Move to parent project',
                                                        action: {
                                                            name: 'moveToParentCE',
                                                            params: ce.id
                                                        }
                                                    },
                                                    {
                                                        value: 'Edit',
                                                        action: {
                                                            name: 'editCE',
                                                            params: ce.id
                                                        }
                                                    },
                                                    {
                                                        value: 'Remove',
                                                        action: {
                                                            name: 'removeCE',
                                                            params: ce.id
                                                        }
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                    downloadCE={this.downloadCE}
                                    removeCE={this.removeCE}
                                    editCE={this.editCE}
                                    moveToParentCE={this.moveToParentCE}
                                />
                            )
                    })}

                    {!this.props.costEstimates.length &&
                        <div className="no-data">
                            <p><i className="la la-exclamation-triangle"></i> No cost estimates</p>
                            <a className="btn--ts-primary btn--ts" href="#" onClick={(e) => {this.openModal()}}>Add your first cost estimate for this project</a>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
CostEstimates.contextType = AppContext;
export default withCookies(CostEstimates);
