import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import './OverviewStats.scss';

class OverviewStats extends Component {

    render() {
        return (
            <div className="overview-card">
                <div className="overview-card-inner">
                    <div className="row">
                        <div className="col-auto">
                            <div className="overview-card-inner--mask" style={{backgroundColor: this.props.backgroundColor, borderColor: this.props.borderColor}}>
                                <i className={this.props.icon}></i>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="progress-main-container">
                                <div className="progress-row-1">
                                    <span className="progress-row-1--title">{this.props.title}</span>
                                    <i className={'la la-arrow-' + (parseInt(this.props.percent) > 0 ? 'up' : 'down')}></i>
                                </div>
                                <div className="progress-row-2">
                                    <span className="progress-row-2--title">{this.props.subtitle}</span>
                                    <span className={'progress-row-2--percent ' + (parseInt(this.props.percent) > 0 ? 'success' : 'danger')}>{this.props.percent}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withCookies(OverviewStats);
